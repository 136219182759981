import { useEffect, useState } from 'react';
import logo from "@/assets/svg/logo-black.svg"
import noInternet from "@/assets/image/nointernet.png"

export const LoadingBox = ({ height = 300 }) => (
    <div className='flex items-center justify-center' style={{ height: height }}>
        <span className="spinner-border align-middle ms-2"></span>
    </div>
)

export const ErrorBox = ({ error }) => (
    <div className='h-100px flex items-center justify-center text-danger'>
        {error}
    </div>
)

export const LoadingScreen = ({ loading }) => {
    const [fadeOut, setFadeOut] = useState(false);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => setFadeOut(true), 100); // Start fade-out after a short delay
            setTimeout(() => {
                setEnded(true)
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [loading])

    return (
        <div
            className={`${ended && 'hidden'} fixed w-screen h-screen bg-white z-[10000] flex items-center justify-center transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}
        >
            <img id='logo-loading' src={logo} className='opacity-85 mb-20' width={130} />
        </div>
    )
}

export const ErrorScreen = () => (
    <div className='fixed w-screen h-screen bg-white flex flex-col gap-3 items-center justify-center text-[#212121] font-semibold mb-20 text-[22px] px-10 text-center'>
        <img src={noInternet} width={70} />
        متاسفانه در دریافت اطلاعات مشکلی پیش آمد. لطفا بعدا دوباره تلاش کنید.
    </div>
)