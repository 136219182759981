import { useState, useEffect, useRef, useContext } from 'react';
import { KTIcon } from '@/metronic-dashboard/helpers/components/KTIcon'
import { HeaderUserMenu } from '../../partials/HeaderUserMenu'
import { UserContext } from '@/metronic-dashboard/context/UserContext';
import { Link } from 'react-router-dom';

const AsideToolbar = () => {
  const { currentUser, loading } = useContext(UserContext);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsMenuVisible((prev) => !prev);
  };


  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5' style={{ borderBottom: '1px dashed #1F212A' }}>
        {/*begin::Symbol*/}
        <Link to='/dashboard' className='symbol symbol-50px min-w-[50px] min-h-[50px] !flex items-center justify-center text-white'>
          {loading ?
            <span className="spinner-border align-middle ms-2"></span>
            :
            <img src={currentUser.avatar} alt='' />
          }
        </Link>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2 flex flex-col'>
              {/*begin::Username*/}
              <span className='text-white fs-4 fw-bold'>
                {loading ?
                  <span>...</span>
                  :
                  currentUser?.full_name || '-'
                }
              </span>
              {/*end::Username*/}

              {/*begin::Label*/}
              <div className='d-flex align-items-center text-success fs-8'>
                <span className='bullet bullet-dot bg-success me-1'></span>آنلاین
              </div>
              {/* <div className='d-flex flex-wrap fw-bold fs-7'>
                <div
                  className='d-flex align-items-center text-gray-500 mb-2'
                >
                  <KTIcon iconName='sms' className='fs-4 ml-1' />
                  info@gmail.com
                </div>
              </div> */}
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            <div className='me-n2'>
              {/*begin::Action*/}
              <button
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'

                onClick={toggleMenu}
              >
                <KTIcon iconName='setting-2' className='text-muted fs-1 scale-125' />
              </button>

              <div ref={menuRef}>
                <HeaderUserMenu isVisible={isMenuVisible} setVisibility={setIsMenuVisible} />
              </div>
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}
    </>
  )
}

export { AsideToolbar }
