import parvaneh1 from "../../assets/image/parvaneh (1).jpg"
import parvaneh2 from "../../assets/image/parvaneh (2).jpg"
import parvaneh3 from "../../assets/image/parvaneh (3).jpg"
import javazIcon1 from "../../assets/image/javaz1.png"
import javazIcon2 from "../../assets/image/javaz2.png"
import javazIcon3 from "../../assets/image/javaz3.png"
import javazIcon4 from "../../assets/image/javaz4.png"
import javazIcon5 from "../../assets/image/javaz5.png"
const DisabledLinksList = () => {
    return (
        <ul style={{ direction: 'ltr' }} className="flex gap-x-5 text-white ps-0 max-lg:order-1 max-lg:border-b max-lg:pb-5">
            <li>
                <a
                    href={parvaneh1}
                    onClick={(e) => e.preventDefault()}
                    className="cursor-default"
                >
                    <img src={javazIcon5} className="h-[62px] javaz-icon transition" alt="javaz icon 5" />
                </a>
            </li>
            <li>
                <a
                    href="https://logo.samandehi.ir/Verify.aspx?id=342048&p=xlaoaodsuiwkobpdaodsmcsi"
                    onClick={(e) => e.preventDefault()}
                    className="cursor-default"
                >
                    <img src={javazIcon4} className="h-[62px] javaz-icon transition" alt="javaz icon 4" />
                </a>
            </li>
            <li>
                <a
                    href={parvaneh2}
                    onClick={(e) => e.preventDefault()}
                    className="cursor-default"
                >
                    <img src={javazIcon1} className="h-[62px] javaz-icon transition" alt="javaz icon 1" />
                </a>
            </li>
            <li>
                <a
                    href={parvaneh3}
                    onClick={(e) => e.preventDefault()}
                    className="cursor-default"
                >
                    <img src={javazIcon2} className="h-[62px] javaz-icon transition" alt="javaz icon 2" />
                </a>
            </li>
            <li>
                <a
                    href="https://trustseal.enamad.ir/?id=317656&Code=fcrwv95fcAV5tvURENa5"
                    onClick={(e) => e.preventDefault()}
                    className="cursor-default"
                >
                    <img src={javazIcon3} className="h-[62px] javaz-icon transition" alt="javaz icon 3" />
                </a>
            </li>
        </ul>
    );
};

export default DisabledLinksList;