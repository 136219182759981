import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLayout } from '@/metronic-dashboard/helpers/layout/core/LayoutProvider'
import { usePageData } from '@/metronic-dashboard/helpers/layout/core/PageData'


const DefaultTitle: FC = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData()
  const { config } = useLayout()
  return (
    <div className='page-title d-flex justify-content-center flex-column me-5'>
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex flex-column text-gray-900 fw-bolder mb-[-4px]'>
          {/* {pageTitle} */}
          <Link to='/dashboard' className='text-gray-900 fs-3'>داشبورد</Link>
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs && pageBreadcrumbs.length > 0 && (
        <ul className='breadcrumb breadcrumb-separatorless fw-bold pt-1'>
          {Array.from(pageBreadcrumbs).map((item, index) => (
            <li
              className={clsx('breadcrumb-item', {
                'text-gray-900': !item.isSeparator && item.isActive,
                'text-muted': !item.isSeparator && !item.isActive,
              })}
              key={`${item.path}${index}`}
            >
              {!item.isSeparator ? (
                <Link className='text-muted text-hover-primary fs-6' to={item.path}>
                  {item.title}
                </Link>
              ) : (
                <span className='bullet bg-gray-500 w-5px h-2px fs-6'></span>
              )}
            </li>
          ))}
          <li className='breadcrumb-item text-gray-900 fs-6'>{pageTitle}</li>
        </ul>
      )}
    </div>
  )
}

export { DefaultTitle }