const NotFound = ({ fromDashboard = false }) => {
    return (
        <div className={`w-full py-20 flex flex-col gap-2 items-center justify-center ${!fromDashboard && 'bg-white h-screen'}`}>
            <h1 className={`text-gray-200 ${fromDashboard && 'text-gray-800'}`} style={{ fontSize: '130px' }}>۴۰۴</h1>
            <h1 className={`text-gray-100 -mt-5 -mb-1 ${fromDashboard && 'text-gray-900'}`}>صفحه مورد نظر یافت نشد!</h1>
            <p className={`text-gray-200 font-medium ${fromDashboard && 'text-gray-800'}`}>متاسفانه صفحه‌ای که به دنبال آن هستید وجود ندارد.</p>
            <a href="/" className="btn btn-primary mt-3 !font-semibold">بازگشت به خانه</a>
        </div>
    )
}

export default NotFound;