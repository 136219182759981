import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '@/utils/axiosInstance';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import { convertNumsToFa } from '@/utils/numLangChanger'
import Alert from '@/components/Alert';

import img from '@/assets/image/contact-us.png'
import img2 from '@/assets/image/contact-us2.png'
import imgBg from '@/assets/image/contact-us-bg.png'

import bookIcon from '@/assets/svg/book.svg'

const validationSchema = Yup.object({
    name: Yup.string().required('این فیلد الزامی است.'),
    email: Yup.string().email('ایمیل معتبر نیست.').required('این فیلد الزامی است.'),
    confirmEmail: Yup.string().oneOf([Yup.ref('email'), null], 'ایمیل‌ها باید یکسان باشند').required('این فیلد الزامی است.'),
    title: Yup.string().required('این فیلد الزامی است.'),
    comment: Yup.string().max(250, 'حداکثر 250 کاراکتر').required('این فیلد الزامی است.'),
});

const ContactUs = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    const btn = useRef(null)
    const [remainingChars, setRemainingChars] = useState(250)

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showDangerModal, setShowDangerModal] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            title: '',
            email: '',
            confirmEmail: '',
            comment: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            btn.current.disabled = true
            btn.current.innerText = 'لطفا کمی صبر کنید...'
            setShowSuccessModal(false)
            setShowDangerModal(false)

            try {
                const response = await axiosInstance.post('/website/contact_us/create', {
                    full_name: values.name,
                    email: values.email,
                    title: values.title,
                    info: values.comment,
                });
                if (response.data.status === 1) {
                    setShowSuccessModal(true)
                    formik.resetForm();
                } else {
                    setShowDangerModal(true)
                }
            } catch (error) {
                setShowDangerModal(true)
            } finally {
                btn.current.disabled = false
                btn.current.innerText = 'ارسال درخواست'
            }
        },
    });

    const handleTextareaChange = (event) => {
        const charCount = event.target.value.length;
        setRemainingChars(250 - charCount);
        formik.handleChange(event);
    };

    useEffect(() => {
        convertNumsToFa()
    }, [remainingChars])


    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            {showSuccessModal && <Alert title='درخواست شما ثبت شد.' text='بعد از بررسی، حداکثر تا ۴۸ ساعت پاسخ برای شما ایمیل خواهد شد.' />}
            {showDangerModal && <Alert danger title='متاسفانه مشکلی پیش آمد.' text='لطفا از اتصال دستگاه خود به اینترنت اطمینان حاصل نمایید و دوباره تلاش کنید.' />}

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 lg:pb-20 overflow-hidden">
                <div style={{ transform: 'skew(0,4deg)', background: 'linear-gradient(180deg, #0A0D12, #3D322D 200%)' }} className='absolute inset-0 mt-[320px] max-lg:mt-[360px] lg:mb-[50px] max-lg:mb-[100px] rounded-[5vw] z-0'></div>
                <div style={{ transform: 'skew(0,4deg)', background: `url(${imgBg}) center 35%`, backgroundSize: 'cover' }} className=' absolute inset-0 mt-[320px] max-lg:mt-[360px] lg:mb-[50px] max-lg:mb-[100px] rounded-[5vw] z-0'></div>

                <div className='my-container relative mb-8'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold text-[28px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            تماس با ما
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-3'>انتقاد و یا پیشنهادتان را پذیرا هستیم.</div>
                </div>

                <div className="lg:min-h-[1100px]  max-lg:pb-36 my-container relative flex gap-10 max-lg:flex-col text-[#e4e4e4]">
                    <div className='lg:w-1/2 flex items-center justify-center'>
                        <div className='lg:border border-white lg:p-1 rounded-xl'>
                            <img src={img} className='lg:hidden max-lg:p-1 max-lg:bg-white rounded' />
                            <img src={img2} className='max-lg:hidden rounded' />
                        </div>
                    </div>

                    <div className='lg:w-1/2 lg:min-h-[840px] m-auto flex flex-col gap-5 text-white'>
                        <h3 className='text-center'>سلام، چطوری؟</h3>
                        <p className='text-[20px]'>به دلیل تعداد بالای کاربران وب سایت، خدمات پشتیبانی تنها به سه روش زیر ارائه می‌گردد:</p>
                        <p className='text-[20px]'>
                            ۱.<Link to='https://app.mu.chat/@innoghte1' className='text-white'>"پشتیبان هوشمند"</Link>
                            <br />۲. ارسال <Link to='/dashboard/support/tickets/tickets-list' className='text-white'>تیکت پشتیبانی</Link>، بعد از ورود به اکانت کاربری
                            <br />۳. ارسال درخواست پشتیبانی از طریق فرم زیر:
                        </p>
                        <div className="flex flex-col gap-3">
                            <form onSubmit={formik.handleSubmit} className='w-full lg:mt-5 max-lg:mt-2'>
                                <div className="grid lg:grid-cols-2 gap-x-8 gap-y-6 -mt-3">
                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed1" className='!text-[20px] font-medium'>نام و نام خانوادگی*</label>
                                        <input
                                            id='filed1'
                                            name='name'
                                            type="text"
                                            className='bg-[#eee] rounded-xl p-4 px-5 !text-[19px] text-[#212121]'
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />
                                        {formik.errors.name && formik.touched.name &&
                                            <div className="flex gap-1 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                <div className="text-red-500 text-[17px]">{formik.errors.name}</div>
                                            </div>
                                        }
                                    </div>

                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed2" className='!text-[20px] font-medium'>آدرس ایمیل*</label>
                                        <input
                                            id='filed2'
                                            name='email'
                                            type="email"
                                            className='bg-[#eee] rounded-xl p-4 px-5 !text-[19px] text-[#212121]'
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        {formik.errors.email && formik.touched.email &&
                                            <div className="flex gap-1 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                <div className="text-red-500 text-[17px]">{formik.errors.email}</div>
                                            </div>
                                        }
                                    </div>

                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed3" className='!text-[20px] font-medium'>موضوع*</label>
                                        <input
                                            id='filed3'
                                            name='title'
                                            type="text"
                                            className='bg-[#eee] rounded-xl p-4 px-5 !text-[19px] text-[#212121]'
                                            onChange={formik.handleChange}
                                            value={formik.values.title}
                                        />
                                        {formik.errors.title && formik.touched.title &&
                                            <div className="flex gap-1 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                <div className="text-red-500 text-[17px]">{formik.errors.title}</div>
                                            </div>
                                        }
                                    </div>

                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed4" className='!text-[20px] font-medium'>تکرار آدرس ایمیل*</label>
                                        <input
                                            id='filed4'
                                            name='confirmEmail'
                                            type="email"
                                            className='bg-[#eee] rounded-xl p-4 px-5 !text-[19px] text-[#212121]'
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmEmail}
                                        />
                                        {formik.errors.confirmEmail && formik.touched.confirmEmail && (
                                            <div className="flex gap-1 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                <div className="text-red-500 text-[17px]">{formik.errors.confirmEmail}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='lg:col-span-2 flex flex-col gap-3'>
                                        <label htmlFor="filed5" className='!text-[20px] font-medium'>شرح درخواست*</label>
                                        <textarea
                                            id='filed5'
                                            name='comment'
                                            rows={3}
                                            maxLength={250}
                                            style={{ resize: 'none' }}
                                            placeholder='اینجا بنویسید...'
                                            className='outline-none bg-[#eee] rounded-xl p-4 px-5 !text-[19px] leading-6 text-[#212121]'
                                            onChange={handleTextareaChange}
                                            value={formik.values.comment}
                                        />
                                        {formik.errors.comment && formik.touched.comment &&
                                            <div className="flex gap-1 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                <div className="text-red-500 text-[17px]">{formik.errors.comment}</div>
                                            </div>
                                        }
                                        <span className='text-[#9E9E9E] text-[17px] -mt-1'>
                                            {remainingChars >= 50 ? 'حداکثر ۲۵۰ کاراکتر' : `کاراکتر باقیمانده: ${remainingChars}`}
                                        </span>
                                    </div>
                                </div>

                                <button ref={btn} type='submit' className='float-start max-md:w-full max-md:mt-5 font-semibold text-[20px] max-lg:text-[19px] h-12 px-8 text-white rounded bg-[--green-primary] hover:bg-[--green-primary-hover] transition'>ارسال درخواست</button>
                            </form>

                            <p className='text-[20px] text-justify mt-2'>بعد از بررسی درخواست حداکثر تا ۴۸ ساعت، پاسخ برای شما ایمیل خواهد شد.</p>

                            <p className='text-[20px] text-justify'>شماره تماس دفتر مجموعه “این نقطه” تنها جهت بررسی امور اداری و حقوقی فعال است و با توجه به تعداد بالای کاربران وب سایت، خدمات پشتیبانی از دوره‌ها، مشکلات پرداخت و مشکلات فنی تنها از روش‌های فوق قابل پیگیری خواهد بود.</p>

                            <div className="flex justify-between">
                                <p className='text-[20px] text-justify'>تلفن:</p>
                                <p className='text-[20px] text-justify'>۰۲۱-۷۷۶۲۷۹۳۵</p>
                            </div>

                            <div className="flex justify-between max-sm:flex-col -mt-2">
                                <p className='text-[20px] text-justify'>آدرس:</p>
                                <p className='text-[20px] text-justify'>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default ContactUs;