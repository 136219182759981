import { useEffect } from 'react';

const MuChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.id = 'muchat-agent';
    script.textContent = `
      import Chatbox from 'https://cdn.mu.chat/embeds/dist/chatbox/index.js';
      Chatbox.initBubble({
        agentId: 'cm3inqjmi012r114vm8n9qlnb'
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default MuChat;
