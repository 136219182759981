import { FC, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTIcon } from '@/metronic-dashboard/helpers/components/KTIcon';
import axiosInstance from '@/utils/axiosInstance';
import { UserContext, defaultUser } from '@/metronic-dashboard/context/UserContext';
import Cookies from 'js-cookie';
import { isDotIr } from '@/utils/isDotIr'

interface HeaderUserMenuProps {
  isVisible: boolean;
  setVisibility
}

const HeaderUserMenu: FC<HeaderUserMenuProps> = ({ isVisible, setVisibility }) => {
  const { currentUser, loading, setCurrentUser } = useContext(UserContext);

  const token = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate()


  const logout = async (e) => {
    try {
      e.target.disabled = true;
      setLoading2(true);

      const token = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

      const response = await axiosInstance.get('/logout', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        // حذف کوکی‌ها
        Cookies.remove(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

        // هدایت به صفحه اصلی یا صفحه ورود
        window.location.href = '/'
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message === "Unauthenticated.") {
        Cookies.remove(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
        window.location.href = '/'
      } else {
        console.error('Error during logout:', error);
      }
    } finally {
      e.target.disabled = false;
      setLoading2(false);
    }
  };


  return (
    <div
      className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px  my-menu1 ${isVisible ? 'show z-[105] fixed' : ''}`}
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-3'>
            {loading ?
              <span className="spinner-border align-middle ms-2"></span>
              :
              <img src={currentUser?.avatar} />
            }
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-3 mb-1'>
              {loading ?
                <span>...</span>
                :
                currentUser?.full_name || '-'
              }
            </div>
            <span className='fw-bold text-muted fs-6' style={{ direction: 'ltr' }}>
              {loading ?
                <span>...</span>
                :
                <>
                  {currentUser?.email.length > 23 ? currentUser?.email.slice(0, 22).concat('...') : currentUser?.email}
                </>
              }
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='./profile/overview' onClick={() => setVisibility(false)} className='menu-link px-5'>
          پروفایل
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='./profile/edit' onClick={() => setVisibility(false)} className='menu-link px-5'>
          ویرایش حساب
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <button onClick={logout} className='menu-link px-5 w-full flex justify-between'>
          خروج
          {loading2 ?
            <span className="spinner-border spinner-border-sm align-middle"></span>
            :
            <KTIcon iconName="exit-right" className="fs-1 me-n1 opacity-55 scale-110" />
          }
        </button>
      </div>
    </div>
  )
}

export { HeaderUserMenu }