import { useRef } from "react";
import './Alert.css';

interface AlertProps {
    danger?: boolean;
    title: any;
    text: any;
    onClose?: () => void;
}

const Alert: React.FC<AlertProps> = ({ danger = false, title, text, onClose }) => { 
    const modal = useRef(null);

    const closeModal = () => {
        modal.current.style.opacity = '0';
        setTimeout(() => {
            modal.current.style.display = 'none';

            if (onClose) {
                onClose();
            }
        }, 150);
    };

    return (
        <div ref={modal} id="my-modal4" className='fixed inset-0 bg-white/5 z-[5001] w-screen h-screen flex items-center justify-center transition'>
            <div className="w-[304px] flex flex-col items-center px-9 pt-7 pb-6 rounded-2xl bg-white relative shadow">
                <i onClick={closeModal} className="block absolute left-3 -top-5 text-[55px] skew-x-[14deg] rotate-45 cursor-pointer !text-[#444]">+</i>
                <h3 className={`text-center ${danger ? 'text-[#F75555]' : 'text-[#12D18E]'}`}>{title}</h3>
                <p className="font-semibold text-[21px] text-[#212121] text-center leading-7">{text}</p>
            </div>
        </div>
    );
};

export default Alert;