import { useState } from 'react';
import { Link } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";

import img from '@/assets/image/podcast.png'
import imgBg from '@/assets/image/podcast-bg2.png'

import bookIcon from '@/assets/svg/book.svg'
import social2 from "@/assets/svg/social (2).svg"
import social3 from "@/assets/svg/social (3).svg"
import social5 from "@/assets/svg/social (5).svg"
import social6 from "@/assets/svg/social (6).svg"


const Podcast = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }


    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden">
                <div
                    style={{ transform: 'skew(0,4deg)', background: 'linear-gradient(180deg, #1F2532, #3D322D 200%)' }}
                    className='absolute inset-0 mt-[320px] max-lg:mt-[400px] mb-[50px] rounded-[5vw] z-0'
                ></div>
                <div
                    style={{ transform: 'skew(0,4deg)', background: `url(${imgBg}) center 35%`, backgroundSize: 'cover' }}
                    className=' absolute inset-0 mt-[320px] max-lg:mt-[400px] mb-[50px] rounded-[5vw] z-0'
                ></div>

                <div className='my-container relative mb-8'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            پادکست این نقطه
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-3'>همراهان گرامی شما می توانید با مراجعه به اپلیکیشن های زیر از پادکست “این نقطه” استفاده نمایید.</div>
                </div>

                <div className="lg:h-[1033px] max-lg:pb-36 my-container relative flex max-lg:flex-col text-[#e4e4e4]">
                    <div className='lg:w-1/2 flex items-center justify-center'>
                        <img src={img} className='max-lg:p-1 max-lg:bg-white max-lg:rounded-2xl lg:border lg:border-[#999] p-1 rounded-xl' />
                    </div>
                    <div className="lg:w-1/2 lg:h-[410px] lg:m-auto lg:pr-32 max-lg:pt-10 max-lg:gap-20 flex flex-col justify-between items-center">
                        <p className='font-semibold text-[30px] text-center'>تو این نقطه همدیگرو بیدار<br /> میکنیم، تا خونه قدم میزنیم و<br /> تمرین میکنیم هنر خوب زیستن رو</p>
                        <div className="flex flex-col items-center gap-5 w-[330px]">
                            <p className='text-[22px] text-center'>کافیست بر روی لوگوی اپلیکیشن مورد نظر خود کلیک کنید.</p>
                            <div className="flex gap-9">
                                <Link target='_blank' to='https://open.spotify.com/show/2HIXQBtFdNg2QfjoFoIChK?si=VQ-8D0xYQC6vULDyX_31Iw'>
                                    <img src={social2} width={50} className='transition hover:scale-110' />
                                </Link>
                                <Link target='_blank' to='https://www.youtube.com/channel/UCxL5awDwnPpPmcYos4zZSSA'>
                                    <img src={social3} width={50} className='transition hover:scale-110' />
                                </Link>
                                <Link target='_blank' to='https://podcasts.apple.com/us/podcast/%D8%A7%DB%8C%D9%86-%D9%86%D9%82%D8%B7%D9%87/id1607369690'>
                                    <img src={social5} width={50} className='transition hover:scale-110' />
                                </Link>
                                <Link target='_blank' to='https://castbox.fm/va/5175428'>
                                    <img src={social6} width={50} className='transition hover:scale-110' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Podcast;