import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axiosInstance from '@/utils/axiosInstance';
import { traverseAndConvert } from '@/utils/numLangChanger'
import { isDotIr } from '@/utils/isDotIr'

interface User {
    avatar: string;
    email: string;
    full_name: string;
    mobile: string;
    verified: number;
    country_code: string;
}

interface UserContextType {
    currentUser: User;
    loading: boolean;
    setCurrentUser: React.Dispatch<React.SetStateAction<User>>;
}

export const defaultUser: User = {
    avatar: '',
    email: '',
    full_name: '',
    mobile: '',
    country_code: '',
    verified: 1,
};

export const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
    const location = useLocation()

    const [currentUser, setCurrentUser] = useState<User>(defaultUser);
    const [dataFetched, setDataFetched] = useState(false)
    const [loading, setLoading] = useState(true);

    const fetchProfileData = async () => {
        const token = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

        if (!token) {
            setLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.get('/profile',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                setCurrentUser(response.data);
                setDataFetched(true)
            } else {
                console.error('Failed to get profile data');
            }
        } catch (error) {
            console.error('Error during fetch profile data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    useEffect(() => {
        document.body.childNodes.forEach(child => traverseAndConvert(child));
    }, [currentUser, loading]);

    useEffect(() => {
        if (!dataFetched) fetchProfileData();
    }, [location])

    return (
        <UserContext.Provider value={{ currentUser, loading, setCurrentUser }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUserContext = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};