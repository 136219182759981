//internal
import { useState, useEffect, useRef, useContext } from "react";
import Cookies from "js-cookie";
import { UserContext } from '@/metronic-dashboard/context/UserContext';
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr'
import axiosInstance from '@/utils/axiosInstance';

//external
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FaChevronDown } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { RxExit } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";

//Component-Style-Image
import "./main-nav.css"
import blury from "../../assets/image/blury.png"
import logo2 from "../../assets/svg/logo-black.svg"
import buy from "../../assets/svg/Buy.svg"
import buyWhite from "../../assets/svg/Buy-white.svg"
import closeIcon from "../../assets/svg/Close2.svg"
import addUser from "../../assets/svg/Add User.svg"
import bars from "../../assets/svg/bars.svg"
import userIcon from "../../assets/svg/user-icon.svg"


const Main_Nav = ({ nav_DropDown, setNav_DropDown, fromHomePage = false }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { currentUser, loading } = useContext(UserContext);
    const { cart } = useContext(CartContext)

    const [coures_Guide, setCoures_Guide] = useState(false);
    const [barList, setBarList] = useState(false);

    let isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com') ? true : false;


    const [onTop, setOnTop] = useState(true)

    document.addEventListener('scroll', function () {
        const scrollTop = window.scrollY || document.documentElement.scrollTop

        if (scrollTop > 16) {
            setOnTop(false)
        } else {
            setOnTop(true)
        }
    })

    useEffect(() => {
        if (barList) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [barList]);

    useEffect(() => {
        return () => document.body.style.overflow = 'unset';
    }, [])


    const [loading2, setLoading2] = useState(false)

    const logout = async (e) => {
        try {
            e.target.disabled = true;
            setLoading2(true);

            const token = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

            const response = await axiosInstance.get('/logout', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                Cookies.remove(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
                navigate('/auth');
            } else {
                console.error('Failed to log out');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === "Unauthenticated.") {
                Cookies.remove(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
                navigate('/auth');
            } else {
                console.error('Error during logout:', error);
            }
        } finally {
            e.target.disabled = false;
            setLoading2(false);
        }
    };


    // 
    const [isOpen1, setIsOpen1] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)

    const li1 = useRef(null)
    const li2 = useRef(null)
    const li2Icon = useRef(null)
    const li21 = useRef(null)
    const li22 = useRef(null)
    const li23 = useRef(null)
    const li24 = useRef(null)
    const li241 = useRef(null)
    const li25 = useRef(null)
    const li251 = useRef(null)
    const li3 = useRef(null)
    const li3Icon = useRef(null)
    const li31 = useRef(null)
    const li32 = useRef(null)
    const li33 = useRef(null)
    const li34 = useRef(null)
    const li35 = useRef(null)
    const li4 = useRef(null)
    const li5 = useRef(null)
    const li6 = useRef(null)
    const li0 = useRef(null)
    const li7 = useRef(null)
    const li8 = useRef(null)

    function handler1() {
        if (!isOpen1) {
            li2Icon.current.style.transform = 'rotate(180deg)'

            li1.current.style.pointerEvents = 'none'
            li7.current.style.pointerEvents = 'none'
            li8.current.style.pointerEvents = 'none'

            li1.current.style.opacity = '0'
            li3.current.style.opacity = '0'
            li4.current.style.opacity = '0'
            li5.current.style.opacity = '0'
            li6.current.style.opacity = '0'
            li7.current.style.opacity = '0'
            li8.current.style.opacity = '0'
            setTimeout(() => {
                li2.current.style.marginTop = '40px'

                li3.current.style.display = 'none'
                li4.current.style.display = 'none'
                li5.current.style.display = 'none'
                li6.current.style.display = 'none'

                li21.current.style.display = 'block'
                li22.current.style.display = 'block'
                li23.current.style.display = 'block'
                if (!isDotIr) li24.current.style.display = 'block'
                if (isDotIr) li241.current.style.display = 'block'
                if (!isDotIr) li25.current.style.display = 'block'
                if (isDotIr) li251.current.style.display = 'block'
                setTimeout(() => {
                    li21.current.style.opacity = '1'
                    li22.current.style.opacity = '1'
                    li23.current.style.opacity = '1'
                    if (!isDotIr) li24.current.style.opacity = '1'
                    if (isDotIr) li241.current.style.opacity = '1'
                    if (!isDotIr) li25.current.style.opacity = '1'
                    if (isDotIr) li251.current.style.opacity = '1'
                }, 1)
                setIsOpen1(true)
            }, 150)
        } else {
            li2Icon.current.style.transform = 'rotate(0deg)'

            li1.current.style.pointerEvents = 'unset'
            li7.current.style.pointerEvents = 'unset'
            li8.current.style.pointerEvents = 'unset'

            li21.current.style.opacity = '0'
            li22.current.style.opacity = '0'
            li23.current.style.opacity = '0'
            if (!isDotIr) li24.current.style.opacity = '0'
            if (isDotIr) li241.current.style.opacity = '0'
            if (!isDotIr) li25.current.style.opacity = '0'
            if (isDotIr) li251.current.style.opacity = '0'
            setTimeout(() => {
                li2.current.style.marginTop = '0px'

                li21.current.style.display = 'none'
                li22.current.style.display = 'none'
                li23.current.style.display = 'none'
                if (!isDotIr) li24.current.style.display = 'none'
                if (isDotIr) li241.current.style.display = 'none'
                if (!isDotIr) li25.current.style.display = 'none'
                if (isDotIr) li251.current.style.display = 'none'

                li3.current.style.display = 'block'
                li4.current.style.display = 'block'
                li5.current.style.display = 'block'
                li6.current.style.display = 'block'
                setTimeout(() => {
                    li1.current.style.opacity = '1'
                    li3.current.style.opacity = '1'
                    li4.current.style.opacity = '1'
                    li5.current.style.opacity = '1'
                    li6.current.style.opacity = '1'
                    li7.current.style.opacity = '1'
                    li8.current.style.opacity = '1'
                }, 1)
                setIsOpen1(false)
            }, 150)
        }
    }

    function handler2() {
        if (!isOpen2) {
            li3Icon.current.style.transform = 'rotate(180deg)'

            li1.current.style.pointerEvents = 'none'
            li2.current.style.pointerEvents = 'none'
            li7.current.style.pointerEvents = 'none'
            li8.current.style.pointerEvents = 'none'

            li1.current.style.opacity = '0'
            li2.current.style.opacity = '0'
            li4.current.style.opacity = '0'
            li5.current.style.opacity = '0'
            li6.current.style.opacity = '0'
            li7.current.style.opacity = '0'
            li8.current.style.opacity = '0'
            setTimeout(() => {
                li3.current.style.marginTop = '-57px'

                li4.current.style.display = 'none'
                li5.current.style.display = 'none'
                li6.current.style.display = 'none'
                li0.current.style.display = 'none'
                li7.current.style.display = 'none'
                li8.current.style.display = 'none'

                li31.current.style.display = 'block'
                li32.current.style.display = 'block'
                li33.current.style.display = 'block'
                li34.current.style.display = 'block'
                li35.current.style.display = 'block'
                setTimeout(() => {
                    li31.current.style.opacity = '1'
                    li32.current.style.opacity = '1'
                    li33.current.style.opacity = '1'
                    li34.current.style.opacity = '1'
                    li35.current.style.opacity = '1'
                }, 1)
                setIsOpen2(true)
            }, 150)
        } else {
            li3Icon.current.style.transform = 'rotate(0deg)'

            li1.current.style.pointerEvents = 'unset'
            li2.current.style.pointerEvents = 'unset'
            li7.current.style.pointerEvents = 'unset'
            li8.current.style.pointerEvents = 'unset'

            li31.current.style.opacity = '0'
            li32.current.style.opacity = '0'
            li33.current.style.opacity = '0'
            li34.current.style.opacity = '0'
            li35.current.style.opacity = '0'
            setTimeout(() => {
                li3.current.style.marginTop = '0'

                li31.current.style.display = 'none'
                li32.current.style.display = 'none'
                li33.current.style.display = 'none'
                li34.current.style.display = 'none'
                li35.current.style.display = 'none'

                li4.current.style.display = 'block'
                li5.current.style.display = 'block'
                li6.current.style.display = 'block'
                li0.current.style.display = 'block'
                li7.current.style.display = 'block'
                li8.current.style.display = 'block'
                setTimeout(() => {
                    li1.current.style.opacity = '1'
                    li2.current.style.opacity = '1'
                    li4.current.style.opacity = '1'
                    li5.current.style.opacity = '1'
                    li6.current.style.opacity = '1'
                    li7.current.style.opacity = '1'
                    li8.current.style.opacity = '1'
                }, 1)
                setIsOpen2(false)
            }, 150)
        }
    }

    return (

        <>
            {/* نوار دسکتاپ */}
            {/* <nav className="main-nav" style={{ paddingTop: onTop ? '2rem' : '1rem', background: pathname === '/' && onTop ? 'transparent' : '#ffffffcb' }}> */}
            <nav className="main-nav" style={{ paddingTop: '2rem', background: onTop ? 'transparent' : '#ffffffdd', backdropFilter: onTop ? 'none' : 'blur(5px)' }}>
                <div className="container flex justify-between items-center !px-[40px]">

                    {/* سمت راست */}
                    <div className="nav-right h-full flex items-center gap-x-20">

                        <figure className="flexCenter">
                            <Link to={"/"} className="flex gap-2">
                                {/* <img src={logo} /> */}
                                <img src={logo2} width={110} className="opacity-85" />
                            </Link>
                        </figure>

                        <ul className="flex h-full items-center gap-x-3">
                            <li className="">
                                <Link to={"/"} className={`hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 px-3 transition ${pathname === "/" ? 'active' : ''}`}>صفحه اصلی</Link>
                            </li>
                            <li className="group nav-experience h-full ">
                                <Link to={""} className={`cursor-default flex w-full items-center gap-x-2 ${fromHomePage ? 'bg-white group-hover:!bg-[#f4f4f4]' : 'bg-[--orange-primary] group-hover:bg-[--orange-primary-hover] text-white'} rounded-[.4rem] h-[37px] px-3`}>
                                    <span className="">خدمات</span>
                                    <span className="text-[13px] group-hover:rotate-180 transition"><FaChevronDown /></span>
                                </Link>

                                <ul className="py-3 px-0 shadow-sm" style={{ border: '1px solid #eee' }}>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/courses"}>
                                            دوره‌های آموزشی
                                        </Link>
                                    </li>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/albums"}>
                                            آلبوم‌ها
                                        </Link>
                                    </li>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/live-meeting"}>
                                            روی خط
                                        </Link>
                                    </li>
                                    {/* {!isDotIr &&
                                        <li className="hover:bg-gray-100">
                                            <Link to={"/events"}>
                                                رویدادها
                                            </Link>
                                        </li>
                                    } */}
                                    {!isDotIr &&
                                        <li className="hover:bg-gray-100">
                                            <Link to={"/consultation"}>
                                                مشاوره خصوصی
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                            <li className="nav-experience group h-full ">
                                <Link to={""} className="cursor-default flex w-full items-center gap-x-2 rounded-[.4rem] h-[37px] p-2 px-3 group-hover:bg-black/5 transition">
                                    <span className="">تجربه کن</span>
                                    <span className="text-[13px] group-hover:rotate-180 transition"><FaChevronDown /></span>
                                </Link>

                                <ul className="py-3 px-0 shadow-sm" style={{ border: '1px solid #eee' }}>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/podcast"}>
                                            پادکست
                                        </Link>
                                    </li>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/meditation"}>
                                            مدیتیشن
                                        </Link>
                                    </li>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/reading"}>
                                            بخوانیم
                                        </Link>
                                    </li>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/listening"}>
                                            بشنویم
                                        </Link>
                                    </li>
                                    <li className="hover:bg-gray-100">
                                        <Link to={"/writing"}>
                                            بنویسیم
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link className="hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/donation">حمایت مالی</Link>
                            </li>
                            <li>
                                <Link className="hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/terms">قوانین و مقررات</Link>
                            </li>
                            <li>
                                <Link className="hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/contact-us">تماس با ما</Link>
                            </li>
                        </ul>
                    </div>

                    {/* سمت چپ نوار  */}
                    <div className="nav-left h-full ">
                        <ul className="flex h-full items-center gap-x-2">

                            {
                                isLogin ? (
                                    <div className="flex items-center gap-x-4 h-full">
                                        {/* سبد خرید */}
                                        {/* <li className="cursor-pointer ml-3 hover:text-[#ffcf35]"><GrCart size={21} /></li> */}
                                        <Link to='/shopping-cart' className="relative cursor-pointer hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 transition">
                                            {cart.length > 0 && <div className="absolute right-1 top-1.5 w-3 h-3 rounded-full bg-[--orange-primary]" />}
                                            <img src={buy} />
                                        </Link>
                                        {/*  */}
                                        <div className="flex items-center gap-x-2 cursor-pointer h-full relative">
                                            <li onClick={() => setNav_DropDown(prev => !prev)} className={`flex w-full items-center gap-x-3 text-[#212121] bg-[#F0F0F0] lg:border border-[#dedede] rounded-[.4rem] h-[44px] px-5`}>
                                                <img src={currentUser?.avatar.endsWith('blank.png') ? userIcon : currentUser?.avatar || userIcon} width={27} className="rounded-full" />
                                                {loading ?
                                                    <span className="spinner-border spinner-border-sm align-middle mx-[6px] opacity-50"></span>
                                                    :
                                                    <a className='font-semibold text-[18px] text-[#212121] ltr'>
                                                        {currentUser?.full_name || currentUser?.email.slice(0, 5).concat('...')}
                                                    </a>
                                                }
                                                <span><FaChevronDown color="#858585" className={`text-xs  transition !duration-150 ${nav_DropDown && 'rotate-180'}`} /></span>
                                            </li>
                                            {/* dropdown */}
                                            {
                                                nav_DropDown && (
                                                    <section className="nav-dropdown absolute shadow-sm lg:border border-[#eee]">
                                                        {/* قسمت بالا */}
                                                        <div className="nav-dropdown-top ">
                                                            <Link to={"/dashboard"}>
                                                                <img src={currentUser?.avatar.endsWith('blank.png') ? userIcon : currentUser?.avatar || userIcon} width={35} className="rounded-full" />

                                                                <div>
                                                                    <span className="block font-medium ltr text-right">
                                                                        {currentUser?.full_name || currentUser?.email.slice(0, 5).concat('...')}
                                                                    </span>
                                                                    <span className="block font-medium ltr">
                                                                        {currentUser?.email.slice(0, 15).concat('...')}
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        <div className="h-px w-[162px] bg-[#eee] my-2 mx-auto" />

                                                        {/* لیست برای دراپ دان */}
                                                        <ul>
                                                            <li className="hover:bg-gray-100">
                                                                <Link to={"/dashboard"}>

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" viewBox="0 -960 960 960" fill="#222"><path d="M120-520v-320h320v320H120Zm0 400v-320h320v320H120Zm400-400v-320h320v320H520Zm0 400v-320h320v320H520ZM200-600h160v-160H200v160Zm400 0h160v-160H600v160Zm0 400h160v-160H600v160Zm-400 0h160v-160H200v160Zm400-400Zm0 240Zm-240 0Zm0-240Z" /></svg>
                                                                    <span>داشبورد</span>
                                                                </Link>
                                                            </li>
                                                            <li className="hover:bg-gray-100">
                                                                <Link onClick={logout}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" viewBox="0 -960 960 960" fill="#222"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" /></svg>
                                                                    <span>خروج</span>

                                                                    {loading2 && <span className="spinner-border spinner-border-sm align-middle mr-auto ml-1 opacity-50"></span>}
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </section>
                                                )
                                            }

                                        </div>
                                    </div>
                                ) :
                                    (
                                        <>
                                            <Link to='/shopping-cart' className="relative cursor-pointer hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 transition">
                                                {cart.length > 0 && <div className="absolute right-1 top-1.5 w-3 h-3 rounded-full bg-[--orange-primary]" />}
                                                <img src={buy} />
                                            </Link>
                                            <li>
                                                <Link className="hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 px-3 transition" to="/auth/login">ورود</Link>
                                            </li>
                                            <Link className={`nav-register rounded-[.4rem] h-[37px] flex gap-[.4rem] items-center transition ${fromHomePage ? 'bg-white hover:!bg-[#f7f7f7]' : 'bg-[--orange-primary] hover:bg-[--orange-primary-hover] text-white'}`} to="/auth/registration">
                                                <img src={addUser} alt="" style={fromHomePage ? {} : { filter: 'invert(1)' }} />
                                                ثبت نام
                                            </Link>
                                        </>
                                    )
                            }

                        </ul>
                    </div>


                    {
                        coures_Guide && (
                            <div className="course-guide">
                                <section>
                                    <img src={mySrc} alt="" />
                                    <span onClick={() => setCoures_Guide(false)}><IoMdClose /></span>
                                </section>
                            </div>
                        )
                    }

                </div>
            </nav>

            {/* نوار موبایل و تبلت */}
            {/* <nav className="mobile-tablet-nav flex items-center justify-between" style={{ padding: onTop ? '3rem 2rem 0 2rem' : '1rem 1.5rem', background: barList ? 'transparent' : pathname === '/' && onTop ? 'transparent' : '#ffffffcb' }}> */}
            <nav className="mobile-tablet-nav flex items-center justify-between transition" style={{ padding: '2rem 2rem 1rem 2rem', background: barList ? 'transparent' : fromHomePage && onTop ? 'transparent' : '#ffffffdd', backdropFilter: !onTop && 'blur(5px)' }}>
                <Link to={"/"} className="flex items-center gap-2 text-black hover:!text-black">
                    {/* <img src={logo} /> */}
                    <img src={logo2} width={110} className="opacity-85" />
                </Link>
                <div className="flex items-center gap-6">
                    <Link to='/shopping-cart' className="cursor-pointer transition relative">
                        {cart.length > 0 && <div className="absolute -right-0.5 top-0 w-3 h-3 rounded-full bg-[--orange-primary]" />}
                        <img src={buy} width={30} />
                    </Link>

                    <span onClick={() => setBarList(true)} className={`cursor-pointer transition duration-300 ${barList && 'opacity-0'}`} >
                        <img src={bars} color="black" />
                    </span>
                </div>

                {
                    barList && (
                        <div className="barlist fixed w-screen h-screen z-40 inset-0 bg-black/80">
                            <img src={blury} className="absolute inset-0 w-full h-full z-0" />

                            {/* <div id="barlist-top" className="mobile-tablet-nav flex items-center justify-between !bg-transparent" style={{ padding: onTop ? '3rem 2rem 0 2rem' : '1rem 1.5rem', backdropFilter: 'none' }}> */}
                            <div id="barlist-top" className="mobile-tablet-nav flex items-center justify-between !bg-transparent" style={{ padding: '2rem 2rem 1rem 2rem', backdropFilter: 'none' }}>
                                <Link to={"/"} className="flex items-center gap-2 text-black hover:!text-black">
                                    {/* <img src={logoWhite} /> */}
                                    <img src={logo2} width={110} className="invert" />
                                </Link>
                                <div className="flex items-center gap-6">
                                    <Link to='/shopping-cart' className="cursor-pointer transition relative">
                                        {cart.length > 0 && <div className="absolute -right-0.5 top-0 w-3 h-3 rounded-full bg-[--orange-primary]" />}
                                        <img src={buyWhite} width={31} />
                                    </Link>

                                    <span onClick={() => setBarList(false)} className="cursor-pointer" >
                                        <img src={closeIcon} className="ml-[3px] mr-[2px]" />
                                    </span>
                                </div>
                            </div>



                            <ul className="min-h-[500px] py-32 overflow-y-auto relative w-full h-full flex flex-col gap-5 items-center justify-center text-white">
                                <li ref={li1} className="transition">
                                    <Link to={"/"} className={`!text-white hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 px-3 transition ${pathname === "/" ? 'active' : ''}`}>صفحه اصلی</Link>
                                </li>

                                <li ref={li2} className="transition" onClick={handler1}>
                                    <Link to={""} className={`flex w-full items-center gap-x-2 ${fromHomePage ? 'bg-white group-hover:!bg-[#f4f4f4] text-black' : 'bg-[--orange-primary] group-hover:bg-[--orange-primary-hover] text-white'} rounded-[.4rem] h-[37px] px-3`}>
                                        <span className="">خدمات</span>
                                        <span ref={li2Icon} className="text-[13px] transition"><FaChevronDown /></span>
                                    </Link>
                                </li>
                                <li ref={li21} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/courses">دوره‌های آموزشی</Link>
                                </li>
                                <li ref={li22} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/albums">آلبوم‌ها</Link>
                                </li>
                                <li ref={li23} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/live-meeting">روی خط</Link>
                                </li>
                                {!isDotIr &&
                                    <li ref={li24} className="transition" style={{ display: 'none', opacity: '0' }}>
                                        <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/events">رویدادها</Link>
                                    </li>
                                }
                                {isDotIr &&
                                    <li ref={li241} className="transition" style={{ display: 'none', opacity: '0' }}>
                                        <Link className="!text-white !opacity-0 hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3 pointer-events-none" to=""></Link>
                                    </li>
                                }
                                {!isDotIr &&
                                    <li ref={li25} className="transition" style={{ display: 'none', opacity: '0' }}>
                                        <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/consultation">مشاوره خصوصی</Link>
                                    </li>
                                }
                                {isDotIr &&
                                    <li ref={li251} className="transition" style={{ display: 'none', opacity: '0' }}>
                                        <Link className="!text-white !opacity-0 hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3 pointer-events-none" to=""></Link>
                                    </li>
                                }

                                <li ref={li3} className="transition" onClick={handler2}>
                                    <Link to={""} className="!text-white flex w-full items-center gap-x-2 rounded-[.4rem] h-[37px] p-2 px-3 group-hover:bg-black/5 transition">
                                        <span className="">تجربه کن</span>
                                        <span ref={li3Icon} className="text-[13px] transition"><FaChevronDown /></span>
                                    </Link>
                                </li>

                                <li ref={li31} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/reading">بخوانیم</Link>
                                </li>
                                <li ref={li32} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/writing">بنویسیم</Link>
                                </li>
                                <li ref={li33} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/listening">بشنویم</Link>
                                </li>
                                <li ref={li34} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/meditation">مدیتیشن</Link>
                                </li>
                                <li ref={li35} className="transition" style={{ display: 'none', opacity: '0' }}>
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/podcast">پادکست</Link>
                                </li>

                                <li ref={li4} className="transition">
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/donation">حمایت مالی</Link>
                                </li>
                                <li ref={li5} className="transition">
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/terms">قوانین و مقررات</Link>
                                </li>
                                <li ref={li6} className="transition">
                                    <Link className="!text-white hover:bg-black/5 transition rounded-[.4rem] h-[37px] p-2 px-3" to="/contact-us">تماس با ما</Link>
                                </li>

                                <li ref={li0} className="opacity-0">
                                    <Link className="!text-white hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 px-3 transition pointer-events-none" to=""></Link>
                                </li>

                                {!isLogin ?
                                    <>
                                        <li ref={li7} className="transition">
                                            <Link className="!text-white hover:bg-black/5 rounded-[.4rem] h-[37px] p-2 px-3 transition" to="/auth/login">ورود</Link>
                                        </li>
                                        <li ref={li8} className="transition">
                                            <Link className={`nav-register rounded-[.4rem] h-[37px] flex gap-[.4rem] items-center transition ${fromHomePage ? 'bg-white group-hover:!bg-[#f4f4f4] text-black' : 'bg-[--orange-primary] group-hover:bg-[--orange-primary-hover] text-white'} rounded-[.4rem] h-[37px] px-3`} to="/auth/registration">
                                                <img src={addUser} alt="" style={fromHomePage ? {} : { filter: 'invert(1)' }} />
                                                ثبت نام
                                            </Link>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li ref={li7} className="relative -mt-[5px]">
                                            <Link to={"/dashboard"} className="flex gap-2 items-center bg-white text-black p-1 px-3 ps-5 rounded">
                                                <img src={currentUser?.avatar.endsWith('blank.png') ? userIcon : currentUser?.avatar || userIcon} width={32} className="rounded-full" />

                                                <span className="block font-semibold ltr text-right">
                                                    {currentUser?.full_name || currentUser?.email.slice(0, 5).concat('...')}
                                                </span>
                                            </Link>

                                            <div className="absolute -left-12 -top-0.5 p-3 cursor-pointer" title="خروج" onClick={logout}>
                                                {loading2 ?
                                                    <span className="spinner-border spinner-border-sm align-middle mx-[5px] mb-2"></span>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21px" className="rotate-180" viewBox="0 -960 960 960" fill="#fff"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" /></svg>
                                                }
                                            </div>
                                        </li>
                                        <li ref={li8}>
                                            <Link to={"/dashboard"} className="text-white p-2">داشبورد</Link>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                    )
                }
            </nav>
        </>
    )
}

export default Main_Nav;