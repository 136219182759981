import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import CommentsSection from '@/components/CommentsSection';
import axiosInstance from '@/utils/axiosInstance';
import { convertNumsToFa } from '@/utils/numLangChanger'
import './css.css'
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr'
import Cookies from 'js-cookie'

import lightImg from '@/assets/image/light.png'

import workshopIcon from '@/assets/svg/workshop.svg'
import diamondIcon from '@/assets/svg/diamond.svg'
import starIcon from '@/assets/svg/Star 3.svg'
import chatIcon from '@/assets/svg/Chat 2.svg'
import videoIcon from '@/assets/svg/Video.svg'
import docIcon from '@/assets/svg/doc.svg'
import priceIcon from '@/assets/svg/price.svg'
import arrowIcon from '@/assets/svg/Arrow - Left.svg'
import basketIcon from '@/assets/svg/Buy-white.svg'
import meditationIcon from "@/assets/svg/meditation.svg"
import lineDecorationIcon from "@/assets/svg/line-decoration1.svg"
import lineDecoration2Icon from "@/assets/svg/line-decoration1-gray.svg"
import planetIcon from "@/assets/svg/planet.svg"
import meditation2Icon from "@/assets/svg/meditation2.svg"
import circlesIcon from "@/assets/svg/circles.svg"
import circles2Icon from "@/assets/svg/circles2.svg"
import excludeIcon from "@/assets/svg/Exclude.svg"

declare let tippy;

const logError = (error, context) => {
    console.error(`Error in ${context}:`, {
        message: error.message,
        stack: error.stack,
        response: error.response?.data,
        status: error.response?.status,
        timestamp: new Date().toISOString()
    });
};

const decodeHtmlEntities = (str) => {
    try {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    } catch (error) {
        logError(error, 'decodeHtmlEntities');
        return str;
    }
};

const splitText = (text: string) => {
    try {
        if (!text) return ['', ''];
        const decodedText = decodeHtmlEntities(text);
        return decodedText.split(/<v>/);
    } catch (error) {
        logError(error, 'splitText');
        return ['', ''];
    }
};

const CourseView = () => {
    // State management
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [noComment, setNoComment] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState(null);
    const [purchasedProducts, setPurchasedProducts] = useState([]);
    const [commentCount, setCommentCount] = useState(0);

    // Refs
    const des1 = useRef(null);
    const des2 = useRef(null);
    const des3 = useRef(null);
    const theEl = useRef(null);
    const theEl2 = useRef(null);
    const tooltipRef = useRef(null);
    const tooltipRef2 = useRef(null);
    const tooltipRef3 = useRef(null);
    const tooltipRef4 = useRef(null);
    const tooltipRef5 = useRef(null);

    // State for layout measurements
    const [desFromTop, setDesFromTop] = useState(0);
    const [desFromTop2, setDesFromTop2] = useState(0);

    const { id } = useParams();
    const { cart, addToCart } = useContext(CartContext);
    const isInCart = cart.some(cartItem => cartItem.id === +id);

    // Course data processing
    const chapters = courseData?.chapters || [];
    const pageType = courseData?.package || 0;
    const bgColor = courseData?.color === 'purple' ? '#111123' : courseData?.color === 'green1' ? '#11231F' : '#001605';

    // Close popup handler
    const close_Popup = (e) => {
        try {
            const dropdownElement = document.querySelector('.nav-dropdown');
            if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) {
                setNav_DropDown(false);
            }
        } catch (error) {
            logError(error, 'close_Popup');
        }
    };

    // Fetch course data
    const fetchData = async () => {
        try {
            console.log('Fetching course data for ID:', id);
            const response = await axiosInstance.get('/website/course_show/' + id);
            const course = response.data.courses[0];

            console.log('Course data fetched:', { courseId: course.id, title: course.title_fa });

            if (!course) {
                setError({ message: 'Course not found' });
                setLoading(false);
                return;
            }

            // Fetch purchase status if logged in
            const token = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
            if (token) {
                try {
                    const purchasedResponse = await axiosInstance.get('/order/purchased', {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    if (purchasedResponse.data.status === 1) {
                        const purchasedOrders = purchasedResponse.data.purchased_orders;
                        const purchasedPackages = purchasedResponse.data.purchased_packages;

                        const isPurchasedInIr = purchasedOrders.ir.includes(course.id) || purchasedPackages.ir.includes(course.id);
                        const isPurchasedInCom = purchasedOrders.com.includes(course.id) || purchasedPackages.com.includes(course.id);

                        setCourseData({
                            ...course,
                            isPurchasedInIr,
                            isPurchasedInCom,
                        });
                    } else {
                        setCourseData(course);
                    }
                } catch (purchaseError) {
                    logError(purchaseError, 'fetchPurchaseStatus');
                    setCourseData(course);
                }
            } else {
                setCourseData(course);
            }
        } catch (error) {
            logError(error, 'fetchData');
            setError({ message: 'Error loading course data' });
        } finally {
            setLoading(false);
        }
    };

    const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
    const checkIfPurchased = product => {
        if (!product) return;
        return purchasedProducts?.find(i => i === product.id);
    }

    const fetchPurchasedData = async () => {
        try {
            const response = await axiosInstance.get('/order/purchased', {
                headers: {
                    'Authorization': `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                },
            });

            if (response.data.status === 1) {
                const purchasedOrders = response.data.purchased_orders;
                const purchasedPackages = response.data.purchased_packages;

                const purchasedInIr = [...purchasedOrders.ir, ...purchasedPackages.ir];
                const purchasedInCom = [...purchasedOrders.com, ...purchasedPackages.com];

                if (isDotIr) {
                    setPurchasedProducts([...purchasedInIr, ...purchasedInCom]);
                } else {
                    setPurchasedProducts(purchasedInCom);
                }
            }
        } catch (error) { }
    };

    useEffect(() => {
        convertNumsToFa();
    }, [courseData]);

    useEffect(() => {
        fetchData();
        if (isLogin) fetchPurchasedData();
    }, []);


    

    useEffect(() => {
        const func = ref => {
            if (ref) {
                tippy(ref, {
                    content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش دوره، VPN خود را خاموش و صفحه را رفرش کنید.",
                });
            }
        }

        func(tooltipRef.current)
        func(tooltipRef2.current)
        func(tooltipRef3.current)
        func(tooltipRef4.current)
        func(tooltipRef5.current)
    }, [courseData]);


    const decodedFullInfo = decodeHtmlEntities(courseData?.full_info)
    const [textBefore = '', textAfter = ''] = decodedFullInfo.split(/<v>/)


    const renderChaptersAfter10 = () => {
        return chapters.slice(10).map((chapter, index) => {
            const isEven = (index + 10) % 2 === 0;
            const isEvenGroup = Math.floor((index + 11) / 2) % 2 === 0;

            return (
                <div key={index + 10} className={`flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] ${!isEven && 'lg:mr-auto lg:flex-row-reverse'}`}>
                    <img src={isEvenGroup ? planetIcon : meditation2Icon} className='max-lg:hidden' />
                    <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                    <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                        <h5 className='text-[--orange-primary]'>{chapter.title_fa}</h5>
                        <p className='font-medium text-[19px]'>{chapter.short_info}</p>
                    </div>
                    <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                </div>
            )
        })
    }


    const handleCommentCountChange = (count: number) => {
        setCommentCount(count);
    };


   
    const scrollHandler = (destination) => {
        if (destination.current) {
            const { top } = destination.current.getBoundingClientRect();
            const offset = 80;
            window.scrollTo({
                top: window.scrollY + top - offset,
                behavior: 'smooth'
            });
        }
    };

    // Handle layout measurements
    const handleDistanceFromTop = () => {
        try {
            const element = theEl.current;
            if (element) {
                const rect = element.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const offsetTop = rect.top + scrollTop;
                setDesFromTop(offsetTop);
            }

            const element2 = theEl2.current;
            if (element2) {
                const rect = element2.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const offsetTop = rect.top + scrollTop;
                setDesFromTop2(offsetTop);
            }
        } catch (error) {
            logError(error, 'handleDistanceFromTop');
        }
    };

    // Process text content

    // Effects
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        document.addEventListener('click', close_Popup);
        return () => document.removeEventListener('click', close_Popup);
    }, [nav_DropDown]);

    useEffect(() => {
        try {
            convertNumsToFa();
        } catch (error) {
            logError(error, 'convertNumsToFa');
        }
    }, [courseData]);

    useEffect(() => {
        handleDistanceFromTop();
        window.addEventListener('resize', handleDistanceFromTop);
        return () => window.removeEventListener('resize', handleDistanceFromTop);
    }, [courseData]);

    // Render loading state
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    // Render error state
    if (error) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen p-4">
                <h2 className="text-2xl font-bold mb-4">خطایی رخ داد</h2>
                <p className="text-gray-600 mb-4">{error.message}</p>
                <button
                    onClick={() => {
                        setError(null);
                        setLoading(true);
                        fetchData();
                    }}
                    className="bg-[--orange-primary] text-white px-4 py-2 rounded hover:bg-[--orange-primary-hover]"
                >
                    تلاش مجدد
                </button>
            </div>
        );
    }

    // Render the main component
    return (
        <>
            <div className='bg-white' onClick={close_Popup}>
                <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

                {/* Main content section */}
                <section className="relative pt-40 max-lg:pt-32 overflow-hidden lg:pb-6 max-lg:pb-20">
                    <div
                        style={{
                            transform: 'skew(0,4deg)',
                            backgroundColor: bgColor,
                            '--des-from-top': `${desFromTop}px`,
                            '--des-from-top2': `${desFromTop2}px`
                        } as React.CSSProperties}
                        className={`bg-box-color bg-box-color-lg absolute inset-0 ${pageType === 0 ? 'mb-[250px] max-lg:mb-[130px]' : 'lg:mb-[45px] max-lg:mb-[30px]'} rounded-[5vw] z-0`}
                    />

                    <div className='my-container'>
                        <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                            <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-4 max-sm:text-center'>
                                <img src={workshopIcon} width={30} className='max-lg:hidden' />
                                {courseData?.title_fa}
                            </h3>
                        </div>

                        <div ref={theEl2} className='text-[#212121] text-[21px] font-semibold  max-lg:text-[20px] max-lg:text-center mb-5'>
                            {courseData?.short_info}
                        </div>

                        <div className='relative z-[2] flex lg:gap-16 max-lg:flex-col max-lg:mt-5 max-w-full items-end'>
                            <div ref={theEl} className='mt-auto mx-auto max-w-full'>
                                <div className='rounded-xl p-1 bg-white w-[365px] max-w-full relative' style={{ border: '1px solid #E0E0E0' }}>
                                    <img src={courseData?.image_media[0].src} className='rounded w-full object-cover h-[440px]' />
                                    {commentCount !== 0 &&
                                        <span className='lg:hidden text-[#1A96F0] text-[18px] font-medium absolute top-5 left-5 p-[.3rem] px-3 rounded-[.3rem] bg-white/80 max-lg:border'>
                                            {commentCount} دیدگاه
                                        </span>
                                    }
                                </div>
                            </div>

                            <div className='grow lg:mt-10 max-lg:text-center mx-auto'>
                                <div className='flex justify-between items-center'>
                                    <div></div>
                                    {commentCount !== 0 &&
                                        <span className='text-[#1A96F0] text-[18px] font-medium max-lg:hidden'>
                                            {commentCount} دیدگاه
                                        </span>
                                    }
                                </div>

                                <div className='flex items-start gap-2 font-semibold lg:mt-7 max-lg:flex-col max-lg:items-center'>
                                    <img src={diamondIcon} className='w-[20px] max-lg:w-[32px] max-lg:mt-6 lg:mt-2' />
                                    <div
                                        className='text-justify text-[21px] max-lg:text-center max-lg:hidden'
                                        dangerouslySetInnerHTML={{ __html: textAfter || textBefore }}
                                    />
                                    <div
                                        className='lg:hidden my-justify text-[21px] max-lg:text-center'
                                        dangerouslySetInnerHTML={{ __html: textAfter || textBefore }}
                                    />
                                </div>

                                <div className='mt-4 flex flex-wrap gap-y-1 gap-x-10 justify-between max-lg:flex-col max-lg:items-center'>
                                    <div className='flex gap-2 items-start'>
                                        <img src={starIcon} width={20} className='mt-1' />
                                        <p className='font-bold text-[21px] my-justify'>پیش نیاز:
                                            <Link to={splitText(courseData?.requirements)[1]} className='text-[#EC7D3E] mr-2 text-[21px]'>
                                                {splitText(courseData?.requirements)[0]}
                                            </Link>
                                        </p>
                                    </div>
                                    <div className='flex gap-2 items-start'>
                                        <img src={starIcon} width={20} className='mt-1' />
                                        <p className='font-semibold text-[21px] my-justify'>قابلیت دانلود:
                                            <span className='text-[#EC7D3E] mr-2 text-[21px]'>
                                                {courseData?.downloadable === 0 ? 'ندارد' : 'دارد'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className='flex gap-2 items-start'>
                                        <img src={starIcon} width={20} className='mt-1' />
                                        <p className='font-semibold text-[21px] my-justify'>تعداد دستگاه‌های مجاز:<span className='text-[#EC7D3E] mr-2 text-[21px]'>دو دستگاه</span></p>
                                    </div>
                                    <div className='flex gap-2 items-start'>
                                        <img src={starIcon} width={20} className='mt-1' />
                                        <p className='font-semibold text-[21px] my-justify'>نحوه دسترسی:
                                            <span className='text-[#EC7D3E] mr-2 text-[21px]'>
                                                {courseData?.access_type}
                                            </span>
                                        </p>
                                    </div>
                                    <div className='flex gap-2 items-start'>
                                        <img src={starIcon} width={20} className='mt-1' />
                                        <p className='font-semibold text-[21px] my-justify'>محدودیت دسترسی:<span className='text-[#EC7D3E] mr-2 text-[21px]'>
                                            {isDotIr ? 'جهت استفاده در کشور ایران' : 'محدودیت دسترسی ندارد'}
                                        </span></p>
                                    </div>
                                </div>

                                <div className='max-lg:hidden flex gap-2 items-center rounded bg-[#00a86a1e] p-[.6rem] px-4 my-5 max-lg:items-start max-lg:mx-auto'>
                                    <img src={excludeIcon} className='max-lg:mt-1' />
                                    <p className='text-[18px] font-medium text-[#12D18E] text-right'>پرسش و پاسخ فردی و مشاوره انفرادی بخشی از خدمات این دوره نمی‌باشد.</p>
                                </div>

                                {pageType === 0 &&
                                    <>
                                        <div className='flex justify-between max-lg:justify-center flex-wrap items-center gap-3 max-lg:mt-8'>
                                            <button onClick={() => scrollHandler(des1)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                                <img src={docIcon} className='max-lg:hidden' width={20} />
                                                <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>سرفصل‌های جلسات</p>
                                            </button>
                                            <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                            <button onClick={() => scrollHandler(des2)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                                <img src={videoIcon} className='max-lg:hidden' width={21} />
                                                <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>دموی دوره</p>
                                            </button>
                                            <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                            <button onClick={() => scrollHandler(des3)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                                <img src={chatIcon} className='max-lg:hidden' width={21} />
                                                <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>دیدگاه شرکت کنندگان</p>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center mt-8 max-lg:hidden">
                                            <div className='flex gap-2 items-center'>
                                                <img src={priceIcon} width={20} />
                                                <p>قیمت دوره: </p>
                                                {courseData?.discount_price && courseData?.discount_price !== courseData?.price ?
                                                    <>
                                                        <div className={`rounded px-2 text-[23px] font-bold bg-[--orange-primary] ${!isDotIr && 'keep-english-numbers'}`}>
                                                            {isDotIr ? (courseData?.discount_price / 10).toLocaleString() : '$' + courseData?.discount_price.toLocaleString()}
                                                            <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                                        </div>
                                                        <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                            {isDotIr ? (courseData?.price / 10).toLocaleString() : courseData?.price.toLocaleString()}
                                                        </p>
                                                    </>
                                                    :
                                                    <p className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                        {isDotIr ? (courseData?.price / 10).toLocaleString() : '$' + courseData?.price.toLocaleString()} {' '}
                                                        <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                                    </p>
                                                }
                                            </div>
                                            {courseData?.remain_capacity === 0 ?
                                                <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-2'>
                                                    <p className='font-semibold text-[20px]'>ظرفیت تکمیل شده است.</p>
                                                </button>
                                                : checkIfPurchased(courseData) ?
                                                    <Link to={`/dashboard/products/courses/course/${courseData.id}`} className='flex gap-2 text-white items-center bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition rounded px-4 p-2'>بخش نمایش دوره</Link>
                                                    :
                                                    <button onClick={() => addToCart(courseData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-2 relative'>
                                                        <img src={basketIcon} width={20} />
                                                        <p className='font-semibold text-[20px]'>
                                                            {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                        </p>
                                                        <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                                        {!isDotIr && courseData?.isPurchasedInIr && !courseData?.isPurchasedInCom &&
                                                            <div ref={tooltipRef} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                    <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </button>
                                            }
                                        </div>
                                    </>
                                }

                                {pageType === 1 &&
                                    <div className="flex justify-between flex-wrap gap-x-16 gap-y-6 items-center mt-8 max-lg:hidden">
                                        <button onClick={() => scrollHandler(des3)} className='noncolor flex gap-2 items-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                            <img src={chatIcon} className='max-lg:hidden' width={21} />
                                            <p className='font-semibold max-lg:font-medium max-lg:text-[18px]'>دیدگاه شرکت کنندگان</p>
                                        </button>
                                        <div className="flex justify-between gap-5 grow">
                                            <div className='flex gap-2 items-center'>
                                                <img src={priceIcon} width={20} />
                                                <p>قیمت دوره: </p>
                                                {courseData?.discount_price && courseData?.discount_price !== courseData?.price ?
                                                    <>
                                                        <div className={`rounded px-2 text-[23px] font-bold bg-[--orange-primary] ${!isDotIr && 'keep-english-numbers'}`}>
                                                            {isDotIr ? (courseData?.discount_price / 10).toLocaleString() : '$' + courseData?.discount_price.toLocaleString()} {' '}
                                                            <span className='text-[21px] font-medium'>{isDotIr && 'تومان'}</span>
                                                        </div>
                                                        <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                            {isDotIr ? (courseData?.price / 10).toLocaleString() : courseData?.price.toLocaleString()}
                                                        </p>
                                                    </>
                                                    :
                                                    <p className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                        {isDotIr ? (courseData?.price / 10).toLocaleString() : '$' + courseData?.price.toLocaleString()}
                                                        <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                                    </p>
                                                }
                                            </div>
                                            {courseData?.remain_capacity === 0 ?
                                                <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-2'>
                                                    <p className='font-semibold text-[20px]'>ظرفیت تکمیل شده است.</p>
                                                </button>
                                                : checkIfPurchased(courseData) ?
                                                    <Link to={`/dashboard/products/courses/course/${courseData.id}`} className='flex gap-2 text-white items-center bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition rounded px-4 p-2'>بخش نمایش دوره</Link>
                                                    :
                                                    <button onClick={() => addToCart(courseData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-2 relative'>
                                                        <img src={basketIcon} width={20} />
                                                        <p className='font-semibold text-[20px]'>
                                                            {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                        </p>
                                                        <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                                        {!isDotIr && courseData?.isPurchasedInIr && !courseData?.isPurchasedInCom &&
                                                            <div ref={tooltipRef2} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                    <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {pageType === 0 &&
                            <>
                                <div className='relative z-[1] flex justify-between max-lg:flex-col-reverse gap-5 mt-16 items-center lg:pl-32'>
                                    <img src={lineDecorationIcon} className='absolute left-0 top-[100px] max-lg:hidden' />
                                    <div className='relative z-[1] max-w-[503px] w-full'>
                                        <h4 className='max-lg:font-bold max-lg:text-[32px] max-lg:my-4 max-lg:text-center'>
                                            {courseData?.details[0]?.title}
                                        </h4>
                                        <div
                                            className='text-[20px] text-justify lg:mt-10'
                                            dangerouslySetInnerHTML={{ __html: courseData?.details[0]?.info }}
                                        />
                                    </div>
                                    <div className='w-[445px] max-h-[445px] max-w-full relative'>
                                        <div className='absolute z-[0] inset-0 scale-[3] opacity-40' style={{ background: 'radial-gradient(circle, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 50%)' }}></div>
                                        {/* <img src={courseData?.details[0]?.image} className='p-1 bg-white w-full max-h-[445px] object-cover relative z-[1]' style={{ borderRadius: '37% 63% 50% 50% / 58% 43% 57% 42%' }} /> */}
                                        <img src={courseData?.details[0]?.image} className='p-1 w-full max-h-[445px] object-contain relative z-[1]' />
                                    </div>
                                </div>

                                <div className='relative z-[2] mt-16 flex flex-col items-center gap-5'>
                                    {courseData?.details[1]?.image ?
                                        <img src={courseData?.details[1]?.image} className='max-w-[445px] max-h-[445px]' />
                                        :
                                        <img src={meditationIcon} width={70} />
                                    }
                                    <h3 className='text-[28px]'>
                                        {courseData?.details[1]?.title}
                                    </h3>
                                    <div
                                        id='my-text'
                                        className='text-[18px] text-center font-medium'
                                        dangerouslySetInnerHTML={{ __html: courseData?.details[1]?.info }}
                                    />
                                    {courseData?.remain_capacity === 0 ?
                                        <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-2'>
                                            <p className='font-semibold text-[20px]'>ظرفیت تکمیل شده است.</p>
                                        </button>
                                        : checkIfPurchased(courseData) ?
                                            <Link to={`/dashboard/products/courses/course/${courseData.id}`} className='flex gap-2 text-white items-center bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition rounded px-4 p-2'>بخش نمایش دوره</Link>
                                            :
                                            <button onClick={() => addToCart(courseData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='max-lg:hidden group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-3 relative'>
                                                <p className='font-semibold text-[19px]'>
                                                    {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                </p>
                                                <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                                {!isDotIr && courseData?.isPurchasedInIr && !courseData?.isPurchasedInCom &&
                                                    <div ref={tooltipRef3} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                        </svg>
                                                    </div>
                                                }
                                            </button>
                                    }
                                </div>

                                <div className='relative z-[1] flex justify-between gap-10 max-lg:flex-col mt-20 items-center lg:pr-32'>
                                    <img src={lineDecorationIcon} className='absolute right-0 top-[100px] max-lg:hidden' />
                                    <div className='w-[445px] max-h-[445px] max-w-full relative'>
                                        <div className='absolute z-[0] inset-0 scale-[3] opacity-40' style={{ background: 'radial-gradient(circle, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 50%)' }}></div>
                                        {/* <img src={courseData?.details[2]?.image} className='p-1 bg-white w-full max-h-[445px] object-cover relative z-[1]' style={{ borderRadius: '53% 47% 49% 51% / 56% 63% 37% 44%' }} /> */}
                                        <img src={courseData?.details[2]?.image} className='p-1 w-full max-h-[445px] object-contain relative z-[1]' />
                                    </div>
                                    <div className='relative z-[1] max-w-[503px] w-full grow max-lg:ml-auto'>
                                        <h4 className='max-lg:font-bold max-lg:text-[32px] max-lg:my-4'>
                                            {courseData?.details[2]?.title}
                                        </h4>
                                        <div
                                            id='my-text2'
                                            className='text-[20px] text-justify font-semibold lg:mt-10'
                                            dangerouslySetInnerHTML={{ __html: courseData?.details[2]?.info }}
                                        />
                                    </div>
                                </div>

                                <div ref={des2} className='relative z-[2] sm:pt-16 max-sm:pt-12 flex flex-col items-center gap-5 w-[782px] max-w-full mx-auto'>
                                    <h3>دموی {courseData?.title_fa}</h3>
                                    <div
                                        className='iframe-box w-full keep-english-numbers'
                                        dangerouslySetInnerHTML={{ __html: courseData?.demo }}
                                    />
                                    <div className="max-lg:hidden flex gap-5 justify-between items-center w-full">
                                        <div className='h-[1px] grow bg-[#BDBDBD]'></div>
                                        {courseData?.remain_capacity === 0 ?
                                            <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-2'>
                                                <p className='font-semibold text-[20px]'>ظرفیت تکمیل شده است.</p>
                                            </button>
                                            : checkIfPurchased(courseData) ?
                                                <Link to={`/dashboard/products/courses/course/${courseData.id}`} className='flex gap-2 text-white items-center bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition rounded px-4 p-2'>بخش نمایش دوره</Link>
                                                :
                                                <button onClick={() => addToCart(courseData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='max-lg:hidden group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-3 relative'>
                                                    <p className='font-semibold text-[19px]'>
                                                        {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                    </p>
                                                    <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                                    {!isDotIr && courseData?.isPurchasedInIr && !courseData?.isPurchasedInCom &&
                                                        <div ref={tooltipRef4} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                </button>
                                        }
                                        <div className='h-[1px] grow bg-[#BDBDBD]'></div>
                                    </div>
                                </div>
                            </>
                        }

                        {pageType === 1 &&
                            <>
                                <div className='max-lg:hidden w-full relative px-32 flex flex-col gap-5 items-center mt-24'>
                                    <img src={lightImg} className='absolute left-[-50px] top-[-100px] max-lg:hidden' />
                                    <h3>این بسته شامل اشتراک آنلاین دوره های زیر است</h3>
                                    <div className='max-lg:hidden w-full flex items-center'>
                                        <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                        <img src={circles2Icon} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                    </div>
                                </div>

                                {courseData.included_courses.map((course, index) => {
                                    const [textBefore, textAfter] = splitText(course.full_info || '');

                                    return (
                                        <div
                                            key={index}
                                            className={`relative z-[1] flex lg:${index % 2 === 0 ? 'px-32' : 'flex-row-reverse lg:px-32'} justify-between max-lg:flex-col-reverse gap-5 mt-6 items-center lg:pl-32 ${index % 2 === 0 ? 'max-lg:mb-6' : ''}`}
                                        >
                                            {index === courseData.included_courses.length - 1 && (
                                                <img src={lightImg} className='absolute right-[-50px] bottom-10 max-lg:hidden' />
                                            )}
                                            <div className='relative z-[1] max-w-[503px] w-full'>
                                                <h4 className='max-lg:font-bold max-lg:mb-4'>
                                                    {course.title_fa}
                                                </h4>
                                                <div className='h-[1px] w-full bg-[#eee4] mb-6 mt-4'></div>
                                                <div className='text-[20px] text-justify'>
                                                    <div dangerouslySetInnerHTML={{ __html: textBefore }} />
                                                    <div dangerouslySetInnerHTML={{ __html: textAfter }} />
                                                </div>
                                            </div>
                                            <div className='w-[291px] max-sm:w-full min-w-[250px] max-w-full relative'>
                                                <img src={course.image_media[0].src} className='p-1 bg-white w-full object-cover rounded-xl relative z-[1]' />
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        }
                    </div>
                </section>

                {pageType === 0 &&
                    <>
                        <section ref={des1} className='my-container text-black lg:mt-5 relative lg:!px-32'>
                            {chapters.length >= 3 &&
                                <img src={lineDecoration2Icon} className='max-lg:hidden absolute z-0 left-[-20px] top-[-250px] max-lg:w-[75px] max-lg:right-[1rem] max-lg:left-auto max-lg:top-40' />
                            }
                            {chapters.length >= 5 &&
                                <img src={lineDecoration2Icon} className='lg:hidden absolute z-0 left-[-20px] top-[-250px] max-lg:w-[75px] max-lg:right-[1rem] max-lg:left-auto max-lg:top-40' />
                            }

                            {chapters.length >= 4 &&
                                <img src={lineDecoration2Icon} className='max-lg:hidden absolute z-0 right-[-20px] bottom-[-150px] max-lg:w-[75px] max-lg:right-auto max-lg:left-[1rem] max-lg:bottom-20' />
                            }
                            {chapters.length >= 10 &&
                                <img src={lineDecoration2Icon} className='lg:hidden absolute z-0 right-[-20px] bottom-[-150px] max-lg:w-[75px] max-lg:right-auto max-lg:left-[1rem] max-lg:bottom-20' />
                            }

                            <h3 className='text-black text-center mb-8'>سرفصل‌های دوره</h3>
                            <div className="relative z-[1] flex flex-col gap-6">
                                {chapters[0] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)]'>
                                        <img src={planetIcon} className='max-lg:hidden' />
                                        <img src={meditation2Icon} className='lg:hidden max-lg:absolute left-[44px]' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[0].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[0].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[1] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] mr-auto flex-row-reverse'>
                                        <img src={meditation2Icon} className='max-lg:hidden' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[1].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[1].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[2] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] max-lg:ml-auto max-lg:flex-row-reverse'>
                                        <img src={meditation2Icon} className='max-lg:hidden left-[40px]' />
                                        <img src={planetIcon} className='lg:hidden max-lg:absolute left-[44px]' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[2].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[2].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[3] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] mr-auto flex-row-reverse'>
                                        <img src={planetIcon} className='max-lg:hidden' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[3].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[3].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[4] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] max-lg:mr-auto'>
                                        <img src={planetIcon} className='max-lg:hidden' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[4].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[4].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[5] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] mr-auto max-lg:ml-auto max-lg:mr-0 flex-row-reverse'>
                                        <img src={meditation2Icon} className='max-lg:hidden' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[5].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[5].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[6] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] max-lg:mr-auto'>
                                        <img src={meditation2Icon} className='max-lg:hidden' />
                                        <img src={planetIcon} className='lg:hidden max-lg:absolute right-[40px]' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[6].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[6].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[7] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] mr-auto flex-row-reverse'>
                                        <img src={planetIcon} className='max-lg:hidden' />
                                        <img src={meditation2Icon} className='lg:hidden max-lg:absolute right-[44px]' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[7].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[7].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[8] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)]'>
                                        <img src={planetIcon} className='max-lg:hidden' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[8].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[8].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {chapters[9] &&
                                    <div className='flex gap-3 items-center w-[670px] max-lg:w-[calc(100%-100px)] lg:mr-auto flex-row-reverse'>
                                        <img src={meditation2Icon} className='max-lg:hidden' />
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                        <div className='grow bg-[#F5F5F5] rounded-xl p-4 px-7 max-lg:min-h-[124px] flex flex-col justify-center'>
                                            <h5 className='text-[--orange-primary]'>{chapters[9].title_fa}</h5>
                                            <p className='font-medium text-[19px]'>{chapters[9].short_info}</p>
                                        </div>
                                        <div className='max-lg:hidden h-[80px] w-[1px] bg-[#BDBDBD]'></div>
                                    </div>
                                }
                                {renderChaptersAfter10()}
                            </div>
                        </section>

                        <div className='max-lg:opacity-30 my-container lg:!px-32 flex items-center mt-14'>
                            <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                            <img src={circlesIcon} className='mt-[1px]' />
                            <div className='grow h-[1px] bg-[#BDBDBD]'></div>
                        </div>
                    </>
                }

                <div ref={des3} />
                <CommentsSection
                    onNoComments={() => setNoComment(true)}
                    id={+id}
                    title='دیدگاه شرکت کنندگان دوره'
                    onCommentCountChange={handleCommentCountChange}
                />

                <div className='my-container lg:hidden fixed bottom-0 w-full shadow border-t z-10 h-[75px] bg-white flex gap-5 justify-between items-center'>
                    {courseData?.remain_capacity === 0 ?
                        <button className='bg-[#888] pointer-events-none font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded'>ظرفیت تکمیل شده است.</button>
                        : checkIfPurchased(courseData) ?
                            <Link to={`/dashboard/products/courses/course/${courseData.id}`} className='bg-[--orange-primary] text-white text-center hover:bg-[--orange-primary-hover] font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded'>بخش نمایش دوره</Link>
                            :
                            <button onClick={() => addToCart(courseData)} disabled={isInCart} style={{ background: isInCart && 'var(--orange-primary-active)' }} className='bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded relative'>
                                <>
                                    {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                    {!isDotIr && courseData?.isPurchasedInIr && !courseData?.isPurchasedInCom &&
                                        <div ref={tooltipRef5} className='absolute -top-2 right-0 bg-white rounded-full'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                            </svg>
                                        </div>
                                    }
                                </>
                            </button>
                    }
                    <div className="flex flex-col">
                        {courseData?.discount_price && courseData?.discount_price !== courseData?.price ?
                            <>
                                <span className='font-semibold text-[23px] text-[#424242]'>
                                    <span className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                        {isDotIr ? (courseData?.discount_price / 10).toLocaleString() : courseData?.discount_price.toLocaleString()}
                                    </span>{' '}
                                    <span className='font-medium text-[23px]'>
                                        {isDotIr ? 'تومان' : '$'}
                                    </span>
                                </span>
                                <span className='font-semibold text-[#424242] mt-[-5px] line-through text-left'>
                                    <span className={`text-[18.5px] ${!isDotIr && 'keep-english-numbers'}`}>
                                        {isDotIr ? (courseData?.price / 10).toLocaleString() : courseData?.price.toLocaleString()}
                                    </span>
                                </span>
                            </>
                            :
                            <span className='font-semibold text-[23px] text-[#424242]'>
                                <span className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                    {isDotIr ? (courseData?.price / 10).toLocaleString() : courseData?.price.toLocaleString()}
                                </span>{' '}
                                <span className='font-medium text-[23px]'>
                                    {isDotIr ? 'تومان' : '$'}
                                </span>
                            </span>
                        }
                    </div>
                </div>

                <Footer />

                <div className='lg:hidden h-[75px]'></div>
            </div>
        </>
    )
}

export default CourseView;