import React, { useState, useEffect } from 'react';
import axiosInstance from '@/utils/axiosInstance';
import { convertNumsToFa } from '@/utils/numLangChanger';
import Cookies from 'js-cookie'
import { isDotIr } from '@/utils/isDotIr'

import img from '@/metronic-dashboard/assets/email-sent.png';

const EmailSent = () => {
    const [timer, setTimer] = useState(120);
    const [isResendActive, setIsResendActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setIsResendActive(true);
        }
    }, [timer]);

    const handleResend = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await axiosInstance.get('/resend_verify_email', {
                headers: {
                    Authorization: `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                }
            });
            if (response.data.status === 1) {
                setTimer(120);
                setIsResendActive(false);
                setLoading(false);
            } else {
                setErrorMessage('مشکلی پیش آمد. لطفا بعدا دوباره تلاش کنید.');
                setLoading(false);
            }
        } catch (error) {
            setErrorMessage('مشکلی پیش آمد. لطفا بعدا دوباره تلاش کنید.');
            setLoading(false);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    useEffect(() => {
        convertNumsToFa();
    }, [timer]);

    return (
        <div className="my-14">
            <img src={img} alt="Email Sent" width={100} className='mx-auto mb-8' />
            <h1 className="text-center mb-2 text-[27.7px]">ایمیل حاوی لینک تایید به آدرس ایمیل شما ارسال شد.</h1>
            <p className="text-center">لطفاً برای ادامه ایمیل خود را بررسی نمایید.</p>
            <div className="text-center fw-semibold fs-7 mt-10">
                <span className="me-1">ایمیل را دریافت نکرده‌اید؟</span>
                {isResendActive ? (
                    loading ? (
                        <span className="spinner-border spinner-border-sm align-middle me-1 mb-1"></span>
                    ) : (
                        <button onClick={handleResend} className="link-primary me-1">ارسال مجدد</button>
                    )
                ) : (
                    <span className="text-muted me-1">ارسال مجدد در {formatTime(timer)}</span>
                )}
            </div>
            {errorMessage && <div className="text-danger text-center mt-3">{errorMessage}</div>}
        </div>
    );
};

export default EmailSent;