import { useState } from 'react';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { useIntl } from 'react-intl'
import { isDotIr } from '@/utils/isDotIr'

export function AsideMenuMain() {
  const intl = useIntl()

  const [openAccordion, setOpenAccordion] = useState<string>('');

  function closeOtherAccordions(to: string) {
    setOpenAccordion(to);
  }

  return (
    <>
      <AsideMenuItemWithSub
        to='/dashboard/profile'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        icon='profile-circle'
        isOpen={openAccordion === '/dashboard/profile'}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            setOpenAccordion('/dashboard/profile');
          } else {
            setOpenAccordion('');
          }
        }}
        closeOtherAccordions={() => closeOtherAccordions('/dashboard/profile')}
      >
        <AsideMenuItem to='/dashboard/profile/overview' title='داشبورد' hasBullet={true}
          children={null} />
        <AsideMenuItem to='/dashboard/profile/edit' title='ویرایش حساب' hasBullet={true}
          children={null} />
        <AsideMenuItem to='/dashboard/profile/security' title='امنیت' hasBullet={true}
          children={null} />
      </AsideMenuItemWithSub>

      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 mt-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8'>محصولات</span>
        </div>
      </div> */}

      <AsideMenuItem
        to='/dashboard/products/courses'
        icon='abstract-26'
        title='دوره‌های آموزشی'
        children={null}
      />

      <AsideMenuItem
        to='/dashboard/products/albums'
        icon='abstract-5'
        title='آلبوم‌های موسیقی'
        children={null}
      />

      <AsideMenuItem
        to='/dashboard/products/rooyekhat'
        icon='abstract-17'
        title='روی خط'
        children={null}
        bigLogo
      />

      <AsideMenuItem
        to='/dashboard/donation'
        icon='abstract-46'
        title='حمایت مالی'
        children={null}
      />

      {!isDotIr &&
        <AsideMenuItem
          to='/dashboard/consultation'
          icon='calendar-2'
          title='مشاوره خصوصی'
          children={null}
        />
      }

      {/* {!isDotIr &&
        <AsideMenuItem
          to='/dashboard/products/events'
          icon='abstract-25'
          title='رویدادها'
          children={null}
        />
      } */}

      <AsideMenuItemWithSub
        to='/dashboard/products/free-services'
        title='تجربه کن'
        icon='abstract-36'
        isOpen={openAccordion === '/dashboard/products/free-services'}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            setOpenAccordion('/dashboard/products/free-services');
          } else {
            setOpenAccordion('');
          }
        }}
        closeOtherAccordions={() => closeOtherAccordions('/dashboard/products/free-services')}
      >
        <AsideMenuItem to='/dashboard/products/free-services/podcast' title='پادکست' hasBullet={true}
          children={null} />
        <AsideMenuItem to='/dashboard/products/free-services/meditation' title='مدیتیشن رایگان' hasBullet={true}
          children={null} />
        <AsideMenuItem to='/dashboard/products/free-services/nbooks' title='بخوانیم' hasBullet={true}
          children={null} />
        <AsideMenuItem to='/dashboard/products/free-services/rooznegar' title='بنویسیم' hasBullet={true}
          children={null} />
        <AsideMenuItem to='/dashboard/products/free-services/navaye-zen' title='بشنویم' hasBullet={true}
          children={null} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/dashboard/support/faq'
        icon='chart-pie-3'
        title='سوالات متداول'
        children={null}
      />

      <AsideMenuItem
        to='/dashboard/support/tickets'
        icon='message-text-2'
        title='پشتیبانی (تیکت)'
        children={null}
      />

      <AsideMenuItem
        to='/dashboard/financial'
        title='سوابق خرید'
        icon='credit-cart'
        children={null}
      />

      <AsideMenuItem
        to='/dashboard/support-services'
        title='خدمات حمایتی'
        icon='abstract-46'
        children={null}
      />

      <AsideMenuItem
        to='/dashboard/about-us'
        icon='information-2'
        title='آشنایی با حسین'
        children={null}
      />

      <AsideMenuItem
        to=''
        icon='arrow-up-left'
        title='درخواست همکاری'
        children={null}
        disable
      />

      <AsideMenuItem
        to='/dashboard/terms'
        icon='book'
        title='قوانین و مقررات'
        children={null}
      />
    </>
  )
}