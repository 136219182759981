import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import axios from 'axios';

// تعریف نوع داده‌های IP
interface IpInfo {
    country_code2: string;
}

// تعریف نوع داده‌های Context
interface IpInfoContextType {
    isIranian: boolean;
    ipInfoloading: boolean;
}

// ایجاد Context
const IpInfoContext = createContext<IpInfoContextType | undefined>(undefined);

// ایجاد Provider
export const IpInfoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isIranian, setIsIranian] = useState<boolean>(false);
    const [ipInfoloading, setIpInfoLoading] = useState<boolean>(true);

    const fetchIpInfo = async (): Promise<IpInfo | null> => {
        try {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const ipAddress = ipResponse.data.ip;

            const response = await axios.get(`https://api.iplocation.net/?ip=${ipAddress}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching IP info:', error);
            return null;
        }
    };

    const checkIranianStatus = async () => {
        const ipInfo = await fetchIpInfo();
        if (ipInfo && ipInfo.country_code2 === 'IR') {
            setIsIranian(true);
        } else {
            setIsIranian(false);
        }
        setIpInfoLoading(false);
    };

    useEffect(() => {
        checkIranianStatus();
    }, []);

    return (
        <IpInfoContext.Provider value={{ isIranian, ipInfoloading }}>
            {children}
        </IpInfoContext.Provider>
    );
};

// Custom hook for easy access to context
export const useIpInfo = (): IpInfoContextType => {
    const context = useContext(IpInfoContext);
    if (context === undefined) {
        throw new Error('useIpInfo must be used within an IpInfoProvider');
    }
    return context;
};