import { useLayout } from '@/metronic-dashboard/helpers/layout/core/LayoutProvider'
// import {DefaultTitle} from './page-title/DefaultTitle'
import { ThemeModeSwitcher } from '@/metronic-dashboard/layout/partials/theme-mode/ThemeModeSwitcher'
import { DefaultTitle } from './page-title/DefaultTitle'

const HeaderToolbar = () => {
  const { classes } = useLayout()


  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex align-items-lg-stretch justify-content-lg-between w-100 px-5`}
      >

        <DefaultTitle />

        <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Separartor */}
            <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
            {/* end::Separartor */}
          </div>
          {/* end::Action wrapper */}

          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Label */}
            <span className='fs-7 text-gray-800 fw-bolder pe-3'>
              پوسته:
            </span>
            {/* end::Label */}

            {/* begin::Actions */}
            <div className='d-flex'>
              {/* begin::Theme mode */}
              <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div>
              {/* end::Theme mode */}
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  )
}

export { HeaderToolbar }