import { useEffect, useState, useRef } from 'react';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";

import light from '@/assets/image/light4.png'

import bookIcon from '@/assets/svg/book.svg'

const Terms = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    return (
        <div className='text-white bg-white' onClick={close_Popup}>
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden">
                <div style={{ transform: 'skew(0,4deg)', background: 'linear-gradient(180deg, #443F39, #20201C)' }} className='absolute inset-0 md:mt-[320px] max-md:mt-[270px] mb-[50px] rounded-[5vw] z-0'></div>

                <div className='my-container relative mb-8'>
                    <div className='max-lg:hidden absolute z-0 left-[-240px] top-60 w-[400px] h-[1000px]'>
                        <img src={light} className='mx-auto' />
                        <div className='absolute inset-0 top-[300px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #CCC0B211 0%, transparent 40%)' }}></div>
                    </div>
                    <div className='max-lg:hidden absolute z-0 right-[-240px] bottom-0 w-[400px] h-[1000px]'>
                        <img src={light} className='mx-auto' />
                        <div className='absolute inset-0 bottom-[-600px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #CCC0B211 0%, transparent 20%)' }}></div>
                    </div>

                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            قوانین و مقررات
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-3'>به نام خدا، ضمن تشکر بابت انتخاب وب سایت innoghte.com، قوانین و‌ مقررات استفاده از وب سایت “این نقطه” به شرح زیر می باشد:</div>

                    <div className="flex flex-col gap-10 text-justify md:mt-40 max-md:mt-28 max-sm:mt-16 sm:pb-24 max-sm:pb-16">
                        <p className='text-[19px]'>توﺟﻪ داشته ﺑﺎﺷﯿﺪ ﮐﻠﯿﻪ اصول و رویه های وب ﺳﺎﯾﺖ “این نقطه” ﻣﻨﻄﺒﻖ ﺑﺎ قوانین جمهوری اﺳﻼﻣﯽ ایران، قانون ﺗﺠﺎرت الکترونیک و ﻗﺎﻧﻮن ﺣﻤﺎﯾﺖ از ﺣﻘﻮق مصرف کننده است و ﻣﺘﻌﺎﻗﺒﺎ ﮐﺎرﺑﺮ ﻧﯿﺰ ﻣﻮﻇﻒ ﺑﻪ رﻋﺎﯾﺖ قوانین ﻣﺮﺑﻮﻃﻪ است.<br /> در ﺻﻮرﺗﯽ ﮐﻪ در قوانین مندرج، رویه ها و سرویس ها تغییری ایجاد ﺷﻮد، در ﻫﻤﯿﻦ ﺻﻔﺤﻪ ﻣﻨﺘﺸﺮ و ﺑﻪ روز رﺳﺎﻧﯽ ﻣﯽ ﺷﻮد و ﺷﻤﺎ ﺗﻮاﻓﻖ ﻣﯽﮐﻨﯿﺪ ﮐﻪ استفاده ﻣﺴﺘﻤﺮ ﺷﻤﺎ از ﺳﺎﯾﺖ ﺑﻪ ﻣﻌﻨﯽ ﭘﺬﯾﺮش ﻫﺮﮔﻮﻧﻪ ﺗﻐﯿﯿﺮ است. </p>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>تعاریف اولیه:</h3>
                            <p className='text-[19px]'>ﻣﺸﺘﺮی ﯾﺎ ﮐﺎﺭﺑﺮ ﺑﻪ ﺷﺨﺼﯽ ﮔﻔﺘﻪ ﻣﯽﺷﻮی ﮐﻪ ﺑﺎ اطلاعات کاربری خود ﮐﻪ در فرم ﺛﺒﺖ نام درج کرده است، ﺑﻪ ﺛﺒﺖ سفارش ﯾﺎ ﻫﺮﮔﻮﻧﻪ استفاده از ﺧﺪﻣﺎت وب ﺳﺎﯾﺖ “این نقطه” اﻗﺪام ﮐﻨﺪ. ﻫﻤﭽﻨﯿﻦ از آنجا ﮐﻪ وب ﺳﺎﯾﺖ “این نقطه” ﯾﮏ ﺳﺮوﯾﺲ دهنده آنلاین است، ﻃﺒﻖ قانون ﺗﺠﺎرت اﻟﮑﺘﺮوﻧﯿﮏ، ﻣﺸﺘﺮی ﯾﺎ ﻣﺼﺮف ﮐﻨﻨﺪه ﻓﺮدی اﺳﺖ ﮐﻪ ﺑﻪ ﻣﻨﻈﻮری ﺟﺰ ﺗﺠﺎرت ﯾﺎ ﺷﻐﻞ ﺣﺮﻓﻪای ﺑﻪ ﺧﺮﯾﺪ ﮐﺎﻻ ﯾﺎ خدمات اقدام ﻣﯽﮐﻨﺪ. </p>
                        </div>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>حریم خصوصی: </h3>
                            <p className='text-[19px]'>وب ﺳﺎﯾﺖ “این نقطه” ﻣﺘﻌﻬﺪ ﻣﯽﺷﻮد در ﺣﺪ توان از ﺣﺮﯾﻢ ﺷﺨﺼﯽ ﺷﻤﺎ و اﻃﻼﻋﺎت ﺧﺼﻮﺻﯽ اﺷﺨﺎﺻﻰ ﮐﻪ از ﺧﺪﻣﺎت ﺳﺎﯾﺖ استفاده ﻣﯽﮐﻨﻨﺪ، ﻣﺤﺎﻓﻈﺖ ﮐﻨﺪ. در واﻗﻊ ﺑﺎ اﺳﺘﻔﺎده از وب ﺳﺎﯾﺖ “این نقطه”، ﺷﻤﺎ رﺿﺎﯾﺖ ﺧﻮد را از اﯾﻦ ﺳﯿﺎﺳﺖ ﻧﺸﺎن ﻣﯽﺩﻫﯿﺪ. </p>
                        </div>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>حق نشر: </h3>
                            <p className='text-[19px]'>ﻫﻤﻪ ﻣﻄﺎﻟﺐ در دﺳﺘﺮس از ﻃﺮﯾﻖ ﻫﺮ ﯾﮏ وب ﺧﺪﻣﺎت وب ﺳﺎﯾﺖ “این نقطه” ، ﻣﺎﻧﻨﺪ ﻣﺘﻦ، ﮔﺮاﻓﯿﮏ، آرم، آﯾﮑﻮن دﮐﻤﻪ، ﺗﺼﺎوﯾﺮ، ﻓﺎﯾﻠﻬﺎی ﺗﺼﻮﯾﺮی و ﺻﻮﺗﯽ، ﻣﻮارد ﻗﺎﺑﻞ دانلود و ﮐﭙﯽ، داده ها و ﮐﻠﯿﻪ ﻣﺤﺘﻮای ﺗﻮﻟﯿﺪ ﺷﺪه ﺗﻮﺳﻂ وب ﺳﺎﯾﺖ “این نقطه” ﺟﺰﺋﯽ از دارایی های ﻣﻌﻨﻮی ﻣﺎ ﻣﺤﺴﻮب ﻣﯽﺷﻮد و ﺣﻖ اﺳﺘﻔﺎده و ﻧﺸﺮ ﺗﻤﺎﻣﯽ ﻣﻄﺎﻟﺐ ﻣﻮﺟﻮد و در دﺳﺘﺮس در اﻧﺤﺼﺎﺭ وب ﺳﺎﯾﺖ “این نقطه” اﺳﺖ و ﻫﺮﮔﻮﻧﻪ اﺳﺘﻔﺎده ﺑﺪون ﮐﺴﺐ ﻣﺠﻮز ﮐﺘﺒﯽ، ﺣﻖ ﭘﯿﮕﺮد ﻗﺎﻧﻮﻧﯽ را برای ﻣﺎ ﻣﺤﻔﻮظ می دارد. هر نوع کپی برداری و رکورد و یا ذخیره فایل های دوره های وب سایت “این نقطه” به نشانی innoghte.com تخلف قانونی و اخلاقی بوده و نحوه استفاده از دوره ها تنها از طریق وب سایت و به صورت آنلاین می باشد. </p>
                        </div>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>اکانت کاربری: </h3>
                            <p className='text-[19px]'>وب سایت “این نقطه” این حق را برای خود قائل است که از ارائه سرویس به هر کاربری که تشخیص می دهد، خودداری نماید. استفاده از اکانت کاربری تنها توسط یک کاربر مجاز می باشد و هر نوع استفاده مشترک (دونفره یا چند نفره)، تخلف محسوب شده و ممکن است باعث توقف ارائه خدمات شود. ﻻزم ﺑﻪ ذﮐﺮ اﺳﺖ اﻓﺰودن ﮐﺎﻻ (دوره) ﺑﻪ ﺳﺒﺪ ﺧﺮﯾﺪ ﺑﻪ ﻣﻌﻨﯽ رزرو ﮐﺎﻻ ﻧﯿﺴﺖ و ﻫﯿﭻ ﮔﻮﻧﻪ ﺣﻘﯽ را ﺑﺮای ﮐﺎﺭﺑﺮ ایجاد ﻧﻤﯽﮐﻨﺪ. ﺷﺎﯾﺎن ذﮐﺮ اﺳﺖ ﺳﻔﺎﺭش تنها زمانی نهایی می‌شود ﮐﻪ ﮐﺎﺭﺑﺮان وب ﺳﺎﯾﺖ “این نقطه” اﯾﻤﯿﻞ ﺳﻔﺎﺭش ﺧﻮد را درﯾﺎﻓﺖ ﮐﻨﻨﺪ و ﺑﺪﯾﻬﯽ اﺳﺖ ﮐﻪ وب ﺳﺎﯾﺖ “این نقطه” ﻫﯿﭻ ﮔﻮﻧﻪ ﻣﺴئوﻟﯿﺘﯽ ﻧﺴﺒﺖ ﺑﻪ ﮐﺎﻻﻫﺎﯾﯽ ﮐﻪ در ﺳﺒﺪ ﺧﺮﯾﺪ رﻫﺎ ﺷﺪه اﺳﺖ، ﻧﺪارد. </p>
                        </div>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>نحوه استفاده از دوره‌ها: </h3>
                            <p className='text-[19px]'>وب سایت innoghte.com برای سرویس دهی به سرتاسر دنیا طراحی شده است. جهت استفاده از محتوای دوره های خریداری شده، کافیست به بخش “دوره های من” در اکانت کاربری خود مراجعه کنید. با خرید از وب سایت این نقطه، شما در حقیقت اشتراک دائم و آنلاین یکی از دوره های آموزشی را خریداری می کنید. به دلیل حفظ حق نشر کاربران حق دانلود و یا ذخیره کردن محتوای دوره ها را ندارند و هر نوع کپی برداری از محتوای ارائه شده، تخلف قانونی و اخلاقی می باشد و ﺣﻖ ﭘﯿﮕﺮﺩ ﻗﺎﻧﻮﻧﯽ برای مجموعه “این نقطه” محفوظ است. دارنده اکانت وب سایت innoghte.ir فقط مجاز به استفاده در داخل ایران است و استفاده در خارج از ایران تخلف محسوب می شود. </p>
                        </div>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>قوانین ورکشاپ‌ها و ایونت‌ها: </h3>
                            <p className='text-[19px]'>جلسات راس ساعت اعلام شده شروع می شوند و به پایان می رسند. شرکت در ورکشاپ های حضوری، بدون ارائه بلیط خریداری شده از وب سایت مقدور نخواهد بود. بعد از انجام رزرو، چه برای ورکشاپ های آنلاین و چه برای ورکشاپ های حضوری، اطلاعات تکمیلی برای شما ایمیل می شود.(در وارد کردن آدرس ایمیل خود دقت کنید) بعد از انجام خرید، امکان عودت وجه، تغییر ساعت، تغییر کلاس وجود نخواهد داشت. عدم حضور شما در کلاس مسئولیتی برای مجموعه “این نقطه” ایجاد نمی کند. چنانچه قبل یا بعد از برگزاری نیاز به پشتیبانی داشتید، می توانید از طریق ارسال ایمیل به آدرس info@innoghte.com اقدام نمایید.</p>
                        </div>

                        <div className="flex flex-col">
                            <h3 className='text-[24px]'>مالی و اداری و مجوزها:</h3>
                            <p className='text-[19px]'>مجموعه “این نقطه” در داخل و خارج از ایران به صورت قانونی به عنوان ناشر آنلاین محتوای آموزشی، ثبت شده است و از تمامی حقوق قانونی فعالیت در فضای مجازی برخوردار می باشد. در صورت نیاز به عودت وجه، وجه پرداختی، تنها به حساب مبدا عودت داده خواهد شد. مراحل عودت وجه ممکن است به دلیل اختلالات بانکی زمان بر باشد. مسئولیت اختلالات بانکی در زمان خرید، با بانک و سیستم بانکی است و چنانچه وجه از حساب کاربر کسر گردد و به حساب مجموعه “این نقطه” واریز نشود، سیستم بانکی موظف است تا 72 ساعت مبلغ را به حساب بانکی مبدا عودت دهد. ﺳﺮوﻳﺳﻬﺎی ﺳﺎﯾﺖ آﻧﮕﻮﻧﻪ ﮐﻪ ﻫﺴﺖ اراﺋﻪ ﻣﯽﺷﻮد و وب ﺳﺎﯾﺖ “این نقطه” ﺗﺤﺖ ﻫﯿﭻ ﺷﺮاﯾﻄﯽ ﻣﺴئوﻟﯿﺖ ﺗﺎﺧﯿﺮ ﯾﺎ ﻋﺪم کارکرد ﺳﺎﯾﺖ را ﮐﻪ ﻣﯽﺗﻮﺍﻧﺪ ﻧﺎﺷﻰ از ﻋﻮاﻣﻞ ﻃﺒﯿﻌﻰ، نیروی اﻧﺴﺎﻧﯽ، ﻣﺸﮑﻼت اﯾﻨﺘﺮﻧﺘﻰ، خرابی ﺗﺠﻬﯿﺰات ﮐﺎﻣﭙﯿﻮﺗﺮی، ﻣﺨﺎﺑﺮاﺗﻰ و ﻏﯿﺮه ﺑﺎﺷﺪ ﺑﺮ ﻋﻬﺪه ندارد. </p>
                        </div>

                        <p className='text-[19px]'>ﺗﻤﺎﻣﯽ ﺷﺮاﯾﻂ و قوانین ﻣﻨﺪرج، در ﺷﺮاﯾﻂ ﻋﺎدی ﻗﺎﺑﻞ اجرا است و در ﺻﻮﺭت ﺑﺮوز ﻫﺮﮔﻮﻧﻪ از ﻣﻮارد ﻗﻮه ﻗﻬﺮﯾﻪ، وب ﺳﺎﯾﺖ “این نقطه” ﻫﯿﭻ ﮔﻮﻧﻪ ﻣﺴﺌﻮﻟﯿﺘﯽ ندارد.<br /> موفق و پیروز باشید.</p>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Terms;