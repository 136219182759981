import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import CommentsSection from '@/components/CommentsSection';
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen, ErrorScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger'
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr'
import Cookies from 'js-cookie'

import lightImg from '@/assets/image/light.png'

import diamondIcon from '@/assets/svg/diamond.svg'
import starIcon from '@/assets/svg/Star 3.svg'
import chatIcon from '@/assets/svg/Chat 2.svg'
import videoIcon from '@/assets/svg/Video.svg'
import docIcon from '@/assets/svg/doc.svg'
import priceIcon from '@/assets/svg/price.svg'
import arrowIcon from '@/assets/svg/Arrow - Left.svg'
import basketIcon from '@/assets/svg/Buy-white.svg'
import lineDecorationGoldIcon from "@/assets/svg/line-decoration1-gold.svg"
import circlesIcon from "@/assets/svg/circles.svg"
import excludeIcon from "@/assets/svg/Exclude.svg"
import musicIcon from "@/assets/svg/Music.svg"
import heartIcon from "@/assets/svg/Heart2.svg"
import timeCircle2Icon from "@/assets/svg/Time Circle2.svg"

declare let tippy: any;

const decodeHtmlEntities = (str) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
}

const splitText = (text: string) => {
    if (!text) return ['', ''];
    const decodedText = decodeHtmlEntities(text);
    return decodedText.split(/<v>/);
};

const AlbumView = () => {
    const { id } = useParams();
    const { cart, addToCart } = useContext(CartContext);

    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [error, setError] = useState(false);
    const [noComment, setNoComment] = useState(false);
    const [albumData, setAlbumData] = useState(null);
    const [purchasedProducts, setPurchasedProducts] = useState(null);
    const [desFromTop, setDesFromTop] = useState(0);

    const tooltipRef = useRef(null);
    const tooltipRef2 = useRef(null);
    const des1 = useRef(null);
    const des2 = useRef(null);
    const des3 = useRef(null);
    const theEl = useRef(null);

    const isInCart = cart.some(cartItem => cartItem.id === +id);
    const pageType = albumData?.package || 0;
    const bgColor = albumData?.color === 'purple' ? '#111123' : albumData?.color === 'green1' ? '#11231F' : '#001605';
    const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) {
            setNav_DropDown(false);
        }
    }

    const fetchData = async () => {
        try {
            const [albumResponse, purchasedResponse] = await Promise.all([
                axiosInstance.get('/website/album_show/' + id),
                isLogin ? axiosInstance.get('/order/purchased', {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                    },
                }) : Promise.resolve({ data: { status: 0 } })
            ]);

            const album = albumResponse.data.items[0];

            if (purchasedResponse.data.status === 1) {
                const { purchased_orders, purchased_packages } = purchasedResponse.data;
                const isPurchasedInIr = purchased_orders.ir.includes(album.id) || purchased_packages.ir.includes(album.id);
                const isPurchasedInCom = purchased_orders.com.includes(album.id) || purchased_packages.com.includes(album.id);

                setAlbumData({
                    ...album,
                    isPurchasedInIr,
                    isPurchasedInCom,
                });

                if (isDotIr) {
                    setPurchasedProducts([...purchased_orders.ir, ...purchased_packages.ir, ...purchased_orders.com, ...purchased_packages.com]);
                } else {
                    setPurchasedProducts(purchased_orders.com);
                }
            } else {
                setAlbumData(album);
            }
        } catch (error) {
            console.error('Error fetching album data:', error);
            setError(true);
        }
    };
    const checkIfPurchased = product => {
        if (!product) return;
        return purchasedProducts?.find(i => i === product.id);
    }
    const scrollHandler = (destination) => {
        if (destination.current) {
            const { top } = destination.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + top - 80,
                behavior: 'smooth'
            });
        }
    }

    const handleDistanceFromTop = () => {
        if (theEl.current) {
            const rect = theEl.current.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setDesFromTop(rect.top + scrollTop);
        }
    }

    useEffect(() => {
        if (tooltipRef.current && tooltipRef2.current) {
            const tooltipContent = "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش آلبوم VPN خود را خاموش و صفحه را رفرش کنید.";
            tippy(tooltipRef.current, { content: tooltipContent });
            tippy(tooltipRef2.current, { content: tooltipContent });
        }
    }, [albumData]);

    useEffect(() => {
        fetchData();
        window.addEventListener('resize', handleDistanceFromTop);
        return () => window.removeEventListener('resize', handleDistanceFromTop);
    }, [id]);

    useEffect(() => {
        convertNumsToFa();
        handleDistanceFromTop();
    }, [albumData]);

    if (error) return <ErrorScreen />;


    return (
        <>
            <LoadingScreen loading={!albumData} />

            <div className='bg-white' onClick={close_Popup}>
                <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

                <section className="relative pt-40 max-lg:pt-32 overflow-hidden">
                    <div
                        style={{
                            transform: 'skew(0,4deg)',
                            backgroundColor: bgColor,
                            '--des-from-top': `${desFromTop}px`
                        } as React.CSSProperties}
                        className="bg-box-color absolute inset-0 lg:mt-[310px] mb-[100px] max-lg:mb-[130px] rounded-[5vw] z-0"
                    ></div>
                    <div className='max-lg:hidden absolute left-[-800px] opacity-30 top-0 z-[0] w-[1500px] aspect-square' style={{ background: 'radial-gradient(circle, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 50%)' }}></div>
                    <div className='max-lg:hidden absolute right-[-600px] opacity-30 -bottom-40 z-[0] w-[1500px] aspect-square' style={{ background: 'radial-gradient(circle, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 50%)' }}></div>

                    <div className='my-container'>
                        <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                            <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-4'>
                                <img src={musicIcon} width={30} className='max-lg:hidden' />
                                {albumData?.title_fa}
                            </h3>
                        </div>
                        <div
                            className='text-[#212121] text-[21px] font-semibold  max-lg:text-[20px] max-lg:text-center mb-5'
                            dangerouslySetInnerHTML={{ __html: splitText(albumData?.short_info)[0] }}
                        />
                        <div className='relative z-[2] flex lg:gap-16 max-lg:flex-col max-lg:mt-5 max-w-full items-end'>
                            <div ref={theEl} className='mt-auto mx-auto max-w-full'>
                                <div className='rounded-xl p-1 bg-white w-[365px] max-w-full relative' style={{ border: '1px solid #E0E0E0' }}>
                                    <img src={albumData?.image_media[0].src} className='rounded w-full object-cover h-[440px]' />
                                </div>
                            </div>
                            <div className='grow lg:mt-10 max-lg:text-center mx-auto'>
                                <div className='flex items-start gap-2 font-semibold lg:mt-7 max-lg:flex-col max-lg:items-center'>
                                    <img src={diamondIcon} className='w-[20px] max-lg:w-[32px] max-lg:mt-6 lg:mt-2' />
                                    <p
                                        className='text-justify max-lg:text-[22px] max-lg:text-center'
                                        dangerouslySetInnerHTML={{ __html: splitText(albumData?.short_info)[1] }}
                                    />
                                </div>
                                <div className='mt-6 flex flex-wrap gap-y-1 gap-x-10 justify-between max-lg:flex-col max-lg:items-center'>
                                    {pageType === 0 && <>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-bold my-justify'>تعداد آهنگ:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.chapters_count}
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.access_type}
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>قابلیت دانلود:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.downloadable === 0 ? 'ندارد' : 'دارد'}
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>مدت زمان کل آلبوم:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.duration} ساعت (حدودی)
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>سبک موسیقی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.tags}
                                            </span></p>
                                        </div>
                                    </>}
                                    {pageType === 1 && <>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-bold my-justify'>تعداد آلبوم:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.included_courses_count}
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.access_type}
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>قابلیت دانلود:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.downloadable === 0 ? 'ندارد' : 'دارد'}
                                            </span></p>
                                        </div>
                                        <div className='flex gap-2 items-start'>
                                            <img src={starIcon} width={20} className='mt-1' />
                                            <p className='font-semibold my-justify'>مدت زمان کل آلبوم:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                                {albumData?.duration} ساعت (حدودی)
                                            </span></p>
                                        </div>
                                    </>}
                                </div>
                                {pageType === 0 ?
                                    <div className='flex gap-2 items-center rounded bg-[#00a86a1e] p-[.6rem] px-4 my-5 max-lg:items-start max-lg:mx-auto'>
                                        <img src={excludeIcon} className='max-lg:mt-1' />
                                        <p className='text-[18px] font-medium text-[#12D18E] text-right'>شما می‌توانید این آلبوم و دیگر آلبوم‌های منتشرشده را در قالب <a href='/album/12' className='text-[#F1C31B] text-[18px]'>پکیج طلایی</a> و با تخفیف خریداری کنید.</p>
                                    </div>
                                    :
                                    <div className='flex gap-2 items-center rounded bg-[#00a86a1e] p-[.6rem] px-4 my-5 max-lg:items-start max-lg:mx-auto'>
                                        <img src={excludeIcon} className='max-lg:mt-1' />
                                        <p className='text-[18px] font-medium text-[#12D18E] text-right'>شما می توانید محتوای بسته طلایی را در قالب آلبوم های جداگانه نیز خریداری نمایید.</p>
                                    </div>
                                }
                                <div className='flex justify-between max-lg:justify-center flex-wrap items-center gap-3 max-lg:mt-8'>
                                    <button onClick={() => scrollHandler(des1)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={docIcon} className='max-lg:hidden' width={20} />
                                        <p className='font-semibold max-lg:font-medium text-[19px] max-lg:text-[18px]'>اطلاعات تکمیلی</p>
                                    </button>
                                    <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                    <button onClick={() => scrollHandler(des2)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={videoIcon} className='max-lg:hidden' width={21} />
                                        <p className='font-semibold max-lg:font-medium text-[19px] max-lg:text-[18px]'>دموی آلبوم</p>
                                    </button>
                                    {/* {!noComment && <> */}
                                    <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                    <button onClick={() => scrollHandler(des3)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={chatIcon} className='max-lg:hidden' width={21} />
                                        <p className='font-semibold max-lg:font-medium text-[19px] max-lg:text-[18px]'>دیدگاه کاربران</p>
                                    </button>
                                    {/* </>} */}
                                </div>
                                <div className="flex justify-between items-center mt-8 max-lg:hidden">
                                    <div className='flex gap-2 items-center'>
                                        <img src={priceIcon} width={20} />
                                        <p>قیمت دوره: </p>
                                        {albumData?.discount_price && albumData?.discount_price !== albumData?.price ?
                                            <>
                                                <div className={`rounded px-2 text-[23px] font-bold bg-[#F1C31B] text-black ${!isDotIr && 'keep-english-numbers'}`}>
                                                    {isDotIr ? (albumData?.discount_price / 10).toLocaleString() : '$' + albumData?.discount_price.toLocaleString()} {' '}
                                                    <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                                </div>
                                                <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                    {isDotIr ? (albumData?.price / 10).toLocaleString() : albumData?.price.toLocaleString()}
                                                </p>
                                            </>
                                            :
                                            <p className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                {isDotIr ? (albumData?.price / 10).toLocaleString() : '$' + albumData?.price.toLocaleString()} {' '}
                                                <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                            </p>
                                        }
                                    </div>
                                    {albumData?.remain_capacity === 0 ?
                                        <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-2'>
                                            <p className='font-semibold text-[19px]'>ظرفیت تکمیل شده است.</p>
                                        </button>
                                        : checkIfPurchased(albumData) ?
                                            <Link to={`/dashboard/products/albums/album/${albumData.id}`} className='bg-[--orange-primary] text-white hover:bg-[--orange-primary-hover] transition rounded px-4 p-2'>بخش نمایش آلبوم</Link>
                                            :
                                            <button onClick={() => addToCart(albumData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-2 relative'>
                                                <img src={basketIcon} width={20} />
                                                <p className='font-semibold text-[19px]'>
                                                    {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                                </p>
                                                <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                                {!isDotIr && albumData?.isPurchasedInIr && !albumData?.isPurchasedInCom &&
                                                    <div ref={tooltipRef} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                        </svg>
                                                    </div>
                                                }
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>

                        <div ref={des1} className='w-full relative flex flex-col gap-5 items-center lg:pt-24 max-lg:pt-16'>
                            {pageType === 0 ?
                                <img src={lineDecorationGoldIcon} className='absolute left-[-134px] top-[-200px] max-lg:hidden' />
                                :
                                <img src={lightImg} className='absolute left-[-134px] top-[-100px] max-lg:hidden' />
                            }
                            <h3 className='text-center max-lg:px-[10vw]'>اطلاعات تکمیلی</h3>
                            <div className='lg:px-32 w-full flex items-center max-lg:-mt-3'>
                                <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                <img src={circlesIcon} className='mt-[1px]' />
                                <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                            </div>
                            <div
                                className='lg:px-32 text-justify font-medium text-[20px]'
                                dangerouslySetInnerHTML={{ __html: albumData?.full_info }}
                            />
                        </div>

                        <div ref={des2} className='relative z-[2] lg:pt-16 max-lg:pt-8 flex flex-col items-center gap-5 max-w-full mx-auto'>
                            {pageType === 0 ?
                                <img src={lineDecorationGoldIcon} className='absolute right-[-134px] bottom-[250px] max-lg:hidden' />
                                :
                                <img src={lightImg} className='absolute right-[-134px] bottom-[150px] max-lg:hidden' />
                            }
                            <h3 className='max-lg:hidden'>دموی {albumData?.title_fa}</h3>
                            <div className='max-lg:hidden lg:px-32 w-full flex items-center max-lg:-mt-3'>
                                <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                <img src={circlesIcon} className='mt-[1px]' />
                                <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                            </div>
                            <div className="flex gap-x-5 gap-y-10 justify-center flex-wrap mt-6 max-w-full">
                                {albumData?.album_demos?.map((item, index) => (
                                    <div key={index} className={`flex flex-col justify-between items-center gap-5 w-[352px] max-w-full rounded-3xl p-7 py-10 ${albumData?.color === 'purple' && 'max-lg:border border-[#19192e]'}`} style={{ background: albumData?.color !== 'purple' ? 'linear-gradient(140deg, #1F3833, #162C27)' : 'linear-gradient(140deg, #23233D, #0D0D1B)', aspectRatio: '2/3' }}>
                                        <div className="flex justify-between w-full items-center gap-4">
                                            <p>نام آهنگ: <b className='text-[24px]'>{item.title}</b></p>
                                            <p className='text-[18px]'>#{item.tags}</p>
                                        </div>
                                        <div className='w-[270px] h-[270px] rounded-full relative' style={{ background: 'linear-gradient(130deg, #41464B, #0e0f11)', boxShadow: '12px 14px 25px -6px #1A1B1F' }}>
                                            <div className='absolute inset-1 rounded-full' style={{ background: 'linear-gradient(130deg, #0F1314, #121617)', boxShadow: '-12px -10px 25px -8px #41464Bcc' }}>
                                                <img src={item.image} className='absolute inset-2 w-[246px] h-[246px] object-cover rounded-full' />
                                            </div>
                                        </div>
                                        <div className="flex justify-between w-full items-center gap-4">
                                            <div className="flex gap-1 items-center">
                                                <img src={heartIcon} />
                                                <div>
                                                    <span className='font-medium'>آلبوم:</span>{' '}
                                                    <span className='font-light'>{albumData?.title_fa?.replace('آلبوم', '').trim()}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <img src={timeCircle2Icon} />
                                                <div>
                                                    <span className='font-medium'>مدت زمان:</span>{' '}
                                                    <span className='font-light'>{item.duration}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <audio controls className={`h-[40px] w-full rounded-full ${albumData?.color === 'purple' ? 'bg-[#00f]' : albumData?.color === 'green1' ? 'bg-[#00a37f]' : 'bg-[#00ffc8]'}`} style={{ border: albumData?.color === 'purple' ? '1px solid #006e56' : '1px solid #6e6e6e' }}>
                                            <source src={item.url} type="audio/mpeg" />
                                        </audio>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <div ref={des3} />
                <CommentsSection onNoComments={() => setNoComment(true)} id={+id} title={`دیدگاه مخاطبین ${albumData?.title_fa}`} />

                <div className='my-container lg:hidden fixed bottom-0 w-full shadow border-t z-10 h-[75px] bg-white flex gap-5 justify-between items-center'>
                    {albumData?.remain_capacity === 0 ?
                        <button className='bg-[#888] pointer-events-none font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded'>ظرفیت تکمیل شده است.</button>
                        : checkIfPurchased(albumData) ?
                            <Link to={`/dashboard/products/albums/album/${albumData.id}`} className='bg-[--orange-primary] hover:bg-[--orange-primary-hover] text-white text-center font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded'>بخش نمایش آلبوم</Link>
                            :
                            <button onClick={() => addToCart(albumData)} disabled={isInCart} style={{ background: isInCart && 'var(--orange-primary-active)' }} className='bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded relative'>
                                <>
                                    {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                    {!isDotIr && albumData?.isPurchasedInIr && !albumData?.isPurchasedInCom &&
                                        <div ref={tooltipRef2} className='absolute -top-2 right-0 bg-white rounded-full'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                            </svg>
                                        </div>
                                    }
                                </>
                            </button>
                    }
                    <div className="flex flex-col">
                        {albumData?.discount_price && albumData?.discount_price !== albumData?.price ?
                            <>
                                <span className='font-semibold text-[23px] text-[#424242]'>
                                    <span className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                        {isDotIr ? (albumData?.discount_price / 10).toLocaleString() : '$' + albumData?.discount_price.toLocaleString()}
                                    </span>{' '}
                                    <span className='font-medium text-[23px]'>
                                        {isDotIr && 'تومان'}
                                    </span>
                                </span>
                                <span className='font-semibold text-[#424242] mt-[-5px] line-through text-left'>
                                    <span className={`text-[18.5px] ${!isDotIr && 'keep-english-numbers'}`}>
                                        {isDotIr ? (albumData?.price / 10).toLocaleString() : '$' + albumData?.price.toLocaleString()}
                                    </span>{' '}
                                    <span className='font-medium text-[18.5px]'>
                                        {isDotIr && 'تومان'}
                                    </span>
                                </span>
                            </>
                            :
                            <span className='font-semibold text-[23px] text-[#424242]'>
                                <span className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                    {isDotIr ? (albumData?.price / 10).toLocaleString() : '$' + albumData?.price.toLocaleString()}
                                </span>{' '}
                                <span className='font-medium text-[23px]'>
                                    {isDotIr && 'تومان'}
                                </span>
                            </span>
                        }
                    </div>
                </div>

                <Footer />

                <div className='lg:hidden h-[75px]'></div>
            </div>
        </>
    )
}

export default AlbumView;