import { FC, useEffect, useState } from 'react';
import { useLayout } from '@/metronic-dashboard/helpers/layout/core/LayoutProvider';
import { AsideMenu } from './AsideMenu';
import { AsideToolbar } from './AsideToolbar';

interface AsideDefaultProps {
  isDrawerOpen: boolean;
}

const AsideDefault: FC<AsideDefaultProps> = ({ isDrawerOpen }) => {
  const { classes } = useLayout();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  return (
    <div
    style={{ left: 'unset', right: '0', ...(isMobile && { width: '250px' }) }}
      id="kt_aside"
      className={`aside ${isMobile ? 'drawer drawer-start' : ''} ${isMobile && isDrawerOpen ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Aside Toolbar */}
      <div className="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
        <AsideToolbar />
      </div>
      {/* end::Aside Toolbar */}
      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  );
};

export { AsideDefault };