import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axiosInstance from '@/utils/axiosInstance';
import { isDotIr } from '@/utils/isDotIr'

import img1 from '@/metronic-dashboard/assets/verified.png'
import img2 from '@/metronic-dashboard/assets/failed.png'

const VerifyEmail = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [successVerify, setSuccessVerify] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const id = query.get('id');
        const hash = query.get('hash');

        const verifyEmail = async () => {
            try {
                const response = await axiosInstance.get(`/verify-email/${id}/${hash}`);
                if (response.data.status === 1) {
                    setSuccessVerify(true)
                    setMessage('ایمیل شما با موفقیت تایید شد.');
                    const accessToken = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');
                    setTimeout(() => {
                        if (accessToken) {
                            navigate('/dashboard');
                        } else {
                            navigate('../login');
                        }
                    }, 3000);
                } else {
                    setMessage('تایید ایمیل ناموفق بود.');
                    setTimeout(() => {
                        navigate('../registration');
                    }, 3000);
                }
            } catch (error) {
                setMessage('خطایی رخ داده است.');
                setTimeout(() => {
                    navigate('../registration');
                }, 3000);
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [location, navigate]);

    useEffect(() => {
        if (!loading && countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [loading, countdown]);

    return (
        <div className="verify-email-container h-[370px] flex flex-col items-center justify-center">
            {loading ? (
                <div className='text-center'>
                    <h3>
                        لطفا کمی صبر کنید... 
                        <span className="spinner-border align-middle ms-2"></span>
                    </h3>
                </div>
            ) : (
                <div className='text-center my-20'>
                    {successVerify === true ?
                        <img src={img1} alt="Verified" width={80} className='mx-auto mb-8' />
                        :
                        <img src={img2} alt="Failed" width={80} className='mx-auto mb-8' />
                    }
                    <h3 className='text-[30px]'>{message}</h3>
                    <p className='text-gray-800 mt-2'>{`${countdown} ثانیه تا انتقال به صفحه بعد...`}</p>
                </div>
            )}
        </div>
    );
};

export default VerifyEmail;