import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import StarIcon from "../../assets/svg/Star.svg";

const CommentsSlider = ({ commentsData }) => {
    const extractComments = (data) => {
        const allComments = [];
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                allComments.push(...data[key]);
            }
        });
        return allComments;
    };

    const comments = commentsData && extractComments(commentsData).map((review) => (
        <SwiperSlide key={review.id}>
            <div className="comment-box px-9 py-5 bg-[#ECECEC] text-[#616161] mx-4 rounded-[2.7rem] min-h-[167px] sm:min-h-[143px]">
                <div className="flex justify-between mb-2">
                    <b className="text-[#454545] text-[21px]">{review.user.full_name}</b>
                    <div className="flex gap-[4px]">
                        {[...Array(5)].map((_, i) => (
                            <img
                                key={i}
                                src={StarIcon}
                                width={14}
                                className={i < review.points ? "block" : "hidden"}
                                alt="star"
                            />
                        ))}
                    </div>
                </div>
                <p className="m-0 leading-6 font-semibold text-[18px] text-justify">
                    {review.comment.length > 300

                        ? review.comment.slice(0, 300).concat('...')
                        : review.comment}
                </p>
            </div>
        </SwiperSlide>
    )) || [];

    return (
        <Swiper
            slidesPerView="auto"
            spaceBetween={0}
            centeredSlides={false}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, Pagination]}
            breakpoints={{
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 10,
                },
                700: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1300: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1800: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
            }}
            className="max-w-[1500px]"
        >
            {comments}
        </Swiper>
    );
};

export default CommentsSlider;