import { useEffect, useState, useRef } from 'react';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen, ErrorScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger';
import Alert from '@/components/Alert';

import light from '@/assets/image/light5.png'
import light2 from '@/assets/image/light5.2.png'

import circlesIcon from "@/assets/svg/circles3.svg"
import bookIcon from '@/assets/svg/book.svg'
import arrowIcon from '@/assets/svg/Arrow - Left.svg'
import arrow2Icon from '@/assets/svg/Arrow - Right.svg'

const validationSchema = Yup.object({
    title: Yup.string().required('این فیلد الزامی است.'),
    info: Yup.string().max(200, 'حداکثر 200 کاراکتر').required('این فیلد الزامی است.'),
})


const Writing = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }
    
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showDangerModal, setShowDangerModal] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/website/write_list', {
                    params: { page: currentPage }
                });
                setData(response.data);
                setCurrentPage(response.data.items.current_page);
                setTotalPages(response.data.items.last_page);

                setTimeout(() => {
                    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
                }, 1)
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage]);


    const [remainingChars, setRemainingChars] = useState(400)
    const btn = useRef(null)

    const formik = useFormik({
        initialValues: {
            title: '',
            info: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            btn.current.disabled = true
            btn.current.innerText = 'لطفا کمی صبر کنید...'
            setShowSuccessModal(false)
            setShowDangerModal(false)

            try {
                const response = await axiosInstance.post('/website/blog/create', values);
                setShowSuccessModal(true)
                formik.resetForm();
            } catch (error) {
                setShowDangerModal(true)
            } finally {
                btn.current.disabled = false
                btn.current.innerText = 'ثبت نظر'
            }
        },
    })

    const handleTextareaChange = (event) => {
        const charCount = event.target.value.length
        setRemainingChars(200 - charCount)
        formik.handleChange(event)
    }

    useEffect(() => {
        convertNumsToFa();
    }, [data, currentPage]);


    const theEl = useRef(null);
    const [desFromTop, setDesFromTop] = useState()

    const handleDistanceFromTop = () => {
        const element = theEl.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollTop;
            setDesFromTop(offsetTop)
        }
    }

    useEffect(() => {
        handleDistanceFromTop()
    }, [data])

    window.onresize = () => handleDistanceFromTop()

    if (error) return <ErrorScreen />;


    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            <LoadingScreen loading={!data} />

            {showSuccessModal && <Alert title='یادداشت روزنگار شما با موفقیت ارسال شد.' text='بعد از بررسی، حداکثر تا ۴۸ ساعت نتیجه برای شما ایمیل خواهد شد.' />}
            {showDangerModal && <Alert danger title='متاسفانه مشکلی پیش آمد.' text='لطفا از اتصال دستگاه خود به اینترنت اطمینان حاصل نمایید و دوباره تلاش کنید.' />}

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden lg:mb-8 max-lg:mb-20">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        background: 'linear-gradient(180deg, #36213D, #1F1123)',
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[430px] mb-[170px] max-lg:mb-[200px] rounded-[5vw] z-0'
                ></div>

                <div className='my-container relative mb-8'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            {data?.category.title}
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-5 text-justify'>
                        {data?.category.list_title}
                    </div>
                </div>

                <div ref={theEl} className="my-container relative">
                    {data?.items.data.length > 3 && <>
                        <div className='max-lg:hidden absolute z-0 left-[-240px] top-32 w-[400px] h-[1000px]'>
                            <img src={light} className='mx-auto' />
                            <div className='absolute inset-0 top-[300px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #FFF20009 0%, transparent 40%)' }}></div>
                        </div>
                        <div className='max-lg:hidden absolute z-0 right-[-240px] bottom-[600px] w-[400px] h-[1000px]'>
                            <img src={light2} className='mx-auto' />
                            <div className='absolute inset-0 bottom-[-600px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #FFF20009 0%, transparent 20%)' }}></div>
                        </div>
                    </>}

                    <div className='relative flex flex-col gap-9'>
                        {data?.items.data.map((item, index) => {
                            // تابع برای بررسی وجود کلمات انگلیسی در متن
                            const isEnglishText = (text) => /[a-zA-Z]/.test(text);

                            return (
                                <div
                                    key={index}
                                    className={`flex max-lg:flex-col lg:gap-5 lg:p-5 max-lg:pt-5 rounded-2xl bg-[#3E2C44] ${index % 2 === 1 ? 'lg:flex-row-reverse' : ''}`}
                                    style={{ border: '1px solid #536A86' }}
                                >
                                    <div className='max-w-[calc(100%-40px)] mx-auto bg-white aspect-[4/5] p-1 flex justify-center rounded'>
                                        <img src={item.image_media[0].src} className='rounded object-cover w-[400px]' />
                                    </div>
                                    <div className='lg:bg-[#321E38] w-full flex flex-col gap-5 rounded p-5 text-white'>
                                        <div className="flex justify-center max-sm:flex-col max-sm:items-center">
                                            <h3 className='text-[27px]'>{item.title_fa}</h3>
                                        </div>

                                        <div className='w-full flex items-center -mt-2'>
                                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                            <img src={circlesIcon} />
                                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                        </div>

                                        <p className={`font-medium text-[20px] tracking-wide text-justify ${isEnglishText(item.full_info) ? 'ltr' : ''}`}>
                                            {item.full_info}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}

                        {data?.items.last_page > 1 &&
                            <div className='flex justify-between items-center'>
                                <div className='relative'>
                                    {loading &&
                                        <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                        </div>
                                    }
                                    <div className={`flex gap-3 items-center ${loading && 'opacity-40 pointer-events-none'}`}>
                                        <button
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className='h-10 w-10 rounded flex items-center justify-center hover:bg-[#f8f8f8] transition'
                                            style={{ border: '1px solid var(--orange-primary)' }}
                                        >
                                            <img src={arrow2Icon} width={23} />
                                        </button>
                                        <button
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                            className='font-semibold text-[24px] max-lg:text-[20px] h-10 px-3 text-white rounded flex items-center justify-center gap-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'
                                        >
                                            صفحه بعدی
                                            <img src={arrowIcon} />
                                        </button>
                                    </div>
                                </div>
                                <div className='flex gap-[.4rem]'>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>صفحه</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>{currentPage}</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>از</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>{totalPages}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>

            {/* <section className='my-container'>
                <div className="flex flex-col gap-5 mt-20 md:mb-14 max-md:mb-20 items-center">
                    <h3 className='text-[#212121]'>فرم ارسال روزنگار</h3>
                    <p className='text-[22px] font-semibold text-center'>“دوست و همراه عزیز، اگر تجربه ای در زمینه ژورنال نویسی داری، می‌توانی از طریق فرم زیر برای ما بفرستی و در این صفحه با دیگران به اشتراک بگذاری.”</p>

                    <form onSubmit={formik.handleSubmit} className='w-full lg:mt-5 max-lg:mt-2'>
                        <div className="grid lg:grid-cols-2 gap-x-12 gap-y-8">
                            <div className='lg:col-span-2 flex flex-col gap-3'>
                                <label htmlFor="filed3" className='!text-[21px] font-semibold required'>عنوان</label>
                                <input
                                    id='filed3'
                                    name='title'
                                    type="text"
                                    placeholder='مثلا بهبودی'
                                    className='bg-[#eee] rounded-xl p-4 px-5 !text-[20px]'
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                                {formik.errors.title && formik.touched.title &&
                                    <div className="flex gap-1 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                        <div className="text-red-500 text-sm">{formik.errors.title}</div>
                                    </div>
                                }
                            </div>

                            <div className='lg:col-span-2 flex flex-col gap-3'>
                                <label htmlFor="filed4" className='!text-[21px] font-semibold required'>شرح مطلب</label>
                                <textarea
                                    id='filed4'
                                    name='info'
                                    rows={3}
                                    maxLength={200}
                                    style={{ resize: 'none' }}
                                    placeholder='اینجا بنویسید...'
                                    className='outline-none bg-[#eee] rounded-xl p-5 px-5 !text-[20px] leading-6'
                                    onChange={handleTextareaChange}
                                    value={formik.values.info}
                                />
                                {formik.errors.info && formik.touched.info &&
                                    <div className="flex gap-1 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" className='fill-red-500'><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                        <div className="text-red-500 text-sm">{formik.errors.info}</div>
                                    </div>
                                }
                                <span className='text-[#9E9E9E] text-[17px] -mt-1'>
                                    {remainingChars >= 50 ? 'حداکثر 200 کاراکتر' : `کاراکتر باقیمانده: ${remainingChars}`}
                                </span>
                            </div>
                        </div>

                        <button ref={btn} type='submit' className='float-start md:mt-2 max-md:w-full max-md:mt-5 font-semibold text-[20px] max-lg:text-[19px] h-12 px-12 text-white rounded bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'>ثبت نظر</button>
                    </form>
                </div>
            </section> */}

            <Footer />
        </div>
    )
}

export default Writing;