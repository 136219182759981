// main.tsx
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App.tsx'
import './index.css'
import './bootstrap.css'
import './App.css'
import '@/metronic-dashboard/assets/keenicons/duotone/style.css'
import '@/metronic-dashboard/my-css.css'
import { MetronicI18nProvider } from '@/metronic-dashboard/i18n/Metronici18n'

try {
  const root = document.getElementById('root')
  if (!root) {
    throw new Error('Root element not found')
  }

  const reactRoot = ReactDOM.createRoot(root)

  reactRoot.render(
    <React.StrictMode>
      <MetronicI18nProvider>
        <BrowserRouter>
          <Routes>
            {/* This will handle all routes and pass them to your App component */}
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </MetronicI18nProvider>
    </React.StrictMode>
  )
} catch (error) {
  console.error('Error during initialization:', error)
}