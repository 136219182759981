import { Route, Routes } from 'react-router-dom';
import LoginLayout from './LoginLayout';
import RegistrationLayout from './RegistrationLayout';
import Login from './components/Login';
import Registration from './components/Registration';
import ForgotPassword from './components/ForgotPassword';
import EmailSent from './components/EmailSent';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import NotFound from '@/pages/NotFound';

const AuthPage = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='email-sent' element={<EmailSent />} />
      <Route path='verify-email' element={<VerifyEmail />} />
      <Route index element={<Login />} />
    </Route>

    <Route element={<RegistrationLayout />}>
      <Route path='registration' element={<Registration />} />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
)

export { AuthPage }