import { useEffect, useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import CommentsSection from '@/components/CommentsSection';
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen, ErrorScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger'
import moment from 'moment-jalaali';
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr'
import Cookies from 'js-cookie'

import workshopIcon from '@/assets/svg/workshop.svg'
import diamondIcon from '@/assets/svg/diamond.svg'
import chatIcon from '@/assets/svg/Chat 2.svg'
import videoIcon from '@/assets/svg/Video.svg'
import docIcon from '@/assets/svg/doc.svg'
import arrowIcon from '@/assets/svg/Arrow - Left.svg'
import lineDecorationIcon from "@/assets/svg/line-decoration1.svg"
import circlesIcon from "@/assets/svg/circles.svg"
import dangerCircleIcon from "@/assets/svg/Danger Circle.svg"
import playIcon from "@/assets/svg/Play.svg"
import clockIcon from "@/assets/svg/Time Circle_rooyekhat.svg"
import calendarIcon from "@/assets/svg/Calendar_rooyekhat.svg"
import priceIcon from "@/assets/svg/Price_rooyekhat.svg"


declare let tippy: any;


const OnTheLine = () => {
    // State Management
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [error, setError] = useState(false);
    const [noComment, setNoComment] = useState(false);
    const [livesData, setLivesdata] = useState(null);
    const [purchasedProducts, setPurchasedProducts] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const [desFromTop, setDesFromTop] = useState(0);

    // Constants and Context
    const { cart, addToCart } = useContext(CartContext);
    const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

    // Derived State
    const next_lives = livesData?.next_lives || [];
    const finished_lives = livesData?.finished_lives || [];
    const package_lives = livesData?.package_lives || [];

    // Refs
    const des1 = useRef(null);
    const des2 = useRef(null);
    const des3 = useRef(null);
    const theEl = useRef(null);

    // Helper Functions
    const convertToJalali = (dateString: string) => {
        if (!dateString) return '';

        try {
            const jalaliDate = moment(dateString).format('jYYYY/jMM/jDD');
            const [year, month, day] = jalaliDate.split('/');

            const dayNames = ['یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه', 'شنبه'];
            const dayName = dayNames[moment(dateString).day()];

            const monthNames = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];
            const monthName = monthNames[parseInt(month) - 1];

            return `${dayName} ${day} ${monthName} ${year}`;
        } catch (error) {
            console.error('Error converting date to Jalali:', error);
            return dateString;
        }
    };

    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) {
            setNav_DropDown(false);
        }
    };

    const checkIfPurchased = product => {
        if (!product) return false;
        return purchasedProducts?.find(i => i === product.id);
    };

    const scrollHandler = (destination) => {
        if (destination.current) {
            const { top } = destination.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + top - 80,
                behavior: 'smooth'
            });
        }
    };

    const handleDistanceFromTop = () => {
        if (theEl.current) {
            const rect = theEl.current.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setDesFromTop(rect.top + scrollTop);
        }
    };

    // API Calls
    const fetchData = async () => {
        try {
            const [livesResponse, purchasedResponse] = await Promise.all([
                axiosInstance.get('/website/live_list'),
                isLogin ? axiosInstance.get('/order/purchased', {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                    },
                }) : Promise.resolve({ data: { status: 0 } })
            ]);

            const lives = livesResponse.data;

            if (purchasedResponse.data.status === 1) {
                const { purchased_orders, purchased_packages } = purchasedResponse.data;

                const updateLivesWithPurchaseInfo = (live) => ({
                    ...live,
                    isPurchasedInIr: purchased_orders.ir.includes(live.id) || purchased_packages.ir.includes(live.id),
                    isPurchasedInCom: purchased_orders.com.includes(live.id) || purchased_packages.com.includes(live.id),
                });

                setLivesdata({
                    ...lives,
                    next_lives: lives.next_lives.map(updateLivesWithPurchaseInfo),
                    finished_lives: lives.finished_lives.map(updateLivesWithPurchaseInfo),
                    package_lives: lives.package_lives.map(updateLivesWithPurchaseInfo),
                });
                const checkIfPurchased = product => {
                    if (!product) return;
                    return purchasedProducts?.find(i => i === product.id);
                }


                if (isDotIr) {
                    setPurchasedProducts([...purchased_orders.ir, ...purchased_packages.ir, ...purchased_orders.com, ...purchased_packages.com]);
                } else {
                    setPurchasedProducts(purchased_orders.com);
                }
            } else {
                setLivesdata(lives);
            }
        } catch (error) {
            console.error('Error fetching live data:', error);
            setError(true);
        }
    };

    // Effects
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
            handleDistanceFromTop();
        };

        window.addEventListener('resize', handleResize);

        fetchData();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        convertNumsToFa();
        handleDistanceFromTop();
    }, [livesData, showMore]);

    useEffect(() => {
        handleDistanceFromTop();
    }, [livesData, isMobile]);

    // Initialize tooltips for purchased items
    useEffect(() => {
        const initTooltips = () => {
            const items = [...next_lives, ...finished_lives, ...package_lives];
            items.forEach(item => {
                if (!isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom) {
                    const element = document.querySelector(`#tooltip-${item.id}`);
                    if (element) {
                        tippy(element, {
                            content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش جلسه، VPN خود را خاموش و صفحه را رفرش کنید.",
                        });
                    }
                }
            });
        };

        if (livesData) {
            initTooltips();
        }
    }, [livesData]);

    if (error) return <ErrorScreen />;



    return (
        <div className='bg-white' onClick={close_Popup}>
            <LoadingScreen loading={!livesData} />

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[310px] mb-[150px] max-lg:mb-[120px] bg-[#001605] rounded-[5vw] z-0'
                ></div>

                <div className='my-container'>
                    <div className='relative flex lg:gap-16 max-lg:flex-col max-lg:mt-5 max-w-full'>
                        <div className='lg:hidden'>
                            <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                                <h3 className='text-[#212121] font-bold max-lg:text-[27px] flex gap-4'>
                                    <img src={workshopIcon} width={30} className='max-lg:hidden' />
                                    {livesData?.category.title}
                                </h3>
                            </div>
                            <div className='text-[#212121] text-[21px] font-semibold max-lg:text-center mb-4'>
                                {livesData?.category.list_title}
                            </div>
                        </div>

                        <div ref={theEl} className='mt-auto mx-auto max-w-full'>
                            <div className='rounded-xl p-1 bg-white w-[370px] h-[455px] max-w-full relative' style={{ border: '1px solid #E0E0E0' }}>
                                <img src={livesData?.category.image} className='rounded h-full w-max object-cover' />
                            </div>
                        </div>
                        <div className='grow gap-14 flex flex-col justify-between max-lg:text-center'>
                            <div className='max-lg:hidden mt-2'>
                                <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                                    <h3 className='text-[#212121] font-bold max-lg:text-[27px] flex gap-4'>
                                        <img src={workshopIcon} width={30} className='max-lg:hidden' />
                                        {livesData?.category.title}
                                    </h3>
                                </div>
                                <div className='text-[#212121] text-[21px] font-semibold  max-lg:text-[20px] max-lg:text-center mb-5'>
                                    {livesData?.category.list_title}
                                </div>
                            </div>
                            <div>
                                <div className='flex items-start gap-2 font-semibold max-lg:flex-col max-lg:items-center'>
                                    <img src={diamondIcon} className='w-[20px] max-lg:w-[32px] max-lg:mt-6 lg:mt-2' />
                                    <p
                                        className='text-justify text-[21px]'
                                        dangerouslySetInnerHTML={{ __html: livesData?.category.list_info }}
                                    />
                                </div>
                                <div className='flex justify-between max-lg:justify-center flex-wrap items-center gap-3 mt-6'>
                                    <button onClick={() => scrollHandler(des1)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={videoIcon} className='max-lg:hidden' width={21} />
                                        <p className='font-semibold text-[20px] max-lg:font-medium max-lg:text-[18px]'>نحوه ثبت‌نام و استفاده از جلسه</p>
                                    </button>
                                    <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                    <button onClick={() => scrollHandler(des2)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={docIcon} className='max-lg:hidden' width={17} />
                                        <p className='font-semibold text-[20px] max-lg:font-medium max-lg:text-[18px]'>اطلاعات تکمیلی</p>
                                    </button>
                                    <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                    <button onClick={() => scrollHandler(des3)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                        <img src={chatIcon} className='max-lg:hidden' width={21} />
                                        <p className='font-semibold text-[20px] max-lg:font-medium max-lg:text-[18px]'>دیدگاه شرکت کنندگان</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='relative mt-20 flex flex-col lg:gap-20 max-lg:gap-14'>
                        <img src={lineDecorationIcon} className='absolute left-[-134px] -top-80' />
                        <img src={lineDecorationIcon} className='absolute right-[-134px] bottom-0' />

                        {next_lives &&
                            <div>
                                <div className="max-lg:hidden flex items-center gap-5">
                                    <h3>رزرو جلسات پیش رو</h3>
                                    <div className='h-[1px] grow bg-[#eee6]'></div>
                                </div>
                                <div className="lg:hidden text-center">
                                    <h3>رزرو جلسات پیش رو</h3>
                                    <div className='lg:px-32 w-full flex items-center mt-5 mb-2'>
                                        <div className='grow h-[1px] bg-[#bdbdbd]'></div>
                                        <img src={circlesIcon} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-5 mt-5">
                                    {next_lives.map((item, i) => {
                                        const productPurchasedInIran = !isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom;

                                        if (productPurchasedInIran) {
                                            tippy(`#tooltip-${item.id}`, {
                                                content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش جلسه، VPN خود را خاموش و صفحه را رفرش کنید.",
                                            });
                                        }

                                        return (
                                            <div key={i} className="bg-[#F1C31B] flex justify-between items-center max-lg:flex-col gap-x-3 md:gap-y-5 max-md:gap-y-3 rounded-xl p-4">
                                                <div className='items-center max-md:hidden gap-4 grow grid' style={{ gridTemplateColumns: 'repeat(30, minmax(0, 1fr))' }}>
                                                    <div className="flex items-center gap-2 col-span-8 text-center">
                                                        <img src={playIcon} />
                                                        <h3 className='font-semibold text-black text-[24px] -mb-[2px]'>
                                                            {item.title_fa}
                                                        </h3>
                                                    </div>
                                                    <div className='h-8 w-px bg-[#535252]'></div>
                                                    <p className='font-semibold text-[21px] text-[#050505] col-span-12 text-center'>
                                                        {item.live_detail?.start_date && convertToJalali(item.live_detail?.start_date)}{' '}
                                                        - ساعت
                                                        {' '}{item.live_detail?.start_time?.endsWith(':00')
                                                            ? item.live_detail.start_time.slice(0, -3)
                                                            : item.live_detail?.start_time || '-'}{' '}
                                                        به وقت تهران
                                                    </p>
                                                    <div className='h-8 w-px bg-[#535252]'></div>
                                                    <h4 className='text-[#212121] -mb-1 flex gap-1 col-span-8 justify-center items-center text-[22px]'>
                                                        قیمت:
                                                        <div className='flex gap-1'>
                                                            {item.discount_price && item.discount_price !== item.price ?
                                                                <>
                                                                    <p className={`line-through text-[18px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                    </p>
                                                                    <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                        </b>
                                                                        {isDotIr && ' تومان'}
                                                                    </div>
                                                                </>
                                                                :
                                                                <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                    <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                    </b>
                                                                    {isDotIr && ' تومان'}
                                                                </p>
                                                            }
                                                        </div>
                                                    </h4>
                                                </div>
                                                <div className="md:hidden flex flex-col w-full items-start gap-5">
                                                    <div className="flex items-center gap-2">
                                                        <img src={playIcon} />
                                                        <h3 className='font-semibold text-[27px] text-black'>
                                                            {item.title_fa}
                                                        </h3>
                                                    </div>
                                                    <div className='h-px w-full -mt-3 bg-[#535252]'></div>
                                                    <div className="flex w-full flex-col gap-2">
                                                        <div className="flex justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={calendarIcon} />
                                                                <span className='text-[#212121] font-semibold text-[20px]'>تاریخ جلسه:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold text-[#212121]'>
                                                                {item.live_detail?.start_date ? convertToJalali(item.live_detail?.start_date) : '-'}
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={clockIcon} />
                                                                <span className='text-[#212121] font-semibold text-[20px]'>ساعت جلسه:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold text-[#212121]'>
                                                                ساعت
                                                                {' '}{item.live_detail?.start_time?.endsWith(':00')
                                                                    ? item.live_detail.start_time.slice(0, -3)
                                                                    : item.live_detail?.start_time || '-'}{' '}
                                                                به وقت تهران
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between flex-wrap">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={priceIcon} />
                                                                <span className='text-[#212121] font-semibold text-[20px]'>هزینه شرکت در جلسه:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold text-[#212121] mr-auto'>
                                                                <div className='flex gap-1'>
                                                                    {item.discount_price && item.discount_price !== item.price ?
                                                                        <>
                                                                            <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                            </p>
                                                                            <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                    {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                                </b>
                                                                                {isDotIr && ' تومان'}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                            </b>
                                                                            {isDotIr && ' تومان'}
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex max-md:w-full justify-end flex-wrap items-center gap-2 mr-auto max-md:justify-between max-md:flex-row-reverse">
                                                    <Link to={'/live-meeting/' + item.id} className='grow justify-center text-[#292929] bg-white/10 hover:bg-white/0 transition font-semibold text-[20px] h-10 flex items-center px-3 rounded' style={{ border: '1px solid #2E2E2E' }}>اطلاعات بیشتر</Link>
                                                    {item.remain_capacity === 0 ?
                                                        <button className='grow justify-center h-10 flex items-center px-3 rounded bg-[#888] font-semibold text-[20px] pointer-events-none'>ظرفیت تکمیل شده است.</button>
                                                        : checkIfPurchased(item) ?
                                                            <Link to={`/dashboard/products/live-meeting/live/${item.id}`} className='grow justify-center h-10 flex items-center px-3 rounded transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[20px] text-white'>بخش نمایش جلسه</Link>
                                                            :
                                                            <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && '#3b7434' }} className='grow justify-center h-10 flex items-center px-3 rounded transition bg-[#418039] hover:bg-[#3b7434] font-semibold text-[20px] relative'>
                                                                <>
                                                                    {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                                                    {productPurchasedInIran &&
                                                                        <div id={`tooltip-${item.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                            </svg>
                                                                        </div>
                                                                    }
                                                                </>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        {package_lives &&
                            <div>
                                <div className="max-lg:hidden flex items-center gap-5">
                                    <h3>بسته جامع روی خط</h3>
                                    <div className='h-[1px] grow bg-[#eee6]'></div>
                                </div>
                                <div className="lg:hidden text-center">
                                    <h3>بسته جامع روی خط</h3>
                                    <div className='lg:px-32 w-full flex items-center mt-6 mb-3'>
                                        <div className='grow h-[1px] bg-[#bdbdbd]'></div>
                                        <img src={circlesIcon} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                    </div>
                                </div>

                                {/* <p className='font-medium max-lg:text-center my-3'>
                                    این بسته شامل ویدیو ضبط شده {package_lives[0]?.included_courses_count} جلسه روی خط می باشد که در قالب اشتراک آنلاین تقدیم شما می شود.
                                </p> */}

                                <div className="flex flex-col gap-5 mt-6">
                                    {package_lives.map((item, i) => {
                                        const productPurchasedInIran = !isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom;

                                        if (productPurchasedInIran) {
                                            tippy(`#tooltip-${item.id}`, {
                                                content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش جلسه، VPN خود را خاموش و صفحه را رفرش کنید.",
                                            });
                                        }

                                        return (
                                            <div key={i} className="bg-[#00370D] text-white flex justify-between items-center max-lg:flex-col gap-x-3 md:gap-y-5 max-md:gap-y-3 flex-wrap rounded-xl p-4">
                                                <div className='items-center max-md:hidden gap-6 max-w-full grid grow' style={{ gridTemplateColumns: 'repeat(30, minmax(0, 1fr))' }}>
                                                    <div className="flex items-center gap-2 col-span-9 text-center">
                                                        <img src={playIcon} className='invert' />
                                                        <h3 className='font-semibold text-[24px] -mb-[2px]'>
                                                            {item.title_fa}
                                                        </h3>
                                                    </div>
                                                    <div className='h-8 w-px bg-[#535252]'></div>
                                                    <p className='font-semibold text-[21px] col-span-10 text-center'>
                                                        شامل جلسات
                                                        {' '}{item.included_courses[0]?.live_detail?.id}{' '}
                                                        تا
                                                        {' '}{item.included_courses[item.included_courses.length - 1]?.live_detail?.id}{' '}
                                                        روی خط
                                                    </p>
                                                    <div className='h-8 w-px bg-[#535252]'></div>
                                                    <h4 className='-mb-1 col-span-9 text-center flex gap-1 justify-center items-center text-[22px]'>
                                                        قیمت:
                                                        <div className='flex gap-1'>
                                                            {item.discount_price && item.discount_price !== item.price ?
                                                                <>
                                                                    <p className={`line-through text-[18px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                    </p>
                                                                    <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                        </b>
                                                                        {isDotIr && ' تومان'}
                                                                    </div>
                                                                </>
                                                                :
                                                                <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                    <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                    </b>
                                                                    {isDotIr && ' تومان'}
                                                                </p>
                                                            }
                                                        </div>
                                                    </h4>
                                                </div>
                                                <div className="md:hidden flex flex-col w-full items-start gap-5">
                                                    <div className="flex items-center gap-2">
                                                        <img src={playIcon} className='invert' />
                                                        <h3 className='font-semibold text-[27px]'>
                                                            {item.title_fa}
                                                        </h3>
                                                    </div>
                                                    <div className='h-px w-full -mt-3 bg-[#535252]'></div>
                                                    <div className="flex w-full flex-col gap-2">
                                                        <div className="flex justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={docIcon} width={17} className='opacity-88 ml-[3px]' />
                                                                <span className=' font-semibold text-[20px]'>درباره بسته:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold '>
                                                                شامل جلسات
                                                                {' '}{item.included_courses[0]?.live_detail?.id}{' '}
                                                                تا
                                                                {' '}{item.included_courses[item.included_courses.length - 1]?.live_detail?.id}{' '}
                                                                روی خط
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={priceIcon} className='invert' />
                                                                <span className=' font-semibold text-[20px]'>اشتراک آنلاین:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold'>
                                                                <div className='flex gap-1'>
                                                                    {item.discount_price && item.discount_price !== item.price ?
                                                                        <>
                                                                            <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                            </p>
                                                                            <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                    {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                                </b>
                                                                                {isDotIr && ' تومان'}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                            </b>
                                                                            {isDotIr && ' تومان'}
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-2 mr-auto max-md:w-full max-md:justify-between max-md:flex-row-reverse">
                                                    <Link to={'/live-meeting/' + item.id} className='grow justify-center bg-white/10 hover:bg-white/5 text-white transition font-semibold text-[20px] h-10 flex items-center px-3 rounded' style={{ border: '1px solid #fff' }}>اطلاعات بیشتر</Link>
                                                    {item.remain_capacity === 0 ?
                                                        <button className='grow justify-center h-10 flex items-center px-3 rounded bg-[#888] font-semibold text-[20px] pointer-events-none'>ظرفیت تکمیل شده است.</button>
                                                        : checkIfPurchased(item) ?
                                                            <Link to={`/dashboard/products/live-meeting/live/${item.id}`} className='grow justify-center h-10 flex items-center px-3 rounded transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[20px] text-white'>بخش نمایش جلسه</Link>
                                                            :
                                                            <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && '#5bac51' }} className='grow justify-center h-10 flex items-center px-3 rounded transition bg-[#67BD5C] hover:bg-[#5bac51] font-semibold text-[20px] relative'>
                                                                {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                                                {productPurchasedInIran &&
                                                                    <div id={`tooltip-${item.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                        </svg>
                                                                    </div>
                                                                }
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        {finished_lives &&
                            <div>
                                <div className="max-lg:hidden flex items-center gap-5">
                                    <h3> جلسات برگزار شده</h3>
                                    <div className='h-[1px] grow bg-[#eee6]'></div>
                                </div>
                                <div className="lg:hidden text-center">
                                    <h3> جلسات برگزار شده</h3>
                                    <div className='lg:px-32 w-full flex items-center mt-6 mb-3'>
                                        <div className='grow h-[1px] bg-[#bdbdbd]'></div>
                                        <img src={circlesIcon} className='mt-[1px]' />
                                        <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                    </div>
                                </div>

                                {/* <p className='font-medium max-lg:text-center my-3'>
                                    این بسته شامل ویدیو ضبط شده {finished_lives?.length} جلسه روی خط می باشد که در قالب اشتراک آنلاین تقدیم شما می شود.
                                </p> */}

                                <div className="flex flex-col gap-5 mt-6">
                                    {finished_lives.slice(0, 4).map((item, i) => {
                                        const productPurchasedInIran = !isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom;

                                        if (productPurchasedInIran) {
                                            tippy(`#tooltip-${item.id}`, {
                                                content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش جلسه، VPN خود را خاموش و صفحه را رفرش کنید.",
                                            });
                                        }

                                        return (
                                            <div key={i} className="flex justify-between flex-wrap items-center max-lg:flex-col gap-x-3 md:gap-y-5 max-md:gap-y-3 rounded-xl p-4" style={{ background: 'linear-gradient(120deg, #fff, #999 130%)' }}>
                                                <div className='grid grow items-center max-md:hidden gap-4' style={{ gridTemplateColumns: 'repeat(37, minmax(0, 1fr))' }}>
                                                    <div className="flex items-center gap-2 col-span-10">
                                                        <img src={playIcon} />
                                                        <h3 className='font-semibold text-black text-[24px] -mb-[2px]'>
                                                            {item.title_fa}
                                                        </h3>
                                                    </div>
                                                    <div className='h-8 w-px bg-[#535252]'></div>
                                                    <p className='font-semibold text-[20px] text-[#050505] text-center' style={{ gridColumn: 'span 14 / span 14' }}>
                                                        خرید اشتراک آنلاین و دائمی فیلم ضبط شده جلسه
                                                    </p>
                                                    <div className='h-8 w-px bg-[#535252]'></div>
                                                    <h4 className='text-[#212121] -mb-1 col-span-11 flex gap-1 justify-center items-center text-[22px]'>
                                                        قیمت:
                                                        <div className='flex gap-1'>
                                                            {item.discount_price && item.discount_price !== item.price ?
                                                                <>
                                                                    <p className={`line-through text-[18px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                    </p>
                                                                    <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                        </b>
                                                                        {isDotIr && ' تومان'}
                                                                    </div>
                                                                </>
                                                                :
                                                                <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                    <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                    </b>
                                                                    {isDotIr && ' تومان'}
                                                                </p>
                                                            }
                                                        </div>
                                                    </h4>
                                                </div>
                                                <div className="md:hidden flex flex-col w-full items-start gap-5">
                                                    <div className="flex items-center gap-2">
                                                        <img src={playIcon} />
                                                        <h3 className='font-semibold text-[27px] text-black'>
                                                            {item.title_fa}
                                                        </h3>
                                                    </div>
                                                    <div className='h-px w-full -mt-3 bg-[#535252]'></div>
                                                    <div className="flex w-full flex-col gap-2">
                                                        <div className="flex justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={calendarIcon} />
                                                                <span className='text-[#212121] font-semibold text-[20px]'>تاریخ جلسه:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold text-[#212121]'>
                                                                {convertToJalali(item.live_detail.start_date)}
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={clockIcon} />
                                                                <span className='text-[#212121] font-semibold text-[20px]'>ساعت جلسه:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold text-[#212121]'>
                                                                ساعت
                                                                {' '}{item.live_detail?.start_time?.endsWith(':00')
                                                                    ? item.live_detail.start_time.slice(0, -3)
                                                                    : item.live_detail?.start_time || '-'}{' '}
                                                                به وقت تهران
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between flex-wrap">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={priceIcon} />
                                                                <span className='text-[#212121] font-semibold text-[20px]'>هزینه شرکت در جلسه:</span>
                                                            </div>
                                                            <p className='text-[20px] font-semibold text-[#212121] mr-auto'>
                                                                <div className='flex gap-1'>
                                                                    {item.discount_price && item.discount_price !== item.price ?
                                                                        <>
                                                                            <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                            </p>
                                                                            <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                    {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                                </b>
                                                                                {isDotIr && ' تومان'}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                            </b>
                                                                            {isDotIr && ' تومان'}
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-2 mr-auto max-md:w-full max-md:justify-between max-md:flex-row-reverse">
                                                    <Link to={'/live-meeting/' + item.id} className='grow justify-center text-[#292929] bg-white/10 hover:bg-white/0 transition font-semibold text-[20px] h-10 flex items-center px-3 rounded' style={{ border: '1px solid #2E2E2E' }}>اطلاعات بیشتر</Link>
                                                    {item.remain_capacity === 0 ?
                                                        <button className='grow justify-center h-10 flex items-center px-3 rounded bg-[#888] font-semibold text-[20px] pointer-events-none'>ظرفیت تکمیل شده است.</button>
                                                        : checkIfPurchased(item) ?
                                                            <Link to={`/dashboard/products/live-meeting/live/${item.id}`} className='grow justify-center h-10 flex items-center px-3 rounded transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[20px] text-white'>بخش نمایش جلسه</Link>
                                                            :
                                                            <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && '#3b7434' }} className='grow justify-center h-10 flex items-center px-3 rounded transition bg-[#418039] hover:bg-[#3b7434] font-semibold text-[20px] relative'>
                                                                {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                                                {productPurchasedInIran &&
                                                                    <div id={`tooltip-${item.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                        </svg>
                                                                    </div>
                                                                }
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {(!isMobile || showMore) && <>
                                        {finished_lives.slice(4).map((item, i) => {
                                            const productPurchasedInIran = !isDotIr && item.isPurchasedInIr && !item.isPurchasedInCom;

                                            if (productPurchasedInIran) {
                                                tippy(`#tooltip-${item.id}`, {
                                                    content: "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش جلسه، VPN خود را خاموش و صفحه را رفرش کنید.",
                                                });
                                            }

                                            return (
                                                <div key={i} className="flex justify-between flex-wrap items-center max-lg:flex-col gap-x-3 md:gap-y-5 max-md:gap-y-3 rounded-xl p-4" style={{ background: 'linear-gradient(120deg, #fff, #999 130%)' }}>
                                                    <div className='grid grow items-center max-md:hidden gap-4' style={{ gridTemplateColumns: 'repeat(37, minmax(0, 1fr))' }}>
                                                        <div className="flex items-center gap-2 col-span-10">
                                                            <img src={playIcon} />
                                                            <h3 className='font-semibold text-black text-[24px] -mb-[2px]'>
                                                                {item.title_fa}
                                                            </h3>
                                                        </div>
                                                        <div className='h-8 w-px bg-[#535252]'></div>
                                                        <p className='font-semibold text-[20px] text-[#050505] text-center' style={{ gridColumn: 'span 14 / span 14' }}>
                                                            خرید اشتراک آنلاین و دائمی فیلم ضبط شده جلسه
                                                        </p>
                                                        <div className='h-8 w-px bg-[#535252]'></div>
                                                        <h4 className='text-[#212121] -mb-1 col-span-11 text-center flex gap-1 justify-center items-center text-[22px]'>
                                                            قیمت:
                                                            <div className='flex gap-1'>
                                                                {item.discount_price && item.discount_price !== item.price ?
                                                                    <>
                                                                        <p className={`line-through text-[18px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                        </p>
                                                                        <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                            </b>
                                                                            {isDotIr && ' تومان'}
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                        <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                            {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                        </b>
                                                                        {isDotIr && ' تومان'}
                                                                    </p>
                                                                }
                                                            </div>
                                                        </h4>
                                                    </div>
                                                    <div className="md:hidden flex flex-col w-full items-start gap-5">
                                                        <div className="flex items-center gap-2">
                                                            <img src={playIcon} />
                                                            <h3 className='font-semibold text-[27px] text-black'>
                                                                {item.title_fa}
                                                            </h3>
                                                        </div>
                                                        <div className='h-px w-full -mt-3 bg-[#535252]'></div>
                                                        <div className="flex w-full flex-col gap-2">
                                                            <div className="flex justify-between">
                                                                <div className="flex gap-2 items-center">
                                                                    <img src={calendarIcon} />
                                                                    <span className='text-[#212121] font-semibold text-[20px]'>تاریخ جلسه:</span>
                                                                </div>
                                                                <p className='text-[20px] font-semibold text-[#212121]'>
                                                                    {convertToJalali(item.live_detail.start_date)}
                                                                </p>
                                                            </div>
                                                            <div className="flex justify-between">
                                                                <div className="flex gap-2 items-center">
                                                                    <img src={clockIcon} />
                                                                    <span className='text-[#212121] font-semibold text-[20px]'>ساعت جلسه:</span>
                                                                </div>
                                                                <p className='text-[20px] font-semibold text-[#212121]'>
                                                                    ساعت
                                                                    {' '}{item.live_detail?.start_time?.endsWith(':00')
                                                                        ? item.live_detail.start_time.slice(0, -3)
                                                                        : item.live_detail?.start_time || '-'}{' '}
                                                                    به وقت تهران
                                                                </p>
                                                            </div>
                                                            <div className="flex justify-between flex-wrap">
                                                                <div className="flex gap-2 items-center">
                                                                    <img src={priceIcon} />
                                                                    <span className='text-[#212121] font-semibold text-[20px]'>هزینه شرکت در جلسه:</span>
                                                                </div>
                                                                <p className='text-[20px] font-semibold text-[#212121] mr-auto'>
                                                                    <div className='flex gap-1'>
                                                                        {item.discount_price && item.discount_price !== item.price ?
                                                                            <>
                                                                                <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                    {isDotIr ? (item.price / 10).toLocaleString() : item.price.toLocaleString()}
                                                                                </p>
                                                                                <div className={`rounded text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                    <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                        {isDotIr ? (item.discount_price / 10).toLocaleString() : '$' + item.discount_price.toLocaleString()} {' '}
                                                                                    </b>
                                                                                    {isDotIr && ' تومان'}
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <p className={`text-[21px] font-medium ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                <b className={`font-semibold text-[21px] ${!isDotIr && 'keep-english-numbers'}`}>
                                                                                    {isDotIr ? (item.price / 10).toLocaleString() : '$' + item.price.toLocaleString()} {' '}
                                                                                </b>
                                                                                {isDotIr && ' تومان'}
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex max-sm:flex-col items-center gap-2 mr-auto max-md:w-full max-md:justify-between max-md:flex-row-reverse">
                                                        <Link to={'/live-meeting/' + item.id} className='max-md:w-full justify-center text-[#292929] bg-white/10 hover:bg-white/0 transition font-semibold text-[20px] h-10 flex items-center px-3 rounded' style={{ border: '1px solid #2E2E2E' }}>اطلاعات بیشتر</Link>
                                                        {item.remain_capacity === 0 ?
                                                            <button className='max-md:w-full justify-center h-10 flex items-center px-3 rounded bg-[#888] font-semibold text-[20px] pointer-events-none'>ظرفیت تکمیل شده است.</button>
                                                            : checkIfPurchased(item) ?
                                                                <Link to={`/dashboard/products/live-meeting/live/${item.id}`} className='max-md:w-full justify-center h-10 flex items-center px-3 rounded transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[20px] text-white'>بخش نمایش جلسه</Link>
                                                                :
                                                                <button onClick={() => addToCart(item)} disabled={cart.some(cartItem => cartItem.id === +item.id)} style={{ background: cart.some(cartItem => cartItem.id === +item.id) && '#3b7434' }} className='max-md:w-full justify-center h-10 flex items-center px-3 rounded transition bg-[#418039] hover:bg-[#3b7434] font-semibold text-[20px] relative'>
                                                                    {cart.some(cartItem => cartItem.id === +item.id) ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                                                    {productPurchasedInIran &&
                                                                        <div id={`tooltip-${item.id}`} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                                                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                                            </svg>
                                                                        </div>
                                                                    }
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>}
                                    {finished_lives.length > 4 && !showMore && isMobile &&
                                        <button
                                            onClick={() => setShowMore(true)}
                                            className='font-semibold text-[20px] bg-[#063310] p-1 px-7 rounded border border-white w-fit mx-auto'
                                        >تماشای جلسات بیشتر</button>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div ref={des1} className='relative pt-20 flex flex-col items-center gap-5 w-[782px] max-w-full mx-auto'>
                        <h3>نحوه ثبت نام و استفاده از جلسه</h3>
                        <div
                            className='iframe-box w-full keep-english-numbers'
                            dangerouslySetInnerHTML={{ __html: livesData?.category.demo }}
                        />
                        <div className="max-lg:hidden flex gap-5 justify-between items-center w-full">
                            {/* <div className='h-[1px] grow bg-[#BDBDBD]'></div> */}
                            {/* {livesData?.remain_capacity === 0 ?
                                <button className='max-md:w-full justify-center h-10 flex items-center px-5 rounded bg-[#888] font-semibold text-[20px] pointer-events-none'>ظرفیت تکمیل شده است.</button>
                                : checkIfPurchased(livesData) ?
                                    <Link to={`/dashboard/products/live-meeting/live/${livesData.id}`} className='grow justify-center h-10 flex items-center px-2 rounded transition bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[20px] text-white'>بخش نمایش جلسه</Link>
                                    :
                                    <button className='group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-3'>
                                        <p className='font-bold text-[19px]'>افزودن به سبد خرید</p>
                                        <img src={arrowIcon} width={20} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />
                                    </button>
                            } */}
                            {/* <div className='h-[1px] grow bg-[#BDBDBD]'></div> */}
                        </div>
                    </div>
                </div>
            </section>

            <div ref={des2} className='my-container text-[#212121] w-full relative flex flex-col gap-5 items-center lg:pt-20 max-lg:pt-14'>
                <h3 className='text-center max-lg:px-[10vw] text-[#212121]'>اطلاعات تکمیلی</h3>
                <div className='lg:px-32 w-full flex items-center max-lg:-mt-3'>
                    <div className='grow h-[1px] bg-[#bdbdbd]'></div>
                    <img src={circlesIcon} className='mt-[1px]' />
                    <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                </div>
                <div className='grid grid-cols-2 items-end max-lg:grid-cols-1 lg:gap-x-9 mt-2'>
                    {livesData?.category.list_questions_and_answers && Object.entries(livesData?.category.list_questions_and_answers as Record<string, string>).map(([questionId, answer], i, arr) => {
                        const isEven = arr.length % 2 === 0;
                        const isSecondLast = i === arr.length - 2;

                        return (
                            <div
                                key={i}
                                className={`flex flex-col gap-1 py-4 border-b border-[#bdbdbd77] last:border-none ${isEven && isSecondLast && 'lg:border-none'}`}
                            >
                                <div className="flex gap-2">
                                    <img src={dangerCircleIcon} className='invert' />
                                    <p className='font-semibold text-[20px]'>{questionId}</p>
                                </div>
                                <p className='font-normal text-[18px] text-justify'>{answer}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

            <div ref={des3} />
            <CommentsSection onNoComments={() => setNoComment(true)} fromCategory withExtraInfo id={+livesData?.category.id} title='دیدگاه شرکت کنندگان جلسات روی خط' />

            <Footer />
        </div>
    )
}

export default OnTheLine;