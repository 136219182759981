import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import axiosInstance from '@/utils/axiosInstance';
import { isDotIr } from '@/utils/isDotIr'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('فرمت ایمیل نادرست است')
        .min(3, 'حداقل ۳ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن ایمیل الزامی است.'),
    password: Yup.string()
        .min(6, 'حداقل 6 کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن رمز عبور الزامی است.'),
    remember: Yup.boolean(),
});

const Login = ({ returnUrl = '/dashboard' }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(undefined);
    const navigate = useNavigate();

    const saveAuth = (authData) => {
        if (authData) {
            localStorage.setItem('auth', JSON.stringify(authData));
        } else {
            localStorage.removeItem('auth');
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: false,
        },
        validationSchema: loginSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                const response = await axiosInstance.post('/login', {
                    email: values.email,
                    password: values.password,
                    remember: values.remember ? 1 : 0,
                });

                const auth = response.data;
                saveAuth(auth);

                Cookies.set(
                    isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com',
                    auth.access_token,
                    { expires: values.remember ? 31 : 1 }
                );

                navigate(returnUrl); // Use the returnUrl prop here
            } catch (error) {
                saveAuth(null);
                console.log(error.response.data.errors);
                setStatus(error.response.data.errors);
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        if (Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')) {
            navigate(returnUrl); // Use the returnUrl prop here too
        }
    }, []);

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <div className='text-center mb-3'>
                <h1 className='text-gray-900 fw-bolder mb-3 text-[30px]'>ورود به حساب کاربری</h1>
                <div className='fw-semibold text-[19.5px] text-center !text-white'>
                    با ورود به حساب از تمامی امکانات سایت بهره‌مند شوید.
                </div>
            </div>

            {status && (
                <div className='my-lg-5 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{status}</div>
                </div>
            )}

            <div className='fv-row mb-5 mt-4'>
                <label htmlFor="email-input" className='form-label font-semibold !text-[21px] text-gray-900'>آدرس ایمیل</label>
                <input
                    id="email-input"
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        { 'is-valid': formik.touched.email && !formik.errors.email }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.email}</span>
                    </div>
                )}
            </div>

            <div className='fv-row mb-6'>
                <label htmlFor="pass-input" className='form-label text-gray-900 font-semibold !text-[21px]'>رمز عبور</label>
                <input
                    id='pass-input'
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.password && formik.errors.password },
                        { 'is-valid': formik.touched.password && !formik.errors.password }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-4'>
                <label className='form-check form-check-custom form-check-solid'>
                    <input
                        className='form-check-input scale-75 !bg-transparent border border-white'
                        type='checkbox'
                        {...formik.getFieldProps('remember')}
                        id='remember_me'
                    />
                    <span className='form-check-label fw-semibold text-gray-900 text-[19px] translate-x-1' htmlFor='remember_me'>
                        مرا به خاطر بسپار
                    </span>
                </label>

                <Link to='/auth/forgot-password' className='text-white text-[19px]'>
                    فراموشی رمز عبور؟
                </Link>
            </div>

            <div className='d-grid mb-5'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded font-semibold h-[51px]'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>ورود</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            لطفاً صبر کنید...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>

            <div className='text-gray-900 fw-semibold text-[19px] -mb-3 -mt-1'>
                هنوز عضو نشده‌اید؟{' '}
                <Link to='/auth/registration' className='link-primary text-[19px]'>
                    ثبت‌نام
                </Link>
            </div>
        </form>
    );
};

export default Login;