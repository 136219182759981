import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '@/utils/axiosInstance';
import { convertNumsToFa } from '@/utils/numLangChanger';
import moment from 'moment';

import star2Icon from "@/assets/svg/Star.svg";
import arrowIcon from '@/assets/svg/Arrow - Left.svg';
import arrow2Icon from '@/assets/svg/Arrow - Right.svg';

const fetchComments = async (id, page = 1, fromCategory) => {
    try {
        const response = await axiosInstance.get(`/course/${fromCategory ? 'comment_list_category' : 'comment_list'}/${id}?page=${page}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching comments:', error);
        return { data: { current_page: 1, data: [], first_page_url: '', from: 1, last_page: 1, last_page_url: '', links: [], next_page_url: null, path: '', per_page: 10, prev_page_url: null, to: 1, total: 0 } };
    }
};

const formatDate = (dateString: string): string => {
    return moment(dateString).format('DD MMM YYYY');
};

const CommentsSection = ({ fromCategory = false, id = 0, title, withExtraInfo = false, onCommentCountChange, onNoComments }: { fromCategory?: boolean, id?: number; title: string; withExtraInfo?: boolean; onCommentCountChange?: (count: number) => void; onNoComments?: () => void }) => {
    const [comments, setComments] = useState<any[]>([]);
    const [pagination, setPagination] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const sectionRef = useRef<HTMLDivElement>(null);

    const loadComments = async (page: number) => {
        setLoading(true);
        const response = await fetchComments(id, page, fromCategory);
        setComments(response.data.data);
        setPagination(response.data);

        if (onCommentCountChange) {
            onCommentCountChange(response.data.total);
        }

        if (response.data.total === 0 && onNoComments) {
            onNoComments();
        }

        setLoading(false);
        // scrollToSection();
    };

    useEffect(() => {
        loadComments(currentPage);
    }, [id, currentPage]);

    // const scrollToSection = () => {
    //     if (sectionRef.current) {
    //         window.scrollTo({
    //             top: sectionRef.current.offsetTop - 50,
    //             behavior: 'smooth'
    //         });
    //     }
    // };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < pagination.last_page) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        convertNumsToFa();
    }, [comments, currentPage]);

    if (!comments.length) return <div className='h-16'></div>;


    return (
        <section id='comments-section' ref={sectionRef} className='my-container text-[#212121] relative mb-14 pt-20'>
            <h3 className='text-[#212121] text-center mb-8'>{title}</h3>
            <div className="flex flex-col gap-4 relative">
                {comments.map(comment => (
                    <div key={comment.id} className='comment-box lg:p-12 min-h-[137px] max-lg:p-6 py-6 bg-[#eee] text-[#616161] !rounded-3xl'>
                        <div className='flex justify-between mb-3 mt-2'>
                            <h5 className='text-[#454545] text-[22px] -mb-2'>{comment.user.full_name || 'محمود حبیبی'}</h5>
                            <div className="flex gap-6 items-center">
                                <span className="max-md:hidden keep-english-numbers font-medium text-[18px] text-[#6f6f6f] -mb-[.35rem]" style={{ direction: 'ltr' }}>
                                    {formatDate(comment.created_at)}
                                </span>
                                {withExtraInfo && <span className="max-md:hidden font-medium text-[18px] text-[#6f6f6f] -mb-1">{comment.course.title_fa}</span>}
                                <div className='flex gap-[4px] w-[86px] justify-end'>
                                    {[...Array(comment.points)].map((_, index) => (
                                        <img key={index} src={star2Icon} width={14} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="md:hidden flex justify-between border-b border-[#ddd] pb-2 mb-3">
                            {withExtraInfo &&
                                <>
                                    <span className="keep-english-numbers font-medium text-[18px] text-[#6f6f6f]" style={{ direction: 'ltr' }}>
                                        {formatDate(comment.created_at)}
                                    </span>
                                    <span className="text-[18px] font-medium text-[#6f6f6f]">{comment.course.title_fa}</span>
                                </>
                            }
                        </div>
                        <p className='m-0 leading-7 font-medium text-[18px] text-justify'>{comment.comment}</p>
                    </div>
                ))}

                {pagination.total > pagination.per_page && (
                    <div className='flex justify-between items-center mt-6'>
                        <div className='relative'>
                            {loading &&
                                <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
                                    <span className="spinner-border spinner-border-sm align-middle"></span>
                                </div>
                            }
                            <div className={`flex gap-3 items-center ${loading && 'opacity-40 pointer-events-none'}`}>
                                <button
                                    className='h-10 w-10 rounded flex items-center justify-center hover:bg-[#f8f8f8] transition'
                                    style={{ border: '1px solid var(--orange-primary)' }}
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                >
                                    <img src={arrow2Icon} width={23} />
                                </button>
                                <button
                                    className='font-semibold text-[20px] h-10 px-3 text-white rounded flex items-center justify-center gap-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'
                                    onClick={handleNextPage}
                                    disabled={currentPage === pagination.last_page}
                                >
                                    صفحه بعدی
                                    <img src={arrowIcon} />
                                </button>
                            </div>
                        </div>
                        <div className='flex gap-[.4rem] max-sm:gap-[.3rem]'>
                            <span className='font-semibold text-[20px] text-[#212121]'>صفحه</span>
                            <span className='font-semibold text-[20px] text-[#212121]'>{currentPage}</span>
                            <span className='font-semibold text-[20px] text-[#212121]'>از</span>
                            <span className='font-semibold text-[20px] text-[#212121]'>{pagination.last_page}</span>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default CommentsSection;