import { useState } from 'react';
import { Outlet } from 'react-router-dom'
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";

import img from '@/metronic-dashboard/assets/register-bg (1).jpg';
import img15 from '@/metronic-dashboard/assets/register-bg (3).jpg';
import img2 from '@/metronic-dashboard/assets/register-bg (2).jpg';
import img3 from '@/metronic-dashboard/assets/LineDecoration.svg';


const LoginLayout = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }


    return (
        <div data-bs-theme="dark" className='relative flex min-h-[1100px] flex-col items-center justify-between lg:gap-20 max-lg:gap-10 bg-[#132029]' onClick={close_Popup}>
            <img src={img3} className='max-lg:hidden absolute top-40 left-20 z-[1]' />

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} fromHomePage={true} />
            <div className='h-1' />

            <img src={img} className='max-lg:hidden 2xl:hidden absolute max-h-full w-full object-cover' />
            <img src={img15} className='max-2xl:hidden absolute max-h-full w-full object-cover' />
            <img src={img2} className='lg:hidden absolute inset-0 max-h-full w-full object-cover' />

            <div className='lg:mr-auto lg:px-56 max-lg:mt-[450px] lg:mt-[70px] max-sm:w-full'>
                <div className='relative lg:w-[446px] max-w-full max-sm:w-full bg-[#132029] rounded-xl lg:p-5 py-7 max-lg:px-7'>
                    <Outlet />
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default LoginLayout;