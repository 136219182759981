import React, { useState, useRef, useEffect, ReactNode } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { KTIcon } from '@/metronic-dashboard/helpers/components/KTIcon';
import { checkIsActive } from '@/metronic-dashboard/helpers/RouterHelpers';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  isOpen: boolean; // State to control whether the submenu is open or not
  setIsOpen: (isOpen: boolean) => void; // Function to set isOpen state
  closeOtherAccordions?: () => void; // Function to close other accordions
};

interface WithChildren {
  children: ReactNode;
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  isOpen,
  setIsOpen,
  closeOtherAccordions,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  const menuRef = useRef<HTMLDivElement>(null);
  const subMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen || isActive) {
      menuRef.current?.classList.add('show');
      menuRef.current?.classList.add('hover');
      subMenuRef.current?.classList.remove('acordion-hiding-anim');
      subMenuRef.current?.classList.add('acordion-showing-anim');

      // Calculate and set the height dynamically
      const height = subMenuRef.current?.scrollHeight;
      if (height) {
        subMenuRef.current.style.setProperty('--kt-hidden-height', `${height}px`);
      }
    } else {
      subMenuRef.current?.classList.remove('acordion-showing-anim');
      subMenuRef.current?.classList.add('acordion-hiding-anim');

      const timeoutId = setTimeout(() => {
        menuRef.current?.classList.remove('show');
        menuRef.current?.classList.remove('hover');
        subMenuRef.current?.classList.remove('show');
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, isActive]);

  function clickHandler() {
    if (closeOtherAccordions) {
      closeOtherAccordions(); // Close other accordions if the function is provided
    }
    setIsOpen(!isOpen); // Toggle isOpen state
  }
  

  return (
    <div
      ref={menuRef}
      className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
      data-kt-menu-trigger='click'
      onClick={clickHandler}
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title text-[18px]'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div
        ref={subMenuRef}
        className={clsx('menu-sub menu-sub-accordion  overflow-hidden', { 'menu-active-bg': isActive })}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };