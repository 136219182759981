import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import axiosInstance from '@/utils/axiosInstance';

const requestPassword = (email) => {
  return axiosInstance.post('/forget_password', {
    email,
  });
};

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('فرمت ایمیل نادرست است')
    .min(3, 'حداقل ۳ کاراکتر')
    .max(50, 'حداکثر ۵۰ کاراکتر')
    .required('وارد کردن ایمیل الزامی است'),
});

export default function ForgotPassword() {
  const navigator = useNavigate()

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(undefined);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      requestPassword(values.email)
        .then(() => {
          setHasErrors(false);
          setLoading(false);
          navigator('../email-sent')
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus('جزئیات ورود نادرست است!');
        });
    },
  });

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-6'>
        {/* begin::Title */}
        <h1 className='text-gray-900 fw-bolder text-[30px]'>فراموشی رمز عبور؟</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='fw-semibold text-[19px] text-center !text-white'>
          ایمیل خود را وارد کنید تا رمز عبور شما بازنشانی شود.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='my-lg-5 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            متاسفیم، به نظر می‌رسد که خطاهایی وجود دارد، لطفا دوباره تلاش کنید.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>بازنشانی رمز عبور ارسال شد. لطفا ایمیل خود را بررسی کنید.</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-6'>
        <label htmlFor='email' className='form-label font-semibold !text-[21px] text-gray-900'>ایمیل</label>
        <input
          id='email'
          style={{ direction: 'ltr' }}
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='px-7 py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded me-3'>
          {!loading && <span className='indicator-label'>ادامه</span>}
          {loading && (
            <span className='indicator-progress' disabled style={{ display: 'block' }}>
              لطفاً صبر کنید...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button data-bs-theme="light"
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            انصراف
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}