import { useEffect, useState, useRef } from 'react';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen, ErrorScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger';

import light from '@/assets/image/light4.png';
import light2 from '@/assets/image/light4.2.png';
import bookIcon from '@/assets/svg/book.svg';
import arrowIcon from '@/assets/svg/Arrow - Left.svg';
import arrow2Icon from '@/assets/svg/Arrow - Right.svg';
import circlesIcon from "@/assets/svg/circles3.svg"

const Reading = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/website/read_list', {
                    params: { page: currentPage }
                });
                setData(response.data);
                setCurrentPage(response.data.items.current_page);
                setTotalPages(response.data.items.last_page);

                setTimeout(() => {
                    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
                }, 1)
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage]);

    useEffect(() => {
        convertNumsToFa();
    }, [data, currentPage]);


    const theEl = useRef(null);
    const [desFromTop, setDesFromTop] = useState()

    const handleDistanceFromTop = () => {
        const element = theEl.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollTop;
            setDesFromTop(offsetTop)
        }
    }

    useEffect(() => {
        handleDistanceFromTop()
    }, [data])

    window.onresize = () => handleDistanceFromTop()

    if (error) return <ErrorScreen />;

    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <LoadingScreen loading={!data} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden lg:mb-8 max-lg:mb-20">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        background: 'linear-gradient(180deg, #0A2039, #1B1B1B)',
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[430px] mb-[170px] max-lg:mb-[200px] rounded-[5vw] z-0'
                ></div>

                <div className='my-container relative mb-8'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            {data?.category.title}
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-5 text-justify'>
                        {data?.category.list_title}
                    </div>
                </div>

                <div ref={theEl} className="my-container relative">
                    {data?.items.data.length > 3 && <>
                        <div className='max-lg:hidden absolute z-0 left-[-240px] top-32 w-[400px] h-[1000px]'>
                            <img src={light} className='mx-auto' />
                            <div className='absolute inset-0 top-[300px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #FFF20010 0%, transparent 40%)' }}></div>
                        </div>
                        <div className='max-lg:hidden absolute z-0 right-[-240px] bottom-[600px] w-[400px] h-[1000px]'>
                            <img src={light2} className='mx-auto' />
                            <div className='absolute inset-0 bottom-[-600px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #FFF20010 0%, transparent 20%)' }}></div>
                        </div>
                    </>}

                    <div className='relative flex flex-col gap-9'>
                        {data?.items.data.map((item, index) => {
                            // تابع برای بررسی وجود کلمات انگلیسی در متن
                            const isEnglishText = (text) => /[a-zA-Z]/.test(text);

                            return (
                                <div
                                    key={index}
                                    className={`flex max-lg:flex-col lg:gap-5 lg:p-5 max-lg:pt-5 rounded-2xl bg-[#233750] ${index % 2 === 1 ? 'lg:flex-row-reverse' : ''}`}
                                    style={{ border: '1px solid #536A86' }}
                                >
                                    <div className='mx-auto bg-white flex justify-center flex-col items-center rounded p-1 max-w-[calc(100%-40px)]'>
                                        <img src={item.image_media[0].src} className='rounded object-cover w-[400px] lg:max-h-[363px]' />
                                    </div>
                                    <div className='lg:bg-[#14263D] w-full flex flex-col gap-5 rounded p-5 text-white'>
                                        <div className="flex justify-between max-sm:flex-col max-sm:items-center">
                                            <h3 className='text-[27px]'>{item.title_fa}</h3>
                                            <p className={isEnglishText(item.author_fa) ? 'ltr' : ''}>{item.author_fa}</p>
                                        </div>

                                        <div className='w-full flex items-center -mt-2'>
                                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                            <img src={circlesIcon} />
                                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                                        </div>

                                        <p className={`font-medium text-[20px] tracking-wide text-justify ${isEnglishText(item.full_info) ? 'ltr' : ''}`}>
                                            {item.full_info}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}


                        {data?.items.last_page > 1 &&
                            <div className='flex justify-between items-center'>
                                <div className='relative'>
                                    {loading &&
                                        <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                        </div>
                                    }
                                    <div className={`flex gap-3 items-center ${loading && 'opacity-40 pointer-events-none'}`}>
                                        <button
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className='h-10 w-10 rounded flex items-center justify-center hover:bg-[#f8f8f8] transition'
                                            style={{ border: '1px solid var(--orange-primary)' }}
                                        >
                                            <img src={arrow2Icon} width={23} />
                                        </button>
                                        <button
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                            className='font-semibold text-[24px] max-lg:text-[20px] h-10 px-3 text-white rounded flex items-center justify-center gap-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] transition'
                                        >
                                            صفحه بعدی
                                            <img src={arrowIcon} />
                                        </button>
                                    </div>
                                </div>
                                <div className='flex gap-[.4rem]'>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>صفحه</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>{currentPage}</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>از</span>
                                    <span className='font-semibold text-[23px] max-lg:text-[20px] text-[#212121]'>{totalPages}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Reading;