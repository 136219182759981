import {FC, ReactNode} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {KTIcon} from '@/metronic-dashboard/helpers/components/KTIcon'
import {checkIsActive} from '@/metronic-dashboard/helpers/RouterHelpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  disable?: boolean
  bigLogo?: boolean
}

interface WithChildren {
  children: ReactNode;
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children = null,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  disable = false,
  bigLogo = false
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <div className={`menu-item ${disable && 'pointer-events-none opacity-50'}`}>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className={`fs-2 ${!bigLogo && 'scale-110'}`} />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title text-[18px]'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}