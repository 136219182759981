import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const IpRedirectWrapper = ({ children }) => {
    const location = useLocation();

    // useEffect(() => {
    //     const checkAndRedirect = async () => {
    //         try {
    //             const ipResponse = await axios.get('https://api.ipify.org?format=json');
    //             const ipAddress = ipResponse.data.ip;

    //             const ipInfoResponse = await axios.get(`https://api.iplocation.net/?ip=${ipAddress}`);
    //             const ipInfo = ipInfoResponse.data;

    //             const currentHostname = window.location.hostname;
    //             const currentPathname = window.location.pathname;

    //             let newTLD;
    //             if (ipInfo.country_code2 === 'IR' && currentHostname.endsWith('.com')) {
    //                 newTLD = 'ir';
    //             } else if (ipInfo.country_code2 !== 'IR' && currentHostname.endsWith('.ir')) {
    //                 newTLD = 'com';
    //             } else {
    //                 newTLD = null;
    //             }

    //             if (newTLD) {
    //                 const newHostname = currentHostname.replace(/\.[a-z]+$/, `.${newTLD}`);
    //                 const targetUrl = `http://${newHostname}${currentPathname}`;
    //                 window.location.replace(targetUrl);
    //             }
    //         } catch (error) {
    //             console.error('Error during IP check and redirection:', error);
    //         }
    //     };

    //     checkAndRedirect();
    // }, [location]);

    return children;
};

export default IpRedirectWrapper;