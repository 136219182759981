import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// import { PasswordMeterComponent } from '@/metronic-dashboard/assets/components/_PasswordMeterComponent';
import axiosInstance from '@/utils/axiosInstance';

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'حداقل 6 کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .matches(/[a-zA-Z]/, 'رمز عبور باید حداقل شامل یک حرف انگلیسی باشد')
        .matches(/\d/, 'رمز عبور باید حداقل شامل یک عدد باشد')
        .required('رمز عبور الزامی است'),
    changepassword: Yup.string()
        .min(6, 'حداقل 6 کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('تکرار رمز عبور الزامی است')
        .oneOf([Yup.ref('password')], 'رمز عبور و تکرار آن مطابقت ندارند'),
});

const resetPassword = async (email, token, new_password, new_password_confirm) => {
    const response = await axiosInstance.post('/reset_password', {
        email,
        token,
        new_password,
        new_password_confirm,
    });
    return response.data;
};

export default function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(undefined);
    const [countdown, setCountdown] = useState(4);
    const [countdownStatus, setCountdownStatus] = useState(false);
    const [countdownMessage, setCountdownMessage] = useState('');

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    const formik = useFormik({
        initialValues: {
            password: '',
            changepassword: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                const response = await resetPassword(email, token, values.password, values.changepassword);
                if (response.status === 1) {
                    setStatus('رمز عبور با موفقیت تغییر کرد!');
                    setCountdownMessage('به صفحه ورود منتقل می‌شوید')
                    setCountdownStatus(true)
                    setTimeout(() => {
                        navigate('/auth/login');
                    }, 3000);
                } else if (response.status === 0 && response.message === "Token not valid") {
                    setStatus('توکن معتبر نیست!');
                    setCountdownMessage('به صفحه بازنویسی رمز عبور منتقل می‌شوید')
                    setCountdownStatus(true)
                    setTimeout(() => {
                        navigate('/auth/forgot-password');
                    }, 3000);
                } else {
                    throw new Error(response.message);
                }
            } catch (error) {
                setStatus('مشکلی پیش آمده است! لطفا بعدا دوباره تلاش کنید.');
                setSubmitting(false);
                setLoading(false);
            }
        },
    });    

    useEffect(() => {
        if (loading && countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [loading, countdown]);

    // useEffect(() => {
    //     PasswordMeterComponent.bootstrap();
    // }, []);


    return (
        <form className='form w-100' noValidate id='kt_reset_password_form' onSubmit={formik.handleSubmit}>
            <div className='text-center mb-6'>
                <h1 className='text-gray-900 fw-bolder text-[30px] mb-3'>بازنویسی رمز عبور</h1>
                <div className='fw-semibold text-white'>رمز عبور جدید خود را وارد کنید.</div>
            </div>

            {status && (
                <div className={`mb-lg-15 alert ${status.includes('موفقیت') ? 'alert-success' : 'alert-danger'}`}>
                    <div className='alert-text font-weight-bold'>{status}</div>
                </div>
            )}

            <div className='fv-row mb-6' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label text-gray-900 font-semibold !text-[21px]'>رمز عبور</label>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                { 'is-invalid': formik.touched.password && formik.errors.password },
                                { 'is-valid': formik.touched.password && !formik.errors.password }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div data-kt-password-meter-control="highlight" className='d-flex gap-3 align-items-center mb-3'>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div> */}
                </div>
                <div className='fs-6'>
                    از ۶ کاراکتر یا بیشتر با ترکیبی از حروف و اعداد استفاده کنید.
                </div>
            </div>

            <div className='fv-row mb-8'>
                <label className='form-label text-gray-900 font-semibold !text-[21px]'>تکرار رمز عبور</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.changepassword && formik.errors.changepassword },
                        { 'is-valid': formik.touched.changepassword && !formik.errors.changepassword }
                    )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_reset_password_submit'
                    className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded font-semibold'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>بازنویسی</span>}
                    {loading && !status && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            لطفاً صبر کنید...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                    {loading && status && countdownStatus && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            {countdownMessage} ({countdown})
                        </span>
                    )}
                </button>
                {/* <Link to="/auth/login" data-bs-theme="light">
                    <button type="button" id="kt_reset_password_cancel_button" className="btn btn-lg btn-light w-100 mb-5 font-semibold">بازگشت</button>
                </Link> */}
            </div>
        </form>
    );
}