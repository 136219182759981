import { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from '@/utils/axiosInstance';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import Alert from '@/components/Alert';
import { convertNumsToFa } from '@/utils/numLangChanger';
import { LoadingScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { isDotIr } from '@/utils/isDotIr'

import img from '@/assets/image/donation.jpg'
import light from '@/assets/image/light6.png'

import bookIcon from '@/assets/svg/book.svg'
import circlesIcon from "@/assets/svg/circles3.svg"
import Bank4 from '@/assets/svg/Bank4.svg'
import Bank6 from '@/assets/svg/Bank6.svg'


const handleNumberInput = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    e.target.value = value;
};

const Donation = ({ canceledOrder = false }) => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    const [loading, setLoading] = useState(true)

    const [searchParams] = useSearchParams()
    const authority = searchParams.get('Authority')
    const status = searchParams.get('Status')
    const paymentId = searchParams.get('paymentId');
    const payerID = searchParams.get('PayerID');
    const token = searchParams.get('token');
    const withPayResult = authority || paymentId

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showDangerModal, setShowDangerModal] = useState(false)
    const [showDangerModal2, setShowDangerModal2] = useState(false)
    const btn = useRef(null)

    useEffect(() => {
        if (canceledOrder) {
            setLoading(false)
            setErrorMessage('پرداخت توسط شما لغو شد.')
        }
    }, [])

    useEffect(() => {
        if (withPayResult) {
            const fetchPaymentResult = async () => {
                try {
                    const response = await axiosInstance.get(authority ? `/donate/payment/result` : `/donate/paypal/execute-payment`, {
                        params: authority ? {
                            Authority: authority,
                            Status: status
                        } : {
                            paymentId: paymentId,
                            PayerID: payerID,
                            token: token
                        }
                    });

                    if (response.data.status === 1) {
                        setShowSuccessModal(true);
                    } else {
                        setShowDangerModal2(true);
                    }
                } catch (error) {
                    setShowDangerModal2(true);
                } finally {
                    setLoading(false)
                }
            };

            fetchPaymentResult();
        }
    }, [withPayResult]);


    const [errorMessage, setErrorMessage] = useState('')

    const [nameField, setNameField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [commentField, setCommentField] = useState('')
    const [remainingChars, setRemainingChars] = useState(200)
    const [paymentType, setPaymentType] = useState('paypal')

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardType, setCardType] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [cvv, setCvv] = useState('');

    const submitForm = async () => {
        if (paymentType === 'credit_card') {
            if (!firstName || !lastName || !cardNumber || !cardType || !expiryMonth || !expiryYear || !cvv) {
                setErrorMessage('لطفاً تمامی فیلدهای کارت اعتباری را پر کنید.');
                return;
            }
        }

        if (!nameField || !emailField) {
            setErrorMessage('لطفا نام و نام خانوادگی و ایمیل خود را وارد نمایید. با تشکر.');
            return;
        }

        setShowSuccessModal(false);
        setShowDangerModal(false);
        btn.current.disabled = true;
        btn.current.innerText = 'لطفا کمی صبر کنید...';

        try {
            const adjustedAmount = `${parseFloat(amount) || 0}`;

            const requestData: { full_name: string; email: string; price_fa?: number; price?: number; payment_method?: string;[key: string]: any } = {
                full_name: nameField,
                email: emailField,
            };

            if (isDotIr) {
                requestData.price_fa = +adjustedAmount;
            } else {
                requestData.price = +adjustedAmount;
                requestData.payment_method = paymentType;

                if (paymentType === 'credit_card') {
                    requestData.first_name = firstName;
                    requestData.last_name = lastName;
                    requestData.card_number = cardNumber;
                    requestData.type = cardType;
                    requestData.expiry_month = expiryMonth;
                    requestData.expiry_year = expiryYear;
                    requestData.cvv = cvv;
                }
            }

            if (commentField.trim()) {
                requestData.message = commentField;
            }

            const response = await axiosInstance.post(isDotIr ? '/donate/create' : '/donate/paypal/create-payment', requestData);

            if (response.data.status === 1) {
                if (paymentType === 'credit_card') {
                    setShowSuccessModal(true);
                    setNameField('')
                    setEmailField('')
                    setCommentField('')
                    setFirstName('')
                    setLastName('')
                    setCardNumber('')
                    setCardType('')
                    setExpiryMonth('')
                    setExpiryYear('')
                    setCvv('')
                    btn.current.disabled = false;
                    btn.current.innerText = 'تایید و پرداخت';
                } else {
                    window.location.href = response.data.url;
                }
            } else {
                setShowDangerModal(true);
            }
        } catch (error) {
            if (paymentType === 'credit_card' && error.response && error.response.data.message === 'Error, please retry') {
                setErrorMessage('لطفا از صحت اطلاعات کارت اعتباری اطمینان حاصل کرده و دوباره تلاش فرمایید.');
                btn.current.disabled = false;
                btn.current.innerText = 'تایید و پرداخت';
                return;
            }

            setShowDangerModal(true);
            btn.current.disabled = false;
            btn.current.innerText = 'تایید و پرداخت';
        }
    };

    const handleTextareaChange = (event) => {
        const charCount = event.target.value.length;
        setRemainingChars(200 - charCount);
        setCommentField(event.target.value);
    };


    const amountInput = useRef(null)
    const initialPrice = isDotIr ? '50000' : '5'
    const [amount, setAmount] = useState(initialPrice);
    const [activeButton, setActiveButton] = useState(initialPrice);
    const [isCustomAmount, setIsCustomAmount] = useState(false);

    const handleButtonClick = (value) => {
        setAmount(value);
        setActiveButton(value);
        setIsCustomAmount(false);
    };

    const handleCustomAmountClick = () => {
        setIsCustomAmount(true);
        setActiveButton(null);
        amountInput.current.focus()
    };

    const handleCustomAmountChange = (event) => {
        setAmount(event.target.value);
        setActiveButton(null);
    };

    useEffect(() => {
        convertNumsToFa();
    }, [amount, activeButton, remainingChars]);

    const handleAlertClose = () => setErrorMessage(null)


    return (
        <div className='bg-white text-[#212121]' onClick={close_Popup}>
            {withPayResult && <LoadingScreen loading={!loading} />}
            {showDangerModal && <Alert danger title='متاسفانه مشکلی پیش آمد.' text='لطفا از اتصال دستگاه خود به اینترنت اطمینان حاصل نمایید و دوباره تلاش کنید.' />}
            {showSuccessModal && <Alert title='تراکنش موفقیت آمیز' text='رسید پرداخت برای شما ایمیل شده است. از حمایت شما متشکریم. 🙏' />}
            {showDangerModal2 && <Alert danger title='خطا در پرداخت' text='لطفا ایمیل خود را چک کنید، اگر رسید پرداخت در قالب ایمیل دریافت کرده‌اید به معنی موفق بودن پرداخت است. در غیر این صورت تراکنش موفق نبوده است و می‌توانید مجدد امتحان کنید و یا به پشتیبانی پیام دهید.' />}

            {errorMessage && <Alert onClose={handleAlertClose} text={errorMessage} danger title='خطا' />}

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 lg:pb-20 overflow-hidden">
                <div
                    style={{ transform: 'skew(0,4deg)', background: 'linear-gradient(180deg, #3E4463, #141726 200%)' }}
                    className='absolute inset-0 mt-[320px] max-lg:mt-[400px] lg:mb-[50px] max-lg:mb-[70px] rounded-[5vw] z-0'
                ></div>

                <div className='my-container relative mb-8'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold text-[28px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            دوست و همراه عزیز
                        </h3>
                    </div>
                    <div className='text-[#212121] !text-[20px] font-semibold max-lg:!text-[20px] max-lg:text-center mb-3'>از اینکه با حمایت مالی من را در ادامه مسیر همراهی میکنی، سپاسگزارم.</div>
                </div>

                <div className="max-lg:pb-36 my-container relative flex gap-10 max-lg:flex-col text-[#e4e4e4] lg:py-20">
                    <div className='max-lg:hidden absolute z-0 left-[-240px] top-0 w-[400px] min-h-[1000px]'>
                        <img src={light} className='mx-auto' />
                        <div className='absolute inset-0 top-[300px] scale-y-[7]' style={{ background: 'radial-gradient(circle, #FFF20008 0%, transparent 40%)' }}></div>
                    </div>

                    <div className='lg:w-1/2 flex items-center justify-center'>
                        <div className='lg:border border-white lg:p-1 rounded-xl'>
                            <img src={img} className='max-lg:p-1 max-lg:bg-white max-w-full rounded max-sm:h-[450px] max-sm:w-full' />
                        </div>
                    </div>

                    <div className='relative lg:w-1/2 lg:min-h-[840px] m-auto flex flex-col gap-5 text-white'>
                        <h3 className='font-bold text-[28px] flex gap-3 -mb-2 self-center mt-2 text-white'>حمایت مالی با {isDotIr ? 'زرین‌پال' : paymentType === 'paypal' ? 'پی‌پل' : 'کارت اعتباری'}</h3>

                        <div className="flex flex-col gap-5 bg-[#444965] rounded-xl px-8 py-5 mt-4 mb-2">
                            <div className="flex justify-between items-center gap-2">
                                <span className='text-[20px]'>مبلغ حمایت مالی:</span>
                                <input
                                    ref={amountInput}
                                    type='number'
                                    value={amount}
                                    readOnly={!isCustomAmount}
                                    onChange={handleCustomAmountChange}
                                    className='keep-english-numbers w-[50%] max-sm:w-[40%] max-w-full text-center text-[20px] font-medium rounded-xl bg-[#67bd5c3a] py-3'
                                    style={{ border: '2px solid #67BD5C', fontFamily: 'Iransans' }}
                                />
                                <span className='text-[20px]'>{isDotIr ? 'تومان' : 'دلار'}</span>
                            </div>

                            <div className="flex justify-between flex-wrap items-center gap-4">
                                <button
                                    className={`grow rounded-xl ${(isDotIr && activeButton === '50000') || (!isDotIr && activeButton === '5') ? 'bg-[#67BD5C] text-white' : 'bg-white text-[#67BD5C]'} py-3 px-6 font-medium text-[16px]`}
                                    style={{ border: '2px solid #67BD5C', fontFamily: 'Iransans' }}
                                    onClick={() => handleButtonClick(isDotIr ? '50000' : '5')}
                                >
                                    {isDotIr ? '50,000' : '5'}
                                </button>
                                <button
                                    className={`grow rounded-xl ${(isDotIr && activeButton === '250000') || (!isDotIr && activeButton === '25') ? 'bg-[#67BD5C] text-white' : 'bg-white text-[#67BD5C]'} py-3 px-6 font-medium text-[16px]`}
                                    style={{ border: '2px solid #67BD5C', fontFamily: 'Iransans' }}
                                    onClick={() => handleButtonClick(isDotIr ? '250000' : '25')}
                                >
                                    {isDotIr ? '250,000' : '25'}
                                </button>
                                <button
                                    className={`grow rounded-xl ${activeButton === null && isCustomAmount ? 'bg-[#67BD5C] text-white' : 'bg-white text-[#67BD5C]'} py-3 px-6 font-medium text-[20px]`}
                                    style={{ border: '2px solid #67BD5C' }}
                                    onClick={handleCustomAmountClick}
                                >
                                    مبلغ دلخواه خود را وارد کنید.
                                </button>
                            </div>
                        </div>

                        {!isDotIr &&
                            <div className={`bg-[#444965] rounded-xl px-8 py-5 mb-3 ${paymentType === 'credit_card' && 'py-7'}`}>
                                <div className="flex items-center justify-center gap-4">
                                    <span className='text-[20px]'>روش پرداخت:</span>
                                    <div
                                        onClick={() => setPaymentType('paypal')}
                                        className={`h-10 px-4 ps-3 rounded bg-white flexCenter cursor-pointer ${paymentType !== 'paypal' && 'opacity-60'}`}
                                    >
                                        <img src={Bank4} />
                                    </div>
                                    <div
                                        onClick={() => setPaymentType('credit_card')}
                                        className={`h-10 px-4 ps-3 rounded bg-white flexCenter cursor-pointer ${paymentType !== 'credit_card' && 'opacity-60'}`}
                                    >
                                        <img src={Bank6} />
                                    </div>
                                </div>

                                {paymentType === 'credit_card' && (
                                    <div className="grid lg:grid-cols-2 gap-y-4 gap-x-5 mt-5">
                                        <div className="flex flex-col">
                                            <label htmlFor="name" className="font-semibold mb-1">نام*</label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                className="bg-[#eee] h-12 px-4 rounded-lg text-[#212121]"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="lastname" className="font-semibold mb-1">نام خانوادگی*</label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                className="bg-[#eee] h-12 px-4 rounded-lg text-[#212121]"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="card_type" className="font-semibold mb-1">نوع کارت*</label>
                                            <div className="bg-[#eee] h-12 px-4 rounded-lg">
                                                <select
                                                    id="card_type"
                                                    value={cardType}
                                                    onChange={(e) => setCardType(e.target.value)}
                                                    className="bg-transparent w-full h-full text-[#212121]"
                                                >
                                                    <option value="">انتخاب کنید...</option>
                                                    <option className="ltr text-[20px] font-medium" value="visa">Visa</option>
                                                    <option className="ltr text-[20px] font-medium" value="mastercard">Mastercard</option>
                                                    <option className="ltr text-[20px] font-medium" value="amex">Amex</option>
                                                    <option className="ltr text-[20px] font-medium" value="discover">Discover</option>
                                                    <option className="ltr text-[20px] font-medium" value="jcb">JCB</option>
                                                    <option className="ltr text-[20px] font-medium" value="dinersclub">Diners Club</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="cardnum" className="font-semibold mb-1">شماره کارت*</label>
                                            <input
                                                type="text"
                                                id="cardnum"
                                                value={cardNumber}
                                                onChange={(e) => setCardNumber(e.target.value)}
                                                onInput={handleNumberInput}
                                                maxLength={19}
                                                className="bg-[#eee] h-12 px-4 rounded-lg ltr text-[#212121]"
                                            />
                                        </div>
                                        <div className="grid gap-4 lg:col-span-2 lg:grid-cols-2 max-lg:grid-cols-3">
                                            <div className="flex flex-col max-lg:col-span-2">
                                                <label htmlFor="carddate" className="font-semibold mb-1">تاریخ انقضا*</label>
                                                <div className="flex gap-4">
                                                    <input
                                                        type="text"
                                                        id="carddate"
                                                        value={expiryMonth}
                                                        onChange={(e) => setExpiryMonth(e.target.value)}
                                                        onInput={handleNumberInput}
                                                        placeholder="MM"
                                                        maxLength={2}
                                                        className="bg-[#eee] h-12 text-center rounded-lg w-[calc(50%-8px)] text-[#212121]"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={expiryYear}
                                                        onChange={(e) => setExpiryYear(e.target.value)}
                                                        onInput={handleNumberInput}
                                                        placeholder="YYYY"
                                                        maxLength={4}
                                                        className="bg-[#eee] h-12 text-center rounded-lg w-[calc(50%-8px)] text-[#212121]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <label htmlFor="cvv" className="font-semibold mb-1">CVV*</label>
                                                <input
                                                    type="text"
                                                    id="cvv"
                                                    value={cvv}
                                                    onChange={(e) => setCvv(e.target.value)}
                                                    onInput={handleNumberInput}
                                                    maxLength={4}
                                                    className="bg-[#eee] h-12 text-center rounded-lg text-[#212121]"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }

                        <div className='w-full flex items-center -mt-2'>
                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                            <img src={circlesIcon} />
                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                        </div>

                        <h3 className='font-bold text-[28px] flex gap-3 -mb-2 self-center mt-2'>مشخصات حامی مالی</h3>

                        <div className="flex flex-col gap-3">
                            <div className='w-full lg:mt-5 max-lg:mt-2'>
                                <div className="grid gap-y-6 -mt-3">
                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed1" className='!text-[20px] font-medium'>نام و نام خانوادگی*</label>
                                        <input
                                            id='filed1'
                                            type="text"
                                            className='bg-[#eee] rounded-xl p-4 px-5 text-[19px] text-[#212121]'
                                            onChange={e => setNameField(e.target.value)}
                                            value={nameField}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed2" className='!text-[20px] font-medium'>آدرس ایمیل*</label>
                                        <input
                                            id='filed2'
                                            type="email"
                                            className='bg-[#eee] rounded-xl p-4 px-5 text-[19px] text-[#212121]'
                                            onChange={e => setEmailField(e.target.value)}
                                            value={emailField}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-3'>
                                        <label htmlFor="filed5" className='!text-[20px] font-medium'>پیام شما</label>
                                        <textarea
                                            id='filed5'
                                            rows={3}
                                            maxLength={200}
                                            style={{ resize: 'none' }}
                                            placeholder='اینجا بنویسید...'
                                            className='outline-none bg-[#eee] rounded-xl p-4 px-5 text-[19px] leading-6 text-[#212121]'
                                            onChange={handleTextareaChange}
                                            value={commentField}
                                        />
                                        <span className='text-[#9E9E9E] text-[17px] -mt-1'>
                                            {remainingChars >= 50 ? 'حداکثر 200 کاراکتر' : `کاراکتر باقیمانده: ${remainingChars}`}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center mt-4 max-md:flex-col">
                                    <h3 className='font-bold text-[24px] flex gap-3 -mb-2'>مجموع قابل پرداخت: {amount + (!isDotIr ? '$' : '')} {isDotIr && 'تومان'}</h3>
                                    <button ref={btn} onClick={submitForm} className='float-start max-md:w-full max-md:mt-5 font-semibold text-[19px] max-lg:text-[19px] h-12 px-16 text-white rounded bg-[--green-primary] hover:bg-[--green-primary-hover] transition'>تائید و پرداخت</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Donation;