import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { KTIcon } from '@/metronic-dashboard/helpers/components/KTIcon'

type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};

const getSystemMode = (): 'light' | 'dark' => {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

const ThemeModeSwitcher = ({
  toggleBtnClass = 'btn btn-icon btn-sm btn-icon-muted btn-active-icon-primary',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const [mode, setMode] = useState<'light' | 'dark' | 'system'>('system');

  const applyTheme = (theme: 'light' | 'dark') => {
    document.documentElement.setAttribute('data-bs-theme', theme);
    localStorage.setItem('theme', theme);
  };

  const switchMode = (newMode: 'light' | 'dark' | 'system') => {
    if (newMode === 'system') {
      const systemMode = getSystemMode();
      applyTheme(systemMode);
    } else {
      applyTheme(newMode);
    }
    setMode(newMode);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark' | null;
    if (savedTheme) {
      setMode(savedTheme);
      applyTheme(savedTheme);
    } else {
      // setMode('system');
      // applyTheme(getSystemMode());
      setMode('dark'); // default mode
      applyTheme('dark'); // default mode
    }
  }, []);

  const calculatedMode = mode === 'system' ? getSystemMode() : mode;

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {/* Menu toggle */}
      <button
        className={clsx('btn btn-icon', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        {calculatedMode === 'dark' && (
          <KTIcon iconName='moon' className={clsx('theme-light-hide', toggleBtnIconClass)} />
        )}

        {calculatedMode === 'light' && (
          <KTIcon iconName='night-day' className={clsx('theme-dark-hide', toggleBtnIconClass)} />
        )}
      </button>
      {/* Menu */}

      <div
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px  z-[105] fixed translate-x-[140px] ${isHovered ? 'show' : ''}`}
        data-kt-menu='true'
      >
        {/* Menu item */}
        <div className='menu-item px-3 my-0'>
          <button
            className={clsx('menu-link px-3 py-2  w-full', { active: mode === 'light' })}
            onClick={() => {
              switchMode('light')
              setIsHovered(false)
            }}
          >
            <span className='menu-icon'>
              <KTIcon iconName='night-day' className='fs-1' />
            </span>
            <span className='menu-title'>روز</span>
          </button>
        </div>
        {/* Menu item */}

        {/* Menu item */}
        <div className='menu-item px-3 my-0'>
          <button
            className={clsx('menu-link px-3 py-2  w-full', { active: mode === 'dark' })}
            onClick={() => {
              switchMode('dark')
              setIsHovered(false)
            }}
          >
            <span className='menu-icon'>
              <KTIcon iconName='moon' className='fs-1' />
            </span>
            <span className='menu-title'>شب</span>
          </button>
        </div>
        {/* Menu item */}

        {/* Menu item */}
        <div className='menu-item px-3 my-0'>
          <button
            className={clsx('menu-link px-3 py-2  w-full', { active: mode === 'system' })}
            onClick={() => {
              switchMode('system')
              setIsHovered(false)
            }}
          >
            <span className='menu-icon'>
              <KTIcon iconName='screen' className='fs-1' />
            </span>
            <span className='menu-title'>سیستم</span>
          </button>
        </div>
        {/* Menu item */}
      </div>
      {/* Menu */}
    </div>
  );
};

export { ThemeModeSwitcher };