export function convertEnglishNumbersToPersian(str) {
    const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    return str.replace(/\d/g, function (digit) {
        return persianNumbers[digit];
    });
}

export function convertPersianNumbersToEnglish(str) {
    const persianToEnglishMap = {
        '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4',
        '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9'
    };
    return str.replace(/[\u06F0-\u06F9]/g, function (digit) {
        return persianToEnglishMap[digit] || digit;
    });
}


export function traverseAndConvert(node) {
    if (node.nodeType === 3) { // Text node
        if (!node.parentElement.classList.contains('keep-english-numbers')) {
            node.nodeValue = convertEnglishNumbersToPersian(node.nodeValue);
        }
    } else if (node.nodeType === 1) { // Element node
        node.childNodes.forEach(child => traverseAndConvert(child));
    }
}

export function convertNumsToFa() {
    document.body.childNodes.forEach(child => traverseAndConvert(child));
}