import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import axiosInstance from '@/utils/axiosInstance';
import { isDotIr } from '@/utils/isDotIr';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { convertPersianNumbersToEnglish } from '@/utils/numLangChanger';

const registrationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'حداقل ۲ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام الزامی است.'),
    family: Yup.string()
        .min(2, 'حداقل ۲ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام خانوادگی الزامی است.'),
    email: Yup.string()
        .email('فرمت ایمیل نادرست است')
        .min(3, 'حداقل ۳ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن ایمیل الزامی است.'),
    mobile: Yup.string()
        .required('وارد کردن شماره موبایل الزامی است.'),
    password: Yup.string()
        .min(6, 'حداقل 6 کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن رمز عبور الزامی است.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'رمز عبور با تکرار آن مطابقت ندارد.')
        .required('تکرار رمز عبور الزامی است.'),
    acceptTerms: Yup.boolean().oneOf([true], 'باید شرایط و ضوابط را بپذیرید.'),
});

const otpSchema = Yup.object().shape({
    otp: Yup.string()
        .length(6, 'کد تایید باید ۶ رقم باشد')
        .required('وارد کردن کد تایید الزامی است')
});

const Registration = () => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(undefined);
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const navigate = useNavigate();

    // Improved token management functions
    const saveTempToken = (authData) => {
        try {
            if (authData?.access_token) {
                // Store temporary token in localStorage
                localStorage.setItem('temp_token', authData.access_token);
                // Store full auth data separately
                localStorage.setItem('temp_auth_data', JSON.stringify(authData));

                // Set temporary cookie with short expiration
                Cookies.set(
                    isDotIr ? 'innoghteh_temp_token' : 'innoghteh_temp_token_com',
                    authData.access_token,
                    { expires: 1 / 24 } // 1 hour expiration
                );
            }
        } catch (error) {
            console.error('Error saving temporary token:', error);
        }
    };

    const activateToken = () => {
        try {
            // Get stored temporary data
            const tempToken = localStorage.getItem('temp_token');
            const tempAuthData = localStorage.getItem('temp_auth_data');

            if (tempToken && tempAuthData) {
                // Parse the stored auth data
                const authData = JSON.parse(tempAuthData);

                // Set the permanent token cookie
                Cookies.set(
                    isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com',
                    tempToken,
                    { expires: 1 } // 1 day expiration for permanent token
                );

                // Set permanent auth data
                localStorage.setItem('auth', tempAuthData);

                // Clean up temporary storage
                localStorage.removeItem('temp_token');
                localStorage.removeItem('temp_auth_data');
                Cookies.remove(isDotIr ? 'innoghteh_temp_token' : 'innoghteh_temp_token_com');

                return true;
            }
            return false;
        } catch (error) {
            console.error('Error activating token:', error);
            return false;
        }
    };

    const otpFormik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: otpSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                const response = await axiosInstance.post('/checkotp', {
                    identifier: identifier,
                    otp: values.otp,
                });

                if (response.data.success) {
                    // Only activate the token after successful OTP verification
                    if (activateToken()) {
                        navigate('/dashboard');
                    } else {
                        setStatus('خطا در فعال‌سازی حساب کاربری. لطفا دوباره تلاش کنید.');
                    }
                } else {
                    setStatus('کد تایید نادرست است. لطفا دوباره تلاش کنید.');
                }
            } catch (error) {
                setStatus('خطا در تایید کد. لطفا دوباره تلاش کنید.');
            } finally {
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            family: '',
            email: '',
            mobile: '',
            country_code: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false,
        },
        validationSchema: registrationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                let mobileNumber = convertPersianNumbersToEnglish(values.mobile);

                if (!isDotIr) {
                    mobileNumber = '00' + mobileNumber;
                }

                const response = await axiosInstance.post('/register', {
                    name: values.name,
                    family: values.family,
                    email: values.email,
                    mobile: mobileNumber,
                    country_code: isDotIr ? '+98' : values.country_code,
                    password: values.password,
                    ref_code: '',
                });

                // Save temporary token after successful registration
                saveTempToken(response.data);
                setIdentifier(values.email);
                setShowOtpForm(true);
                setStatus(undefined);
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    setStatus('با این ایمیل یا شماره موبایل قبلا ثبت‌نام صورت گرفته است.');
                } else {
                    setStatus('مشکلی پیش آمده است! لطفا بعدا دوباره تلاش کنید.');
                }
                window.scrollTo(0, 0);
            } finally {
                setSubmitting(false);
                setLoading(false);
            }
        },
    });



    if (showOtpForm) {
        return (
            <form
                className='form w-100'
                onSubmit={otpFormik.handleSubmit}
                noValidate
            >
                <div className='text-center mb-5'>
                    <h1 className='text-gray-900 fw-bolder mb-3 text-[30px]'>تایید کد</h1>
                    <div className='fw-semibold text-[19.5px] text-center text-white'>
                        کد تایید ارسال شده را وارد کنید
                    </div>
                </div>

                {status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{status}</div>
                    </div>
                )}

                <div className='fv-row mb-8'>
                    <input
                        type='text'
                        placeholder='کد تایید'
                        {...otpFormik.getFieldProps('otp')}
                        className={clsx(
                            'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg text-center',
                            {
                                'is-invalid': otpFormik.touched.otp && otpFormik.errors.otp,
                            },
                            {
                                'is-valid': otpFormik.touched.otp && !otpFormik.errors.otp,
                            }
                        )}
                    />
                    {otpFormik.touched.otp && otpFormik.errors.otp && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{otpFormik.errors.otp}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='d-grid mb-8'>
                    <button
                        type='submit'
                        className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded-lg font-semibold h-[51px]'
                        disabled={loading}
                    >
                        {!loading && <span className='indicator-label'>تایید</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                لطفاً صبر کنید...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
        );
    }
    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signup_form'
        >
            <div className='text-center mb-3'>
                <h1 className='text-gray-900 fw-bolder mb-3 text-[30px]'>ثبت‌نام در سایت</h1>
                <div className='fw-semibold text-[19.5px] text-center !text-white'>
                    با ثبت‌نام در سایت از تمامی امکانات سایت بهره‌مند شوید.
                </div>
            </div>

            {status && (
                <div className='my-lg-5 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{status}</div>
                </div>
            )}

            <div className='fv-row mb-5'>
                <label htmlFor="name-input" className='form-label font-semibold !text-[21px] text-gray-900'>نام</label>
                <input
                    id="name-input"
                    {...formik.getFieldProps('name')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.name && formik.errors.name },
                        { 'is-valid': formik.touched.name && !formik.errors.name }
                    )}
                    type='text'
                    name='name'
                    autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.name}</span>
                    </div>
                )}
            </div>

            {/* Family Field */}
            <div className='fv-row mb-5'>
                <label htmlFor="family-input" className='form-label font-semibold !text-[21px] text-gray-900'>نام خانوادگی</label>
                <input
                    id="family-input"
                    {...formik.getFieldProps('family')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.family && formik.errors.family },
                        { 'is-valid': formik.touched.family && !formik.errors.family }
                    )}
                    type='text'
                    name='family'
                    autoComplete='off'
                />
                {formik.touched.family && formik.errors.family && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.family}</span>
                    </div>
                )}
            </div>

            {/* Email Field */}
            <div className='fv-row mb-5'>
                <label htmlFor="email-input" className='form-label font-semibold !text-[21px] text-gray-900'>آدرس ایمیل</label>
                <input
                    id="email-input"
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        { 'is-valid': formik.touched.email && !formik.errors.email }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.email}</span>
                    </div>
                )}
            </div>

            {/* Mobile Field */}
            <div className='fv-row mb-5'>

                <label htmlFor="mobile-input" className='form-label font-semibold !text-[21px] text-gray-900'>شماره موبایل
                </label>
                <div className='ltr'>{isDotIr ? " +98 912 345 67 XX" : ""
                }</div>
                {isDotIr ?

                    <PhoneInput
                        id="mobile-input"
                        country='ir'
                        value={formik.values.mobile}
                        className='ltr'
                        onChange={(value, country) => {
                            formik.setFieldValue('mobile', value)
                            formik.setFieldValue('country_code', '00' + country.dialCode)
                        }}
                        inputClass={clsx(
                            'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg !w-full !h-[51px] !border-none',
                            { 'is-invalid': formik.touched.mobile && formik.errors.mobile },
                            { 'is-valid': formik.touched.mobile && !formik.errors.mobile }
                        )}
                        inputProps={{
                            name: 'mobile',
                            autoComplete: 'off',
                            placeholder: ''
                        }}
                    />
                    :
                    <PhoneInput
                        id="mobile-input"
                        country='us'
                        value={formik.values.mobile}
                        className='ltr'
                        onChange={(value, country) => {
                            formik.setFieldValue('mobile', value)
                            formik.setFieldValue('country_code', '00' + country.dialCode)
                        }}
                        inputClass={clsx(
                            'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg !w-full !h-[51px] !border-none',
                            { 'is-invalid': formik.touched.mobile && formik.errors.mobile },
                            { 'is-valid': formik.touched.mobile && !formik.errors.mobile }
                        )}
                        inputProps={{
                            name: 'mobile',
                            autoComplete: 'off',
                            placeholder: ''
                        }}
                    />
                }
                {formik.touched.mobile && formik.errors.mobile && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.mobile}</span>
                    </div>
                )}
            </div>

            {/* Password Field */}
            <div className='fv-row mb-6'>
                <label htmlFor="pass-input" className='form-label text-gray-900 font-semibold !text-[21px]'>رمز عبور</label>
                <input
                    id='pass-input'
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.password && formik.errors.password },
                        { 'is-valid': formik.touched.password && !formik.errors.password }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* Confirm Password Field */}
            <div className='fv-row mb-6'>
                <label htmlFor="confirm-pass-input" className='form-label text-gray-900 font-semibold !text-[21px]'>تکرار رمز عبور</label>
                <input
                    id='confirm-pass-input'
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirmPassword')}
                    className={clsx(
                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                        { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                        { 'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword }
                    )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* Accept Terms Field */}
            <div className='fs-base fw-semibold mb-4'>
                <label className='form-check form-check-custom form-check-solid'>
                    <input
                        className='form-check-input scale-75 !bg-transparent border border-white'
                        type='checkbox'
                        {...formik.getFieldProps('acceptTerms')}
                        id='acceptTerms'
                    />
                    <span className='form-check-label fw-semibold text-gray-900 text-[19px] translate-x-1' htmlFor='acceptTerms'>
                        <Link to='/terms' className='text-white'>شرایط و ضوابط</Link> را می‌پذیرم.
                    </span>
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.acceptTerms}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* Submit Button */}
            <div className='d-grid mb-1'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded-lg font-semibold h-[51px]'
                >
                    {!loading && <span className='indicator-label'>ثبت‌نام</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            لطفاً صبر کنید...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>

            <div className='text-gray-900 fw-semibold text-[19px] mt-4 -mb-2'>
                قبلا ثبت‌نام کرده‌اید؟{' '}
                <Link to='/auth/login' className='link-primary text-[19px]'>
                    ورود
                </Link>
            </div>
        </form>
    );
};

export default Registration;