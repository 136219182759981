import { useEffect, useState, useRef, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-jalaali';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import CommentsSection from '@/components/CommentsSection';
import axiosInstance from '@/utils/axiosInstance';
import { LoadingScreen, ErrorScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger'
import { CartContext } from '@/context/CartContext';
import { isDotIr } from '@/utils/isDotIr'
import Cookies from 'js-cookie'

import lightImg from '@/assets/image/light.png'

import workshopIcon from '@/assets/svg/workshop.svg'
import diamondIcon from '@/assets/svg/diamond.svg'
import starIcon from '@/assets/svg/Star 3.svg'
import chatIcon from '@/assets/svg/Chat 2.svg'
import videoIcon from '@/assets/svg/Video.svg'
import docIcon from '@/assets/svg/doc.svg'
import priceIcon from '@/assets/svg/price.svg'
import arrowIcon from '@/assets/svg/Arrow - Left.svg'
import basketIcon from '@/assets/svg/Buy-white.svg'
import lineDecorationGoldIcon from "@/assets/svg/line-decoration1-gold.svg"
import circlesIcon from "@/assets/svg/circles.svg"
import excludeIcon from "@/assets/svg/Exclude.svg"
import questionCircleIcon from "@/assets/svg/Question Circle.svg"
import dangerCircleIcon from "@/assets/svg/Danger Circle.svg"
import doc2Icon from "@/assets/svg/Doc2.svg"
import timeCircleIcon from "@/assets/svg/Time Circle.svg"
import calendarIcon from "@/assets/svg/Calendar.svg"


declare var tippy: any;

// Helper functions
const decodeHtmlEntities = (str) => {
    if (!str) return '';
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
};

const splitText = (text: string) => {
    if (!text) return ['', ''];
    const decodedText = decodeHtmlEntities(text);
    return decodedText.split(/<v>/);
};

const convertToJalali = (dateString: string) => {
    if (!dateString) return '';
    try {
        return moment(dateString).format('jYYYY/jMM/jDD');
    } catch (error) {
        console.error('Error converting date to Jalali:', error);
        return dateString;
    }
};

const OnTheLineView = () => {
    // State Management
    const [error, setError] = useState(false);
    const [noComment, setNoComment] = useState(false);
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [liveData, setLiveData] = useState(null);
    const [purchasedProducts, setPurchasedProducts] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const [commentCount, setCommentCount] = useState(0);
    const [desFromTop, setDesFromTop] = useState(0);

    // Refs
    const tooltipRef = useRef(null);
    const tooltipRef2 = useRef(null);
    const tooltipRef3 = useRef(null);
    const des1 = useRef(null);
    const des2 = useRef(null);
    const des3 = useRef(null);
    const theEl = useRef(null);

    // Route and Context
    const { id } = useParams();
    const { cart, addToCart } = useContext(CartContext);
    const isInCart = cart.some(cartItem => cartItem.id === +id);
    const isLogin = Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com');

    // Computed Values
    const startDateTime = liveData?.package === 0 && new Date(`${liveData.live_detail?.start_date}T${"00.00.00"}`);
    const now = new Date();
    const isPast = startDateTime ? startDateTime < now : null;
    const pageType = liveData && liveData.package === 1 ? 3 : isPast ? 2 : 1;
    const bgColor = liveData?.color === 'purple' ? '#111123' : liveData?.color === 'green1' ? '#11231F' : '#001605';
    const startDate = liveData?.live_detail?.start_date;
    const jalaliDate = startDate ? convertToJalali(startDate) : '';

    // Event Handlers
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) {
            setNav_DropDown(false);
        }
    };

    const handleCommentCountChange = (count) => {
        setCommentCount(count);
    };

    const checkIfPurchased = product => {
        if (!product) return false;
        return purchasedProducts?.find(i => i === product.id);
    };

    const scrollHandler = (destination) => {
        if (destination.current) {
            const { top } = destination.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + top - 80,
                behavior: 'smooth'
            });
        }
    };

    const handleDistanceFromTop = () => {
        if (theEl.current) {
            const rect = theEl.current.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setDesFromTop(rect.top + scrollTop);
        }
    };

    // API Calls
    const fetchData = async () => {
        try {
            const [liveResponse, purchasedResponse] = await Promise.all([
                axiosInstance.get('/website/live_show/' + id),
                isLogin ? axiosInstance.get('/order/purchased', {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get(isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com')}`,
                    },
                }) : Promise.resolve({ data: { status: 0 } })
            ]);

            const live = liveResponse.data.items[0];

            if (purchasedResponse.data.status === 1) {
                const { purchased_orders, purchased_packages } = purchasedResponse.data;

                const isPurchasedInIr = purchased_orders.ir.includes(live.id) || purchased_packages.ir.includes(live.id);
                const isPurchasedInCom = purchased_orders.com.includes(live.id) || purchased_packages.com.includes(live.id);

                setLiveData({
                    ...live,
                    isPurchasedInIr,
                    isPurchasedInCom,
                });

                // Set purchased products
                if (isDotIr) {
                    setPurchasedProducts([
                        ...purchased_orders.ir,
                        ...purchased_packages.ir,
                        ...purchased_orders.com,
                        ...purchased_packages.com
                    ]);
                } else {
                    setPurchasedProducts(purchased_orders.com);
                }
            } else {
                setLiveData(live);
            }
        } catch (error) {
            console.error('Error fetching live data:', error);
            setError(true);
        }
    };

    // Effects
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
            handleDistanceFromTop();
        };

        window.addEventListener('resize', handleResize);

        fetchData();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [id]);

    useEffect(() => {
        convertNumsToFa();
    }, [liveData, showMore]);

    useEffect(() => {
        handleDistanceFromTop();
    }, [liveData]);

    // Initialize tooltips
    useEffect(() => {
        const tooltipContent = "شما این محصول را خریداری کرده‌اید اما فقط با آی‌پی ایران قابل مشاهده است. می‌توانید برای رفتن به بخش نمایش جلسه VPN خود را خاموش و صفحه را رفرش کنید.";

        [tooltipRef, tooltipRef2, tooltipRef3].forEach(ref => {
            if (ref.current) {
                tippy(ref.current, { content: tooltipContent });
            }
        });
    }, [liveData]);

    if (error) return <ErrorScreen />;


    return (
        <div className='bg-white' onClick={close_Popup}>
            <LoadingScreen loading={!liveData} />

            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden">
                <div
                    style={{
                        transform: 'skew(0,4deg)',
                        backgroundColor: bgColor,
                        '--des-from-top': `${desFromTop}px`
                    } as React.CSSProperties}
                    className='bg-box-color absolute inset-0 lg:mt-[330px] mb-[250px] max-lg:mb-[130px rounded-[5vw] z-0'
                ></div>

                <div className='my-container'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-4'>
                            <img src={workshopIcon} width={30} className='max-lg:hidden' />
                            {liveData?.title_fa}
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold  max-lg:text-[20px] max-lg:text-center mb-5'>
                        {liveData?.short_info}
                    </div>
                    <div className='relative z-[2] flex lg:gap-16 max-lg:flex-col max-lg:mt-5 max-w-full items-end'>
                        <div ref={theEl} className='mt-auto mx-auto max-w-full'>
                            <div className='rounded-xl p-1 bg-white w-[365px] max-w-full relative' style={{ border: '1px solid #E0E0E0' }}>
                                <img src={liveData?.image_media[0].src} className='rounded w-full object-cover h-[440px]' />
                                {commentCount !== 0 &&
                                    <span className='lg:hidden text-[#1A96F0] text-[18px] font-medium absolute top-5 left-5 p-[.3rem] px-3 rounded-[.3rem] bg-white/50'>
                                        {commentCount} نظر
                                    </span>
                                }
                            </div>
                        </div>
                        <div className='grow lg:mt-10 max-lg:text-center mx-auto'>
                            <div className='flex justify-between items-center'>
                                <div></div>
                                {commentCount !== 0 &&
                                    <span className='text-[#1A96F0] text-[18px] font-medium max-lg:hidden'>
                                        {commentCount} نظر
                                    </span>
                                }
                            </div>
                            <div className='flex items-start gap-2 font-semibold lg:mt-7 max-lg:flex-col max-lg:items-center'>
                                <img src={diamondIcon} className='w-[20px] max-lg:w-[32px] max-lg:mt-6 lg:mt-2' />
                                <p
                                    className='text-justify max-lg:text-[22px] max-lg:text-center'
                                    dangerouslySetInnerHTML={{ __html: liveData?.full_info }}
                                />
                            </div>
                            <div className='mt-6 flex flex-wrap gap-y-1 gap-x-6 justify-between max-lg:flex-col max-lg:items-center'>
                                {pageType === 2 && <>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>تاریخ:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {jalaliDate}
                                        </span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>ساعت:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {liveData?.live_detail.start_time} به وقت تهران
                                        </span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {/* {liveData?.access_type} */}
                                            'نرم افزار زوم'
                                        </span></p>
                                    </div>
                                </>}
                                {pageType === 2 && <>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>پیش نیاز:
                                            <Link to={splitText(liveData?.requirements)[1]} className='text-[#F1C31B] mr-2 text-[21px] font-bold'>
                                                {splitText(liveData?.requirements)[0]}
                                            </Link>
                                        </p>
                                    </div>
                                    {/* <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            'نرم افزار زوم'
                                        </span></p>
                                    </div> */}
                                    {/* <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>قابلیت دانلود:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {liveData?.downloadable === 0 ? 'ندارد' : 'دارد'}
                                        </span></p>
                                    </div> */}
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>تعداد دستگاه‌های مجاز:<span className='text-[#F1C31B] mr-2 text-[21px]'>دو دستگاه</span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>محدودیت دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {isDotIr ? 'جهت استفاده در کشور ایران' : ' ندارد'}
                                        </span></p>
                                    </div>
                                </>}
                            </div>


                            <div className='mt-6 flex flex-wrap gap-y-1 gap-x-6 justify-between max-lg:flex-col max-lg:items-center'>
                                {pageType === 1 && <>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>تاریخ:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {jalaliDate}
                                        </span></p>
                                    </div>

                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {/* {liveData?.access_type} */}
                                            اشتراک آنلاین و دائمی
                                        </span></p>
                                    </div>
                                </>}
                                {pageType === 1 && <>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>پیش نیاز:
                                            <Link to={splitText(liveData?.requirements)[1]} className='text-[#F1C31B] mr-2 text-[21px] font-bold'>
                                                {splitText(liveData?.requirements)[0]}
                                            </Link>
                                        </p>
                                    </div>
                                    {/* <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>نحوه دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            'نرم افزار زوم'
                                        </span></p>
                                    </div> */}
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>قابلیت دانلود:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {liveData?.downloadable === 0 ? 'ندارد' : 'دارد'}
                                        </span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>تعداد دستگاه‌های مجاز:<span className='text-[#F1C31B] mr-2 text-[21px]'>دو دستگاه</span></p>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <img src={starIcon} width={20} />
                                        <p className='font-semibold'>محدودیت دسترسی:<span className='text-[#F1C31B] mr-2 text-[21px]'>
                                            {isDotIr ? 'جهت استفاده در کشور ایران' : ' ندارد'}
                                        </span></p>
                                    </div>
                                </>}
                            </div>

                            <div className='flex gap-2 items-center rounded bg-[#00a86a1e] p-[.6rem] px-4 my-5 max-lg:items-start max-lg:mx-auto'>
                                <img src={excludeIcon} className='max-lg:mt-1' />
                                <p className='text-[18px] font-medium text-[#12D18E] text-right'>پرسش و پاسخ در جلسات روی خط، تنها محدود به زمان برگزاری جلسات می‌باشد.</p>
                            </div>
                            <div className='flex justify-between max-lg:justify-center flex-wrap items-center gap-3 max-lg:mt-8'>
                                <button onClick={() => scrollHandler(des1)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                    <img src={docIcon} className='max-lg:hidden' width={20} />
                                    <p className='font-semibold max-lg:font-medium text-[19px] max-lg:text-[18px]'>
                                        {pageType === 3 ? 'جلسات برگزار شده' : 'سوالات مطرح شده'}
                                    </p>
                                </button>
                                <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                <button onClick={() => scrollHandler(des2)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                    <img src={videoIcon} className='max-lg:hidden' width={21} />
                                    <p className='font-semibold max-lg:font-medium text-[19px] max-lg:text-[18px]'>
                                        {pageType === 3 ? 'دموی روی خط' : 'دموی جلسه'}
                                    </p>
                                </button>
                                <div className='h-[1px] grow bg-[#eee] max-lg:hidden'></div>
                                <button onClick={() => scrollHandler(des3)} className='noncolor flex gap-2 items-center max-lg:grow justify-center border p-1 rounded px-5 border-white hover:bg-black/10 transition'>
                                    <img src={chatIcon} className='max-lg:hidden' width={21} />
                                    <p className='font-semibold max-lg:font-medium text-[19px] max-lg:text-[18px]'>نظر شرکت کنندگان</p>
                                </button>
                            </div>
                            <div className="flex justify-between items-center mt-8 max-lg:hidden">
                                <div className='flex gap-2 items-center'>
                                    <img src={priceIcon} width={20} />
                                    <p>قیمت دوره: </p>
                                    {liveData?.discount_price && liveData?.discount_price !== liveData?.price ?
                                        <>
                                            <div className={`rounded px-2 text-[23px] font-bold bg-[#F1C31B] text-black ${!isDotIr && 'keep-english-numbers'}`}>
                                                {isDotIr ? (liveData?.discount_price / 10).toLocaleString() : '$' + liveData?.discount_price.toLocaleString()} {' '}
                                                <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                            </div>
                                            <p className={`line-through ${!isDotIr && 'keep-english-numbers'}`}>
                                                {isDotIr ? (liveData?.price / 10).toLocaleString() : liveData?.price.toLocaleString()}
                                            </p>
                                        </>
                                        :
                                        <p className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                            {isDotIr ? (liveData?.price / 10).toLocaleString() : '$' + liveData?.price.toLocaleString()} {' '}
                                            <span className='text-[21px] font-medium'>{isDotIr && ' تومان'}</span>
                                        </p>
                                    }
                                </div>
                                {liveData?.remain_capacity === 0 ?
                                    <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-2'>
                                        <p className='font-semibold text-[19px]'>ظرفیت تکمیل شده است.</p>
                                    </button>
                                    : checkIfPurchased(liveData) ?
                                        <Link to={`/dashboard/products/rooyekhat/live/${liveData.id}`} className='bg-[--orange-primary] text-white hover:bg-[--orange-primary-hover] transition rounded px-4 p-2'>بخش نمایش جلسه</Link>
                                        :
                                        <button onClick={() => addToCart(liveData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-2 relative'>
                                            <img src={basketIcon} width={20} />
                                            <p className='font-semibold text-[19px]'>
                                                {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                            </p>
                                            <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                            {!isDotIr && liveData?.isPurchasedInIr && !liveData?.isPurchasedInCom &&
                                                <div ref={tooltipRef} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                        <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                    </svg>
                                                </div>
                                            }
                                        </button>
                                }
                            </div>
                        </div>
                    </div>

                    <div ref={des1} className='w-full relative flex flex-col gap-5 items-center lg:pt-20 max-lg:pt-14'>
                        {pageType === 3 ?
                            <>
                                <img src={lightImg} className='absolute left-[-134px] top-[-100px] max-lg:hidden' />
                                <img src={lightImg} className='absolute right-[-134px] bottom-[-350px] max-lg:hidden' />
                            </>
                            :
                            <>
                                <img src={lineDecorationGoldIcon} className='absolute left-[-134px] top-[-100px] max-lg:hidden' />
                                <img src={lineDecorationGoldIcon} className='absolute right-[-134px] bottom-[-300px] max-lg:hidden' />
                            </>
                        }
                        <h3 className='text-center max-lg:px-[10vw]'>
                            {pageType === 2 && 'اطلاعات تکمیلی'}
                            {pageType === 1 && 'برخی از سوالات مطرح شده در ' + liveData?.title_fa}
                            {pageType === 3 && 'محتوای ' + liveData?.title_fa}
                        </h3>
                        <div className='lg:px-24 w-full flex items-center max-lg:-mt-3'>
                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                            <img src={circlesIcon} className='mt-[1px]' />
                            <div className='grow h-[1px] bg-[#E0E0E0]'></div>
                        </div>

                        {pageType === 1 &&
                            <div className='grid grid-cols-2 items-start max-lg:grid-cols-1 lg:gap-x-14 mt-2 max-lg:gap-[1px]'>
                                {liveData?.live_detail?.questions_and_answers && Object.entries(liveData.live_detail.questions_and_answers as Record<string, string>).map(([questionId, answer], i) => (
                                    <div
                                        key={i}
                                        className={`flex flex-col gap-2 py-3 border-t border-[#eee2] first:border-none ${i === 1 && 'lg:border-none'}`}
                                    >
                                        <div className="flex gap-2">
                                            <img src={dangerCircleIcon} alt="Warning Icon" />
                                            <p className='font-semibold text-[20px]'>{questionId}</p>
                                        </div>
                                        <p className='font-normal text-[18px] text-justify'>{answer}</p>
                                    </div>
                                ))}
                            </div>
                        }

                        {pageType === 2 && (() => {
                            const questions = liveData?.live_detail?.questions_and_answers ? Object.keys(liveData.live_detail.questions_and_answers) : [];

                            return (
                                <div className='grid grid-cols-2 items-start max-lg:grid-cols-1 lg:gap-x-14 mt-2'>
                                    {questions.slice(0, 8).map((questionId, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`flex gap-2 items-start py-3 border-t border-[#eee2] firset:border-none ${i === 1 && 'lg:border-none'}`}
                                            >
                                                <img src={questionCircleIcon} className='mt-1' alt="Question Icon" />
                                                <p className='font-medium text-[19px] text-justify'>{questionId}</p>
                                            </div>
                                        );
                                    })}

                                    {(!isMobile || showMore) &&
                                        questions.slice(8).map((questionId, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`flex gap-2 items-start py-3 border-t border-[#eee2] last:border-none`}
                                                >
                                                    <img src={questionCircleIcon} className='mt-1' alt="Question Icon" />
                                                    <p className='font-medium text-[19px] text-justify'>{questionId}</p>
                                                </div>
                                            );
                                        })
                                    }

                                    {questions.length > 8 && !showMore && isMobile && (
                                        <button
                                            onClick={() => setShowMore(true)}
                                            className='font-semibold text-[22px] bg-[#063310] p-1 px-7 rounded border border-white'
                                        >
                                            تماشای بیشتر
                                        </button>
                                    )}
                                </div>
                            );
                        })()}

                        {pageType === 3 &&
                            <div className="lg:px-24 w-full flex flex-col gap-[1px]">
                                {liveData?.included_courses?.map((item, i) => (
                                    <div key={i} className='border-b border-[#eee2] last:border-none flex gap-2 justify-between flex-wrap py-3 lg:grid' style={{ gridTemplateColumns: 'repeat(50, minmax(0, 1fr))' }}>
                                        <div className="flex gap-[7px] max-lg:gap-[10px] items-center" style={{ gridColumn: 'span 27 / span 27' }}>
                                            <img src={doc2Icon} />
                                            <p className='inline text-[19px]'>
                                                <p className='inline ml-2 font-semibold'>ویدیوی ضبط شده {item.title_fa}</p>
                                                با حضور {item.capacity - item.remain_capacity} شرکت کننده، شامل {Object.keys(item.live_detail.questions_and_answers).length} سوال
                                            </p>
                                        </div>
                                        <div className="flex gap-[7px] max-lg:gap-[10px] items-center mr-1" style={{ gridColumn: 'span 10 / span 10' }}>
                                            <img src={timeCircleIcon} />
                                            <p className='inline text-[19px]'>
                                                <p className='inline ml-2 font-semibold'>مدت زمان جلسه:</p>
                                                {item.live_detail.duration}
                                            </p>
                                        </div>
                                        <div className="flex gap-[7px] max-lg:gap-[10px] items-center lg:mr-auto" style={{ gridColumn: 'span 13 / span 13' }}>
                                            <img src={calendarIcon} />
                                            <p className='inline text-[19px]'>
                                                <p className='inline ml-2 font-semibold'>برگزار شده در تاریخ:</p>
                                                {convertToJalali(item.live_detail.start_date)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>

                    <div ref={des2} className='relative z-[2] pt-16 flex flex-col items-center gap-5 w-[782px] max-w-full mx-auto'>
                        <h3>
                            {pageType === 2 && 'نحوه ثبت نام و استفاده از جلسه'}
                            {pageType === 1 && 'بخشی از ' + liveData?.title_fa}
                            {pageType === 3 && 'دموی ' + liveData?.title_fa}
                        </h3>
                        <div
                            className='iframe-box w-full keep-english-numbers'
                            dangerouslySetInnerHTML={{ __html: liveData?.demo }}
                        />
                        <div className="max-lg:hidden flex gap-5 justify-between items-center w-full">
                            <div className='h-[1px] grow bg-[#BDBDBD]'></div>
                            {liveData?.remain_capacity === 0 ?
                                <button className='group flex gap-2 items-center bg-[#888] pointer-events-none rounded px-4 p-3'>
                                    <p className='font-semibold text-[19px]'>ظرفیت تکمیل شده است.</p>
                                </button>
                                : checkIfPurchased(liveData) ?
                                    <Link to={`/dashboard/products/rooyekhat/live/${liveData.id}`} className='bg-[--orange-primary] text-white hover:bg-[--orange-primary-hover] transition rounded px-4 p-3'>بخش نمایش جلسه</Link>
                                    :
                                    <button onClick={() => addToCart(liveData)} disabled={isInCart} style={{ background: isInCart && 'var(--green-primary-active)' }} className='group flex gap-2 items-center bg-[--green-primary] hover:bg-[--green-primary-hover] transition rounded px-4 p-3 relative'>
                                        <p className='font-semibold text-[19px]'>
                                            {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}
                                        </p>
                                        <img src={arrowIcon} width={20} style={{ transform: isInCart && 'rotate(90deg)' }} className='group-hover:scale-x-110 group-hover:-translate-x-[2px] transition' />

                                        {!isDotIr && liveData?.isPurchasedInIr && !liveData?.isPurchasedInCom &&
                                            <div ref={tooltipRef2} className='absolute -top-2 right-0 bg-white rounded-full'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                                    <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                                </svg>
                                            </div>
                                        }
                                    </button>
                            }
                            <div className='h-[1px] grow bg-[#BDBDBD]'></div>
                        </div>
                    </div>
                </div>
            </section>

            <div ref={des3} />
            <CommentsSection
                onNoComments={() => setNoComment(true)}
                id={+id}
                title='نظر شرکت کنندگان جلسات روی خط'
                onCommentCountChange={handleCommentCountChange}
            />

            <div className='my-container lg:hidden fixed bottom-0 w-full shadow border-t z-10 h-[75px] bg-white flex gap-5 justify-between items-center'>
                {liveData?.remain_capacity === 0 ?
                    <button className='bg-[#888] pointer-events-none font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded'>ظرفیت تکمیل شده است.</button>
                    : checkIfPurchased(liveData) ?
                        <Link to={`/dashboard/products/rooyekhat/live/${liveData.id}`} className='bg-[--orange-primary] text-white text-center hover:bg-[--orange-primary-hover] font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded'>بخش نمایش جلسه</Link>
                        :
                        <button onClick={() => addToCart(liveData)} disabled={isInCart} style={{ background: isInCart && 'var(--orange-primary-active)' }} className='bg-[--orange-primary] hover:bg-[--orange-primary-hover] font-semibold text-[21px] p-2 sm:px-14 max-sm:grow rounded relative'>
                            <>
                                {isInCart ? 'در سبد خرید شما' : 'افزودن به سبد خرید'}

                                {!isDotIr && liveData?.isPurchasedInIr && !liveData?.isPurchasedInCom &&
                                    <div ref={tooltipRef3} className='absolute -top-2 right-0 bg-white rounded-full'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21px" fill="#222">
                                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                                        </svg>
                                    </div>
                                }
                            </>
                        </button>
                }
                <div className="flex flex-col">
                    {liveData?.discount_price && liveData?.discount_price !== liveData?.price ?
                        <>
                            <span className='font-semibold text-[23px] text-[#424242]'>
                                <span className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                    {isDotIr ? (liveData?.discount_price / 10).toLocaleString() : '$' + liveData?.discount_price.toLocaleString()}
                                </span>{' '}
                                <span className='font-medium text-[23px]'>
                                    {isDotIr && ' تومان'}
                                </span>
                            </span>
                            <span className='font-semibold text-[#424242] mt-[-5px] line-through text-left'>
                                <span className={`text-[18.5px] ${!isDotIr && 'keep-english-numbers'}`}>
                                    {isDotIr ? (liveData?.price / 10).toLocaleString() : '$' + liveData?.price.toLocaleString()}
                                </span>{' '}
                                <span className='font-medium text-[18.5px]'>
                                    {isDotIr && ' تومان'}
                                </span>
                            </span>
                        </>
                        :
                        <span className='font-semibold text-[23px] text-[#424242]'>
                            <span className={`text-[23px] ${!isDotIr && 'keep-english-numbers'}`}>
                                {isDotIr ? (liveData?.price / 10).toLocaleString() : '$' + liveData?.price.toLocaleString()}
                            </span>{' '}
                            <span className='font-medium text-[23px]'>
                                {isDotIr && ' تومان'}
                            </span>
                        </span>
                    }
                </div>
            </div>

            <Footer />

            <div className='lg:hidden h-[75px]'></div>
        </div>
    )
}

export default OnTheLineView;