import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import Exclude2 from '@/assets/svg/Exclude-red.svg'
import { isDotIr } from '@/utils/isDotIr'

import bookIcon from '@/assets/svg/book.svg'
import meditationIcon from '@/assets/svg/meditation3.svg'

const MakeAppointment = () => {
    const [nav_DropDown, setNav_DropDown] = useState(false)
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    const navigator = useNavigate()

    useEffect(() => {
        if (isDotIr) navigator('/404')
    }, [])

    return (
        <div className='text-[#212121] bg-white relative' onClick={close_Popup}>
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <section className="relative pt-40 max-lg:pt-32 overflow-hidden max-lg:mb-10">
                <div className='my-container relative mb-8'>
                    <div className='flex justify-between items-center lg:border-b border-[#eee] lg:pb-1 mb-1.5 max-lg:justify-center'>
                        <h3 className='text-[#212121] font-bold max-lg:text-[30px] flex gap-3'>
                            <img src={bookIcon} className='max-lg:hidden' />
                            دوست و همراه عزیز سلام،
                        </h3>
                    </div>
                    <div className='text-[#212121] text-[21px] font-semibold max-lg:text-[20px] max-lg:text-center mb-3'>خوشحالم که برای ارتقاء کیفیت زندگیت تصمیم گرفتی با من مشاورۀ خصوصی داشته باشی. لطف کن و از طریق فرم زیر وقت رزرو کن.</div>

                    <div className="w-fit flex items-center bg-[#ffe0de] gap-2 rounded p-2">
                        <img src={Exclude2} />
                        <p className='text-[#F75555] text-[19px] font-medium'>سرویس مشاوره خصوصی با توجه به شرایط، برای ساکنین کشور ایران قابل استفاده نمی باشد.</p>
                    </div>

                    <div className='bg-[#ECECEC] lg:mt-10 max-lg:mt-10 mb-8 p-2 rounded-2xl'>
                        <iframe width="100%" height="1150" className="lazyloaded rounded-lg" src="https://calendly.com/hosein-aura/life_coaching"></iframe>
                    </div>

                    <img src={meditationIcon} className='mx-auto' />
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default MakeAppointment;