import { useEffect, useState, useRef, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axiosInstance from '@/utils/axiosInstance';
import Main_Nav from "@/components/main-nav/Main_Nav";
import Footer from "@/components/footer/Footer";
import { convertNumsToFa } from '@/utils/numLangChanger';
import { CartContext } from '@/context/CartContext';
import { LoadingScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { UserContext } from '@/metronic-dashboard/context/UserContext';
import { isDotIr } from '@/utils/isDotIr';

import img from '@/assets/image/success.png';
import img2 from '@/assets/image/failed.png';

// Types for response data structures
interface OrderType {
    id: number;
    order_number: string;
    type: 'gift' | 'normal' | 'paid_by_card' | 'credit';
    CurrencyType: 'USD' | 'GBP' | 'EUR' | 'IRR';
    user_id: number;
    discount_code_id?: number;
    status: number;
    total: number | null;
    tax?: number;
    totalPayable: number | null;
    discount_amount?: number;
    paid_at?: string;
    user: {
        email: string;
    };
    courses: Array<{
        id: number;
        title_fa: string;
        price: number;
        price_fa: number;
        ImageMedia: Array<{
            src: string;
        }>;
    }>;
}

interface PaymentResponseData {
    user_id: number;
    order_id: number;
    order: OrderType;
    ref_id?: string;
    card_pan?: string;
    final_price?: number; // For zero-price orders
    discount_code?: string | null;
}

const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const months = [
        'ژانویه', 'فوریه', 'مارس', 'آوریل', 'مه', 'ژوئن',
        'ژوئیه', 'آگوست', 'سپتامبر', 'اکتبر', 'نوامبر', 'دسامبر'
    ];
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return `${month} ${day}، ${year}`;
}

const formatPrice = (price, currencyType) => {
    if (price === null || price === 0) return 'رایگان';
    if (currencyType === 'IRR') {
        return (price / 10).toLocaleString() + ' تومان';
    }
    return '$' + price.toLocaleString();
}

const OrderResult = ({ canceledOrder = false }) => {
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    };
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [data, setData] = useState<PaymentResponseData | null>(null);
    const [loading, setLoading] = useState(true);
    const requestSent = useRef(false);

    const { clearCart } = useContext(CartContext);

    useEffect(() => {
        if (location.state) {
            // Handle direct state passing
            if (location.state.response) {
                setData(location.state.response.data);
                setStatus('success');
                clearCart();
            } else if (location.state.creditCardPaymentData) {
                setData(location.state.creditCardPaymentData);
                setStatus('success');
                clearCart();
            }
            setLoading(false);
        } else {
            if (canceledOrder) {
                setLoading(false);
                return;
            }

            const searchParams = new URLSearchParams(location.search);
            const authority = searchParams.get('Authority');
            const statusParam = searchParams.get('Status');
            const paymentId = searchParams.get('paymentId');
            const payerID = searchParams.get('PayerID');

            if (!requestSent.current) {
                if (authority && statusParam) {
                    requestSent.current = true;
                    fetchPaymentResult(authority, statusParam);
                } else if (paymentId && payerID) {
                    requestSent.current = true;
                    fetchPaymentResult(paymentId, payerID, true);
                } else if (!authority && !statusParam && !paymentId && !payerID) {
                    setMessage('آدرس URL شامل پارامترهای صحیح نیست!');
                    setStatus('error');
                    setLoading(false);
                }
            }
        }
    }, [location]);

    const fetchPaymentResult = async (param1, param2, paypal = false) => {
        try {
            const response = await axiosInstance.get(
                paypal ? `/paypal/execute-payment?paymentId=${param1}&PayerID=${param2}`
                    : `/order/payment/result?Authority=${param1}&Status=${param2}`
            );

            if (response.data.status === 1) {
                if (response.data.data) {
                    setData(response.data.data);
                    setStatus('success');
                    clearCart();
                } else {
                    setMessage('تراکنش قبلا تایید شده است!');
                    setStatus('info');
                    clearCart();
                }
            } else {
                setMessage(response.data.message);
                setStatus('error');
            }
        } catch (error) {
            setMessage('متاسفانه مشکلی در دریافت اطلاعات تراکنش داریم!');
            setStatus('error');
        } finally {
            setLoading(false);
        }
    };

    const getPaymentMethod = (order) => {
        if (!order) return '-';
        if (order.totalPayable === 0 || order.totalPayable === null) return 'رایگان';
        if (order.CurrencyType === 'USD') return 'PayPal';
        return 'پرداخت امن زرین پال';
    }

    useEffect(() => {
        convertNumsToFa();
    }, [data, loading]);

    return (
        <div className="bg-white text-[#212121]" onClick={close_Popup}>
            <Main_Nav nav_DropDown={nav_DropDown} setNav_DropDown={setNav_DropDown} />

            <LoadingScreen loading={loading} />

            <div className='w-full min-h-[84vh] flex flex-col gap-5 items-center justify-center my-container pt-40 max-lg:pt-32 lg:mb-14 max-lg:mb-14'>
                {!loading && !canceledOrder && status === 'success' && data?.order ?
                    <div className='bg-[#f5fcf9] !border border-[#4AAF57] rounded-2xl w-[1023px] max-w-full flex flex-col items-center justify-center py-5 lg:px-20 max-lg:px-6'>
                        <h3 className='text-[#4AAF57] text-center'>سفارش شما تایید شد</h3>
                        <p className='font-semibold text-[21px] text-center'>
                            {data.order.totalPayable === 0 ?
                                'دسترسی به دوره برای شما فعال شده است.' :
                                'پرداخت شما با موفقیت انجام و دسترسی برای شما فعال شده است.'
                            }
                        </p>

                        <div className="max-lg:hidden flex justify-between w-full items-center my-8">
                            <div>
                                <p className='text-[20px]'>شماره سفارش</p>
                                <p className='font-semibold'>{data.order.order_number}</p>
                            </div>
                            <div className='h-5 w-px bg-[#bdbdbd]' />
                            <div>
                                <p className='text-[20px]'>تاریخ</p>
                                <p className='font-semibold'>{data.order.paid_at && formatDate(data.order.paid_at)}</p>
                            </div>
                            <div className='h-5 w-px bg-[#bdbdbd]' />
                            <div>
                                <p className='text-[20px]'>ایمیل</p>
                                <p className='font-semibold'>{data.order.user.email}</p>
                            </div>
                            <div className='h-5 w-px bg-[#bdbdbd]' />
                            <div>
                                <p className='text-[20px]'>مجموع</p>
                                <p className='font-semibold'>
                                    {formatPrice(data.order.totalPayable ?? data.final_price ?? 0, data.order.CurrencyType)}
                                </p>
                            </div>
                            <div className='h-5 w-px bg-[#bdbdbd]' />
                            <div>
                                <p className='text-[20px]'>روش پرداخت</p>
                                <p className='font-semibold'>{getPaymentMethod(data.order)}</p>
                            </div>
                        </div>

                        {/* Mobile view of order details */}
                        <div className='lg:hidden flex flex-col w-full my-6'>
                            <div className="flex justify-between py-2 border-b border-[#aaa]">
                                <div>
                                    <p className='text-[20px]'>شماره سفارش</p>
                                    <p className='font-semibold'>{data.order.order_number}</p>
                                </div>
                                <div>
                                    <p className='text-[20px]'>تاریخ</p>
                                    <p className='font-semibold'>{data.order.paid_at && formatDate(data.order.paid_at)}</p>
                                </div>
                            </div>
                            <div className="flex justify-between py-2 border-b border-[#aaa]">
                                <div>
                                    <p className='text-[20px]'>ایمیل</p>
                                    <p className='font-semibold'>{data.order.user.email}</p>
                                </div>
                                <div>
                                    <p className='text-[20px]'>مجموع</p>
                                    <p className='font-semibold'>
                                        {formatPrice(data.order.totalPayable ?? data.final_price ?? 0, data.order.CurrencyType)}
                                    </p>
                                </div>
                            </div>
                            <div className='py-2'>
                                <p className='text-[20px]'>روش پرداخت</p>
                                <p className='font-semibold'>{getPaymentMethod(data.order)}</p>
                            </div>
                        </div>

                        <div className='w-full lg:mb-5 max-lg:mb-9'>
                            <div className="flex justify-between">
                                <span className='font-semibold text-[21px]'>محصول</span>
                                <span className='font-semibold text-[21px]'>جمع کل</span>
                            </div>

                            <div className="flex flex-col gap-px border-y border-[#757575] bg-[#ddd] my-5">
                                {data.order.courses.map((item, i) => (
                                    <div key={i} className='bg-[#f5fcf9] flex flex-wrap gap-2 justify-between items-center py-4 first:pt-2 last:pb-2'>
                                        <div className='grow flex gap-4 items-center'>
                                            <div className='w-[72px] h-[72px] rounded !border-2 border-[#eee] p-[3px]'>
                                                {/* <img src={item.ImageMedia[0]?.src} className='w-full h-full object-cover rounded-[5px]' /> */}
                                            </div>
                                            <div className="flex max-lg:flex-col lg:gap-4 lg:items-center">
                                                <span className='font-semibold text-[20px]'>{item.title_fa}</span>
                                                <div className='max-lg:hidden w-px h-[20px] bg-[#aaa]' />
                                                <span className='font-semibold text-[20px]'>تعداد: 1</span>
                                            </div>
                                        </div>
                                        <div className="flex gap-10 items-center">
                                            <Link to='/dashboard/products' className='max-lg:hidden text-white text-[20px] bg-[#4AAF57] hover:bg-[--green-primary-hover] transition p-1 px-4 rounded'>
                                                داشبورد
                                            </Link>
                                            <span className='font-semibold text-[21px] mr-auto'>
                                                {formatPrice(item.price, data.order.CurrencyType)}
                                            </span>
                                        </div>
                                        <Link to='/dashboard/products' className='lg:hidden w-full text-center text-white text-[20px] bg-[#4AAF57] hover:bg-[--green-primary-hover] transition p-1 px-4 rounded'>
                                            داشبورد
                                        </Link>
                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-between">
                                <span className='font-semibold text-[21px]'>قیمت نهایی</span>
                                <div className="flex gap-1 items-center">
                                    <span className='font-semibold text-[21px]'>
                                        {formatPrice(data.order.totalPayable ?? data.final_price ?? 0, data.order.CurrencyType)}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <h3 className='text-[#454545] text-[26px] -mb-0.5'>از این که همراه ما هستید سپاسگزاریم.</h3>
                    </div>
                    : canceledOrder ?
                        <>
                            <img src={img2} alt="Error" width={100} className='mb-5' />
                            <div className='flex flex-col items-center justify-center gap-2'>
                                <h1 className="text-[#212121] text-center">سفارش توسط شما لغو شد.</h1>
                            </div>
                        </>
                        :
                        <>
                            {status === 'info' && (
                                <>
                                    <img src={img} alt="Info" width={100} className='mb-5' />
                                    <div className='flex flex-col items-center justify-center gap-2'>
                                        <h1 className="text-[#212121] text-center">{message}</h1>
                                    </div>
                                </>
                            )}
                            {status === 'error' && (
                                <>
                                    <img src={img2} alt="Error" width={100} className='mb-5' />
                                    <div className='flex flex-col items-center justify-center gap-2'>
                                        <h1 className="text-[#212121] text-center">{message || 'مشکلی پیش آمده است!'}</h1>
                                    </div>
                                </>
                            )}
                            {status !== 'success' && (
                                <div className='mt-2'>
                                    <a href="/dashboard" className="bg-[--orange-primary] hover:bg-[--orange-primary-hover] px-8 p-2 rounded text-white">داشبورد</a>
                                </div>
                            )}
                        </>
                }
            </div>

            <Footer />
        </div>
    );
};

export default OrderResult;